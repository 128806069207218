import React, { useState } from 'react';
import '../../styles/customer/Nav.css';
import logo from '../../images/logo.png';
import Grid from "@mui/material/Grid";
import vector from '../../images/Vector.png';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';

function NavBar() {
    const commonStyle = {
        fontFamily: 'Alike, sans-serif',
    };

    function logout() {
        window.localStorage.removeItem("isLoggedIn");
        window.location.href = '/login';
    }

    const navigate = useNavigate();

    const { id } = useParams();

    const profile = () => {
        axios.get(`http://localhost:8070/getAll/${id}`)
            .then((response) => {
                console.log(response);
                if (response.data.length > 0) {
                    navigate(`/main/myprofile/${id}`);
                } else {
                    message.error('Sorry!... Complete your profile to view your details.');
                }
            })
    }

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    return (
        <div className='nav'>

            <Grid container>
                <Grid item xs={2}>
                    <div className='navbar'>
                        <a href={`/main/${id}`}> <img src={logo} alt=" " /> </a>
                    </div>

                </Grid>
                <Grid item xs={10}>
                    <div className='navlink' style={commonStyle}>
                        <div className={`mobile ${isMenuOpen ? 'show' : ''}`}>
                            <div className="subnav">
                                <button className="subnavbtn"> MY AARUDHRA </button>
                                <div className="subnav-content" id = 'content1'>
                                    <Link to={`/main/dashboard/${id}`}>DASHBOARD</Link>
                                    <Link to='' onClick={profile}>MY PROFILE</Link>
                                    <a href="/">MY PHOTOS</a>
                                    <a href="/">PARTNER PREFERENCES</a>
                                    <a href="/">SETTINGS</a>
                                    <a href="/">MORE</a>
                                </div>
                            </div>

                            <div className="subnav">
                                <button className="subnavbtn"> MATCHES </button>
                                <div className="subnav-content" id = 'content2' style={{ marginLeft: '50px' }}>
                                    <a href={`/main/matches/${id}/searchUsers`} >NEW MATCHES</a>
                                    <a href={`/main/matches/${id}/searchBrides`} >BRIDES</a>
                                    <a href={`/main/matches/${id}/searchGrooms`} >GROOMS</a>
                                </div>
                            </div>

                            <div className="subnav">
                                <button className="subnavbtn"> SEARCH </button>
                            </div>

                            <div className="subnav">
                                <button className="subnavbtn"> INBOX </button>
                                <div className="subnav-content" id = 'content3' style={{ left: '550px' }}>
                                    <a href="/">RECEIVED</a>
                                    <a href="/">ACCEPTED</a>
                                    <a href="/">REQUEST</a>
                                    <a href="/">SENT</a>
                                </div>
                            </div>

                            <button className='upgrade'> <img src={vector} alt='' /> Upgrade Now </button>

                            <select className='language'>
                                <option className='opt'> English </option>
                                <option className='opt'> Sinhala </option>
                                <option className='opt'> Tamil </option>
                            </select>

                            <a href='/' className='help'> Help </a>

                            <div className="dropdown">
                                <button className="dropbtn">
                                    <i className='fa fa-user-circle'> </i>
                                    <i className="fa fa-caret-down"> </i>
                                </button>
                                <div className="dropdown-content">
                                    <Link to='' className='logout' onClick={logout}>Logout</Link>
                                </div>
                            </div>

                        </div>
                        <button type='button' className='openbtn' onClick={toggleMenu}> &#9776; </button>
                    </div>

                </Grid>
            </Grid>
        </div>
    )
}

export default NavBar;
