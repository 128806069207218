import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/customer/Home.js';
import Login from './components/customer/Login.js';
import SignUp from './components/customer/SignUp.js';
import ForgetPassword from './components/customer/ForgetPassword.js';
import MainPage from './components/customer/MainPage.js';
import Dashboard from './components/customer/Dashboard.js';
import CompleteProfile from './components/customer/CompleteProfile.js';
import Profile from './components/customer/MyProfile.js';
import EditCompleteProfile from './components/customer/EditCompleteProfile.js';
import CreatePost from './components/customer/CreatePost.js';
import ViewPost from './components/customer/ViewPost.js';
import PrintPost from './components/customer/PrintPost.js';
import Print from './components/customer/Print.js';
import Post from './components/customer/Post.js';
import EditPost from './components/customer/EditPost.js';
import SearchCustomer from './components/customer/SearchCustomer.js';
import SearchBride from './components/customer/SearchBride.js';
import SearchGroom from './components/customer/SearchGroom.js';
import Notification from './components/customer/Notification.js';
import Requests from './components/customer/Requests.js';

import AdminLogin from './components/admin/Login.js';
import AdminForgetPassword from './components/admin/ForgetPassword.js';
import AdminDashboard from './components/admin/Dashboard.js';
import Registration from './components/admin/customer/ManageRegistration.js';
import ManagePost from './components/admin/customer/ManagePost.js';
import AdminPost from './components/admin/customer/Post.js';
import View from './components/admin/customer/View.js';
import ViewProfile from './components/admin/customer/ViewProfile.js';
import CancelProfile from './components/admin/customer/CancelProfile.js';
import Astrologer from './components/admin/astrologer/ManageRegistration.js';

function App() {
  return (
    <div>
        <Router>
        <Routes>
            <Route path="/" Component={Home}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/signup" element={<SignUp/>}/>
            <Route path="/forgot" element={<ForgetPassword/>}/>
            <Route path="/main/:id" element={<MainPage/>}/>
            <Route path="/main/dashboard/:id" element={<Dashboard/>}/>
            <Route path="/main/dashboard/completeprofile/:id" element={<CompleteProfile/>}/>
            <Route path="/main/myprofile/:id" element={<Profile/>}/>
            <Route path="/main/dashboard/editprofile/:id" Component={EditCompleteProfile}/>
            <Route path="/main/dashboard/createpost/:id" element={<CreatePost/>}/>
            <Route path="/main/dashboard/post/:id" element={<Post/>}/>
            <Route path="/main/dashboard/editpost/:id" element={<EditPost/>}/>
            <Route path="/main/matches/:id/searchUsers" element={<SearchCustomer/>}/>
            <Route path="/main/matches/:id/searchBrides" element={<SearchBride/>}/>
            <Route path="/main/matches/:id/searchGrooms" element={<SearchGroom/>}/>
            <Route path="/main/matches/:id/searchUsers/viewpost/:post_ID" element={<ViewPost/>}/>
            <Route path="/main/matches/:id/searchUsers/viewpost/printpost/:post_ID" element={<PrintPost/>}/>
            <Route path="/main/matches/:id/searchUsers/viewpost/printpost/print/:post_ID" element={<Print/>}/>
            <Route path="/main/dashboard/:id/notifications" element={<Notification/>}/>
            <Route path="/main/dashboard/requests/:id" element={<Requests/>}/>

            <Route path="/adminlogin" element={<AdminLogin/>}/>
            <Route path="/adminforgot" element={<AdminForgetPassword/>}/>
            <Route path="/admindashboard/:id" element={<AdminDashboard/>}/>
            <Route path="/admindashboard/:id/registration" element={<Registration/>}/>
            <Route path="/admindashboard/:id/registration/view/:user_ID" element={<View/>} />
            <Route path="/admindashboard/:id/registration/view/cancelProfile/:user_ID" element={<CancelProfile/>}/>
            <Route path="/admindashboard/:id/registration/astrologer" element={<Astrologer/>}/>
            <Route path="/admindashboard/:id/registration/view/viewDetails/:user_ID" element={<ViewProfile/>}/>
            <Route path="/admindashboard/:id/managepost" element={<ManagePost/>}/>
            <Route path="/admindashboard/:id/managepost/viewpost/:user_ID" element={<AdminPost/>}/>
        </Routes>
        </Router>
    </div>
    
  );
}

export default App;
