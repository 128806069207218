import React, { useEffect, useState } from 'react';
import '../../styles/customer/CompleteProfile.css';
import NavBar from './NavBar';
import Footer from './Footer';
import user from '../../images/user.png';
import { Link , useParams} from 'react-router-dom';
import axios from 'axios';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function Customer(suggestions){
    const commonStyle = {
        fontFamily: 'Alike, sans-serif',
    };

    const containerStyle = {
        fontFamily: 'Alike, sans-serif',
    }; 

    const tableStyle = { 
        maxWidth: 1200,
        boxShadow:'none',
        margin:'25px',
        backgroundColor:'inherit',
        fontFamily: 'Alike, sans-serif',
        color:'#41436A',
        border:'none'
    }

    const [rows, setData] = React.useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
   
    const {id} = useParams();
    useEffect(() => {
        axios.get(`http://localhost:8070/getPostGroomDetails`)
        .then((data)=>{
            console.log(data);

            setData(data.data);

        });
    }, [id]);

    const [inputValue, setInputValue] = useState('');
    const [filteredDistricts, setFilteredDistricts] = useState([]);
    const [currentFocus, setCurrentFocus] = useState(-1);

    useEffect(() => {
      const districts = [
        "Ampara",
        "Anuradhapura",
        "Badulla",
        "Batticaloa",
        "Colombo",
        "Galle",
        "Gampaha",
        "Hambantota",
        "Jaffna",
        "Kandy",
        "Kegalle",
        "Kurunegala",
        "Mannar",
        "Matara",
        "Negombo",
        "Nuwara Eliya",
        "Polonnaruwa",
        "Ratnapura",
        "Trincomalee",
        "Vavuniya"
      ];
      setFilteredDistricts(
        districts.filter((district) =>
          district.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    }, [inputValue]);

    const handleInput = (e) => {
      setInputValue(e.target.value);
    };

    const handleKeyDown = (e) => {
      if (e.keyCode === 40) {
        // Arrow DOWN key
        setCurrentFocus((prevFocus) =>
          prevFocus < filteredDistricts.length - 1 ? prevFocus + 1 : prevFocus
        );
      } else if (e.keyCode === 38) {
        // Arrow UP key
        setCurrentFocus((prevFocus) => (prevFocus > 0 ? prevFocus - 1 : prevFocus));
      } else if (e.keyCode === 13) {
        // Enter key
        if (currentFocus > -1) {
          setInputValue(filteredDistricts[currentFocus]);
        }
      }
    };

    const handleClick = (district) => {
      setInputValue(district);
      setTimeout(() => {
        setFilteredDistricts([]);
      }, 0);
    };

    const [groom, setGroom] = useState(false);
    const [bride, setBride] = useState(false);
    const [single, setSingle] = useState(false);
    const [divorce, setDivorced] = useState(false);
    const [veg, setVeg] = useState(false);
    const [nonveg, setNonVeg] = useState(false);
    const [caste, setCaste] = useState('');
    const [religion, setReligion] = useState('');
    const [inputAge, setInputAge] = useState();

    const handleGroomClick = () => {
      setGroom(!groom);
    };

    const handleBrideClick = () => {
      setBride(!bride);
    };

    const handleSingleClick = () => {
      setSingle(!single);
    };

    const handleDivorcedClick = () => {
      setDivorced(!divorce);
    };

    const handleVegClick = () => {
      setVeg(!veg);
    };

    const handleNonVegClick = () => {
      setNonVeg(!nonveg);
    };

    const calculateRowCount = () => {
        const filteredRows = rows.filter((row) => {
        const isBride= row.gender === 'F';
        const isGroom = row.gender === 'M';
        const isSingle = row.marital_status === 'Single';
        const isDivorced = row.marital_status === 'Divorce';
        const isVeg = row.vegnonveg === 'Veg';
        const isNonVeg = row.vegnonveg === 'NonVeg';
        const isLocation = row.district === inputValue;

        const dateString = row.dob;
        const birthYear = new Date(dateString).getFullYear();
        const currentYear = new Date().getFullYear();
        const rowAge = currentYear - birthYear;
        const isAge = inputAge ? rowAge === parseInt(inputAge, 10) : true;
        const isCaste = row.cast === caste;
        const isReligion = row.religion === religion;
    
        return (
          (row.post_status === 'active') &&
          (!bride || (bride && isBride)) &&
          (!groom || (groom && isGroom)) &&
          (!single || (single && isSingle)) &&
          (!divorce || (divorce && isDivorced)) &&
          (!veg || (veg && isVeg)) &&
          (!nonveg || (nonveg && isNonVeg)) &&
          (!inputValue || (inputValue && isLocation)) && 
          (!inputAge || (inputAge && isAge)) &&
          (!caste || (caste && isCaste)) &&
          (!religion || (religion && isReligion))
        );
      });
    
      return filteredRows.length;
    };

    const filterData = () => {
      const filteredRows = rows.filter((row) => {
        const isBride = row.gender === 'F';
        const isGroom = row.gender === 'M';
        const isSingle = row.marital_status === 'Single';
        const isDivorced = row.marital_status === 'Divorce';
        const isVeg = row.vegnonveg === 'Veg';
        const isNonVeg = row.vegnonveg === 'NonVeg';
        const isLocation = row.district === inputValue;

        const dateString = row.dob;
        const birthYear = new Date(dateString).getFullYear();
        const currentYear = new Date().getFullYear();
        const rowAge = currentYear - birthYear;
        const isAge = inputAge ? rowAge === parseInt(inputAge, 10) : true;
        const isCaste = row.cast === caste;
        const isReligion = row.religion === religion;

        return (
          (row.post_status === 'active') &&
          (!bride || (bride && isBride)) &&
          (!groom || (groom && isGroom)) &&
          (!single || (single && isSingle)) &&
          (!divorce || (divorce && isDivorced)) &&
          (!veg || (veg && isVeg)) &&
          (!nonveg || (nonveg && isNonVeg)) &&
          (!inputValue || (inputValue && isLocation)) &&
          (!inputAge || (inputAge && isAge)) &&
          (!caste || (caste && isCaste)) &&
          (!religion || (religion && isReligion))
        );
      });

      return filteredRows;
    };

    const chunkArray = (array, size) => {
      return array.reduce((acc, _, i) => (i % size === 0 ? [...acc, array.slice(i, i + size)] : acc), []);
    };

    const filteredRows = filterData();
    const groupedData = chunkArray(filteredRows, 3);

    return(
        <div style={commonStyle}>
            <NavBar/> 
            <div className='searchDiv'>
            <div className='searchDiv filter'>
                <div className='filterNav' style={{marginBottom:'20px'}}>
                        <div class="filterNav">
                          <button
                            className={`filterNavbtn${groom ? ' active' : ''}`}
                            style={{ marginLeft: '30px', color: '#F54768'}}
                            onClick={handleGroomClick}
                          >
                            <input type="checkbox" style={{ visibility: 'hidden' }} checked={groom} />
                            Groom
                          </button>|
                          <button
                            className={`filterNavbtn${bride ? ' active' : ''}`}
                            style={{ color: bride ? '#F54768' : '#41436A'}}
                            onClick={handleBrideClick} disabled
                          >
                            Bride
                            <input type="checkbox" style={{ visibility: 'hidden' }} checked={bride} />
                          </button>    
                        </div>
                        
                        <div class="filterNav" style={{marginLeft:'300px'}}>
                          <button
                            className={`filterNavbtn${single ? ' active' : ''}`}
                            style={{ marginLeft: '30px', color: single ? '#F54768' : '#41436A'}}
                            onClick={handleSingleClick}
                          >
                            <input type="checkbox" style={{ visibility: 'hidden' }} checked={single} />
                            Single
                          </button>|
                          <button
                            className={`filterNavbtn${divorce ? ' active' : ''}`}
                            style={{ color: divorce ? '#F54768' : '#41436A'}}
                            onClick={handleDivorcedClick}
                          >
                            Divorced
                            <input type="checkbox" style={{ visibility: 'hidden' }} checked={divorce} />
                          </button>
                        </div>

                        <div class="filterNav" style={{marginLeft:'300px'}}>
                          <button
                            className={`filterNavbtn${veg ? ' active' : ''}`}
                            style={{ marginLeft: '30px', color: veg ? '#F54768' : '#41436A'}}
                            onClick={handleVegClick}
                          >
                            <input type="checkbox" style={{ visibility: 'hidden' }} checked={veg} />
                            Veg
                          </button>|
                          <button
                            className={`filterNavbtn${nonveg ? ' active' : ''}`}
                            style={{ color: nonveg ? '#F54768' : '#41436A'}}
                            onClick={handleNonVegClick}
                          >
                            Non Veg
                            <input type="checkbox" style={{ visibility: 'hidden' }} checked={nonveg} />
                          </button>
                        </div>
                </div> <br/> <br/> <br/>
                <div className='filterNav' style={{marginLeft:'10px'}}>
                    <label className='form-label'> Age : </label>
                    <input type='number' className='form-filter' style={{width:'100px', margin:'0', marginLeft:'10px'}} min='18' max = '80'
                    value={inputAge}
                    onChange={(event) => {
                    setInputAge(event.target.value);
                    }}/>
                </div>
                <div className='filterNav' style={{marginLeft:'90px'}} id="autocomplete-container">
                    <label className='form-label'> Location : </label>
                    <input type="text" name="myCountry" className='form-filter' style={{width:'200px', margin:'0', marginLeft:'10px'}} 
                    value={inputValue}
                    onChange={handleInput}
                    onKeyDown={handleKeyDown}/>
                    {inputValue && filteredDistricts.length > 0 && (
                      <ul className="autocomplete-list">
                        {filteredDistricts.map((district, index) => (
                          <li
                            key={index}
                            className={index === currentFocus ? 'autocomplete-active' : ''}
                            onClick={() => handleClick(district)}
                          >
                            {district}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
                <div className='filterNav' style={{marginLeft:'90px'}}>
                    <label className='form-label'> Caste : </label>
                    <select style={{ marginLeft: '10px', width: '150px' }} className='select' defaultValue=''
                    value={caste}
                    onChange={(event) => {
                    setCaste(event.target.value);
                    }}>
                      <option value='' disabled>  </option>
                      <option value='Bathgama'> Bathgama </option>
                      <option value='Berava'> Berava </option>
                      <option value='Bodhi Vamsa/Bodhahara'> Bodhi Vamsa/Bodhahara </option>
                      <option value='Demala-Gattara'> Demala-Gattara </option>
                      <option value='Dewa people'> Dewa people </option>
                      <option value='Durava'> Durava </option>
                      <option value='Govigama'> Govigama </option>
                      <option value='Hena'> Hena </option>
                      <option value='Karava'> Karava </option>
                      <option value='Karava heraldry'> Karava heraldry </option>
                      <option value='Kinnaraya'> Kinnaraya </option>
                      <option value='Konda Karavas'> Konda Karavas </option>
                      <option value='Marakkalage'> Marakkalage </option>
                      <option value='Pamunu'> Pamunu </option>
                      <option value='Patti'> Patti </option>
                      <option value='Porowakara'> Porowakara </option>
                      <option value='Radala'> Radala </option>
                      <option value='Rodiya'> Rodiya </option>
                      <option value='Salagama'> Salagama </option>
                      <option value='Kurukulam'> Kurukulam </option>
                    </select>
                </div>
                <div className='filterNav' style={{marginLeft:'90px'}}>
                    <label className='form-label'> Religion : </label>
                    <select style={{marginLeft:'10px', width:'150px'}} className='select' defaultValue=''
                    value={religion}
                    onChange={(event) => {
                    setReligion(event.target.value);
                    }}>
                        <option value='' disabled>  </option>
                        <option value='Buddhist' > Buddhist </option>
                        <option value='Hindu' > Hindu </option>
                        <option value='Islam' > Islam </option>
                        <option value='Catholic' > Catholic </option>
                    </select>
                </div> 
            </div> 
            <div style={containerStyle}>
                <div>
                <hr/>
                <TableContainer component={Paper} sx={tableStyle}>
                <Table sx={{ maxWidth: 1150 }} aria-label="custom pagination table">
                <TableBody>
                {rows && groupedData.map((group, index) => (
                  <div key={index} className="verticalContainer" style={{justifyContent:'space-between'}}>
                   {chunkArray(group, 3).map((rowChunk, rowIndex) => (
                    <TableRow key={rowIndex} style={{ justifyContent: 'space-between' }}>
                        {rowChunk.map((row, colIndex) => {
                            const isBride = row.gender === 'F';
                            const isGroom = row.gender === 'M';
                            const isSingle = row.marital_status === 'Single';
                            const isDivorced = row.marital_status === 'Divorce';
                            const isVeg = row.vegnonveg === 'Veg';
                            const isNonVeg = row.vegnonveg === 'NonVeg';
                            const isLocation = row.district === inputValue;
                            const dateString = row.dob;
                            const birthYear = new Date(dateString).getFullYear();
                            const currentYear = new Date().getFullYear();
                            const rowAge = currentYear - birthYear;
                            const isAge = inputAge ? rowAge === parseInt(inputAge, 10) : true;
                            const isCaste = row.cast === caste;
                            const isReligion = row.religion === religion;

                            const displayRow = (
                            (row.post_status === 'active') &&
                            (!bride || (bride && isBride)) &&
                            (!groom || (groom && isGroom)) &&
                            (!single || (single && isSingle)) &&
                            (!divorce || (divorce && isDivorced)) &&
                            (!veg || (veg && isVeg)) &&
                            (!nonveg || (nonveg && isNonVeg)) &&
                            (!inputValue || (inputValue && isLocation)) &&
                            (!inputAge || (inputAge && isAge)) &&
                            (!caste || (caste && isCaste)) &&
                            (!religion || (religion && isReligion))
                            );

                    return displayRow ? (
                        <TableCell key={colIndex} style={{ color: '#41436A', border: 'none', maxWidth: 350, fontFamily: 'Alike, sans-serif', justifyContent: 'space-between' }} align="center">
                          <div style={{ justifyContent: 'space-between' }}>
                            <div className='searchDiv block' style={{ marginRight: '100px', justifyContent: 'space-between' }}>
                              {row.image === 1 ? (
                                <img src={`http://localhost:8070/public/images/${row.profile}`} alt='' className='groom'
                                  style={{ borderRadius: '50%', height: '150px', width: '150px', objectFit: 'fill' }} />
                              ) : (
                                <img src={user} alt='' className='groom'
                                  style={{ borderRadius: '50%', height: '150px', width: '150px', objectFit: 'fill' }} />
                              )}
                              <h6> UserID: {row.userId} </h6>
                              <label> Age: {rowAge} </label> <br />
                              <label> Location: {row.district} </label> <br />
                              <label> Caste: {row.cast} </label> <br />
                              <label> Religion: {row.religion} </label> <br /> <br />
                              <Link to={`/main/matches/${id}/searchUsers/viewpost/${row.userId}`} className='viewbtn' style={{ padding: '8px' }}> View Profile </Link> <br />
                              </div>
                            </div>
                          </TableCell>
                          ) : null}
                          
                        )}
                        </TableRow>
                      ))}
                  {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={10}> </TableCell>
                      </TableRow>
                    )}
                    </div>
                  ))}
                </TableBody>
                    <TableFooter style={{border:'none'}}>
                    <TableRow>
                    {rows && (<>
                    <TablePagination
                      rowsPerPageOptions={[9, 12, 15, { 
                        label: 'All', 
                        value: -1,
                        style:{
                          borderRadius:'30px'
                        } 
                      
                      }]}
                      colSpan={5}
                      rowsPerPage={rowsPerPage}
                      count={calculateRowCount()}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                          style:{
                            color: '#41436A',
                            textAlign:'center',
                            fontFamily: 'Alike, sans-serif',
                            border:'1px solid #41436A',
                            background:'inherit',
                            borderRadius:'30px'
                          }
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelRowsPerPage={
                        <span style={{ color:'#41436A', fontFamily: 'Alike, sans-serif', position: 'relative', top: '7px' }}>
                          Rows per page:
                        </span>
                      }
                      labelDisplayedRows={({ from, to, count }) => (
                        <span style={{ color:'#41436A', fontFamily: 'Alike, sans-serif', position: 'relative', top: '7px', float: 'left', marginLeft: '25px' }}>
                          Showing {from}–{to} of {count} entries
                        </span>
                      )}
                    />
                    </>)}
                    </TableRow>
                    </TableFooter>
                </Table>
                </TableContainer>
                </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Customer;