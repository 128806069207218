import React, { useEffect, useState } from 'react';
import '../../styles/customer/Dashboard.css';
import Grid from "@mui/material/Grid";
import NavBar from './NavBar';
import user from '../../images/user.png';
import visa from '../../images/visa.png';
import subnav1 from '../../images/subnav1.png';
import subnav2 from '../../images/subnav2.png';
import subnav3 from '../../images/subnav3.png';
import subnav4 from '../../images/subnav4.png';
import subnav5 from '../../images/subnav5.png';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';
import moment from 'moment';
import Footer from './Footer';

function Dashboard (){
    const commonStyle = {
        fontFamily: 'Alike, sans-serif',
    };

    const {id} = useParams();
    const navigate = useNavigate();

    function handleEdit(){
        axios.get(`http://localhost:8070/getAll/${id}`)
            .then((response)=>{
                console.log(response);
                if (response.data.length > 0) {
                    navigate(`/main/dashboard/editprofile/${id}`);
                }else{
                    message.error('Sorry!... Complete your profile to edit profile.'); 
                }
        }) 
    }

    const [sentRequest, setSentRequest] = useState([]);
    const [recievedRequest, setRecievedRequest] = useState([]);

    useEffect (() => {
        axios.get(`http://localhost:8070/getsetrequest/${id}`)
        .then((data)=>{
            console.log('Get Request:', data.data);
            setSentRequest(data.data);   
        })
        .catch((err) => console.log(err))
    }, [id])

    console.log(sentRequest);
    console.log(recievedRequest)

    function confirm(requestId, id) {
        axios.put(`http://localhost:8070/requestConfirm/${requestId}/${id}`)
            .then((data)=>{
                console.log('Request Confirmed:', data.data);   
            })
            .catch((err) => console.log(err))
    }
    

    useEffect (() => {
        axios.get(`http://localhost:8070/getrecievedrequest/${id}`)
        .then((data)=>{
            console.log('Get Request:', data.data);
            setRecievedRequest(data.data);           
        })
        .catch((err) => console.log(err))
    }, [id])

    const handleButton = () => { 
        axios.get(`http://localhost:8070/getAll/${id}`)
            .then((response)=>{
                console.log(response);
                if (response.data.length > 0) {
                    message.error('Sorry!... You already have an account.');
                }else{
                    navigate(`/main/dashboard/completeprofile/${id}`);  
                }
        })  
    }

    const seeMore = () => {
        axios.get(`http://localhost:8070/getAll/${id}`)
            .then((response)=>{
                console.log(response);
                if (response.data.length > 0) {
                    navigate(`/main/myprofile/${id}`); 
                }else{
                    message.error('Sorry!... Complete your profile to view your details.'); 
                }
        }) 
    }

    const showMore = () => {
        navigate(`/main/dashboard/requests/${id}`)
    };

    const [userData, setuserData] = useState([]);

    useEffect(() => {
            axios.get(`http://localhost:8070/getProfile/${id}`)
            .then((data)=>{
                console.log(data)
    
                const dateString = data.data[0].dob;
                const dateObject = new Date(dateString);
                const formatDate = dateObject.toLocaleDateString();
    
                const contry = data.data[0].country;
                const nationality = contry+"n";
    
                setuserData({
                    fullName: data.data[0].fullName,
                    district: data.data[0].district,
                    religion: data.data[0].religion,
                    nationality,
                    height: data.data[0].height+"cm",
                    status: data.data[0].marital_status,
                    birthtime: data.data[0].birthtime,
                    cast: data.data[0].cast,
                    formatDate
                });
    
                //console.log(formatData);
            });
    }, [id]);

    const [status, setStatus] = useState('');
    
    useEffect(() => {
        axios.get(`http://localhost:8070/getStatus/${id}`)
        .then((response)=>{
            console.log(response);
            if (response.data.length > 0) {
                setStatus(response.data[0].status)
            }
        })
    }, [id])

    // Define a variable to hold the color based on the status
    let statusColor = '';

    // Set color based on status
    switch (status) {
        case 'Deactive':
            statusColor = 'red';
            break;
        case 'Pending':
            statusColor = 'orange';
            break;
        case 'Active':
            statusColor = 'green';
            break;
        case 'Confirmed':
            statusColor = 'green';
            break;
        default:
            statusColor = 'black'; // Default color, change as needed
            break;
    }

    const [file, setFile] = useState();

    useEffect(() => {
        axios.get(`http://localhost:8070/getImage/${id}`)
        .then((data)=>{
            console.log('Images', data);
            setFile(data.data[0].profile);
        })
        .catch((err) => console.log(err))
    }, [id]);

    const [horoscope, setHoroscope] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getHoroscope/${id}`)
        .then((data)=>{
            console.log(data)
            setHoroscope(data.data)
        });
    }, [id]);

    function createPost(){
        if(userData && file && horoscope){
            if(status === 'Active'){
                navigate(`/main/dashboard/createpost/${id}`);
            }
            else if (status === 'Pending'){
                message.warning('You can create a post when your account is activated!');
            }
            else if (status === 'Deactive'){
                message.error('Your account is deactivated!');
            }
            else{
                message.error('Your account is cancelled!');
            }
        }else{
            message.warning('Complete your profile!');
        }
        
    }

    function viewPost(){
        navigate(`/main/dashboard/post/${id}`);
    }

    const [post, setPost] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getPost/${id}`)
        .then((data) => {          
            console.log(data);   
            setPost(data.data);   
            setPost({
                views : data.data[0].views,
                prints: data.data[0].prints,
                status:data.data[0].post_status
            })
          })
        .catch(err => console.log(err));
    },[id]);

    function notification(){
        navigate(`/main/dashboard/${id}/notifications`);
    }

    function handleDelete(sent_id){
        axios.delete(`http://localhost:8070/${id}/deleterequest/${sent_id}`)
        .then((data)=>{
            console.log('Delete Request:', data.data);
        })
        .catch((err) => console.log(err))
    }

    const [activeTab, setActiveTab] = useState('sent');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return(
        <div style={commonStyle}>
                <NavBar/>
                <br/>
                <div className="subnavbar"> 
                    <button className='servicebtn'> Grooms </button>
                    <button className='servicebtn'> Brides </button>
                    <button className='servicebtn'> Astrologers </button>
                    <button className='servicebtn'> Success Stories </button>

                    <button className='notification' onClick={notification}> <i className='fa fa-bell'> </i> </button>
                </div>

                <div className="subnavbar2"> 
                    <button className='servicebtn'> <img src={subnav1} alt='' className='subnavimg' /> </button>
                    <button className='servicebtn'> <img src={subnav2} alt='' className='subnavimg' /> </button>
                    <button className='servicebtn'> <img src={subnav5} alt='' className='subnavimg' /> </button>
                    <button className='servicebtn'> <img src={subnav3} alt='' className='subnavimg' /> </button>

                    <button className='servicebtn' onClick={notification}> <img src={subnav4} alt='' className='subnavimg' /> </button>
                </div>
                <br/>

                <Grid container>
                    <Grid item xs={0.25}>
                        
                    </Grid>
                    <Grid item xs={5}>
                        
                        <div className = 'profile'>
                            <center>
                            {file? (
                                <img src={`http://localhost:8070/public/images/${file}`} alt='' className='groom'
                                style={{borderRadius:'50%', height:'150px', width:'150px', objectFit: 'fill'}}/>
                            ) : (
                                <img src={user} alt='' className='groom' />
                            )                              
                            } 
                            </center>
                            <ul style={{ color: statusColor, fontWeight:'bold', marginLeft:'175px'}} className='accStatus'>
                                <li> {status} </li>
                            </ul>

                            <Link to={seeMore} onClick={seeMore}> See More... </Link>
                            {userData && (
                            <>
                            <span className='name'> {userData.fullName} </span> <br/>
                            <span className='location'> {userData.district} </span> <br/> <br/>

                            <label className='label'> Religion </label>
                            <label className='value'> {userData.religion} </label> <br/>

                            <label className='label'> Nationality </label> 
                            <label className='value'> {userData.nationality} </label> <br/>

                            <label className='label'> Height </label>
                            <label className='value'> {userData.height}  </label> <br/>

                            <label className='label'> Birth Date </label>
                            <label className='value'> {userData.formatDate} </label> <br/>

                            <label className='label'> Birth Time </label>
                            <label className='value'> {userData.birthtime} </label> <br/>

                            <label className='label'> Status </label>
                            <label className='value'> {userData.status} </label> <br/>

                            <label className='label'> Cast </label>
                            <label className='value'> {userData.cast} </label> <br/>
                            </>
                            )}
                            <button className='editprofilebtn' onClick={handleEdit}> Edit Profile </button>
                            <button className='completebtn' onClick={handleButton}> Complete Profile </button>

                        </div>
                        
                    </Grid>
                    <Grid item xs={0.5}>
                        
                    </Grid>
                    <Grid item xs={5}>
                        <div className = 'horoscope'>
                            <h5> Find your perfect match based on horoscope compatibility </h5>
                            <center>
                            <button className='horoscopebtn'> Get Started to Analyze Your Horoscope </button>
                            </center>
                        </div>

                        <div className = 'subscription'>
                            <button className='subsbtn'> Silver </button>
                            <span className='amount'> Rs. 2500 </span> <br/> 

                            <label className='subdetails'> Subscribed Date </label>
                            <label className='subvalue'> Subscribed Date </label> <br/>

                            <label className='subdetails'> Expired Date </label>
                            <label className='subvalue'> Expired Date </label> <br/>

                            <label className='subdetails'> Validity Period </label>
                            <label className='subvalue'> Validity Period </label> <br/>

                            <center>
                            <button className='subscribebtn'> Subscription Plan </button>
                            </center>
                        </div>
                        
                    </Grid>
                    <Grid item xs={0.25}>
                        
                    </Grid>
                    
                </Grid>
                <br/> 
                <div className='rewards'>
                    <Grid container>
                        
                        <Grid item xs={8}> 
                            <h5 className='rewardstitle1'> My Posts </h5>
                            {post.status==='deactive' ? (
                                <span style={{color:'red', fontWeight:'bold', paddingLeft:'465px', marginTop:'-30px'}} className='rewardvalue' id='postStatus'> Deactive </span> 
                            ) : (
                                <span style={{color:'green', fontWeight:'bold', paddingLeft:'465px', marginTop:'-30px'}} className='rewardvalue' id='postStatus'> Active </span>
                            )
                            }
                            
                            <label className='rewarddetails'> Views </label>
                            <label className='rewardvalue'> {post.views} </label> <br/>

                            <label className='rewarddetails'> Date of Posted </label>
                            {post? ( 
                            <>
                                <label className='rewardvalue'> {moment(post.createDate).format('DD/MM/YYYY')} </label> <br/> 
                            </>
                            ):(
                            <>
                                <label className='rewardvalue'> DD/MM/YYYY </label> <br/>
                            </>
                            )}
                            <label className='rewarddetails'> Printed </label>
                            <label className='rewardvalue'> {post.prints} </label> <br/>
                        
                        </Grid>
                        <Grid item xs={1}>                    
                        </Grid>
                        <Grid item xs={3}>
                        {post ?(
                        <center>  <br/> 
                            <button className='paybtn' onClick={viewPost}> View Post </button> <br/>  <br/> 
                            <div className='managePost'>
                                <Link to ={`/main/dashboard/editpost/${id}`}> Edit Post  </Link> / <Link to={`/main/dashboard/post/${id}`}> Delete Post </Link> 
                            </div>
                        </center>
                        ):(
                            <center>  <br/> <br/>
                                <button className='paybtn' onClick={createPost}> Create Post </button> <br/>  <br/> 
                            </center>
                        )}
                        </Grid>

                    </Grid>  <br/> 
                </div>
                <br/> 
                <div className='rewards'>
                    <Grid container>
                        
                        <Grid item xs={6}>
                            <h5 className='rewardstitle1'> My Rewards </h5>

                            <label className='rewarddetails'> Last Use </label>
                            <label className='rewardvalue'> 124.57 </label> <br/>

                            <label className='rewarddetails'> Balance </label>
                            <label className='rewardvalue'> 1423.45 </label> <br/>

                            <div className='managerewards'>
                                <a href='/'> Use Rewards  </a> 
                            </div> 
                        
                        </Grid>
                        <Grid item xs={1}>
                        <div className='vl1'> </div>                     
                        </Grid>
                        <Grid item xs={5}>
                            <h5 className='rewardstitle2'> Credit/Debit Card </h5>
                            <Grid container>
                                <Grid item xs={3}>
                                    <img src ={visa} alt="" className='visa'/>                    
                                </Grid>
                                <Grid item xs={8}>
                                    <h6 className='accno'> XXXX XXXX XXXX X789 </h6> 
                                    <div className='managecard'>
                                    <a href='/'> Edit Card  </a> / <a href='/'> Delete Card </a>  
                                    </div>              
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                    <center>
                        <button className='paybtn'> To Pay </button>
                    </center>
                </div>

                <h5 className='requestname'> Requests </h5>
                <div className='requests'>
                    <Grid container>
                        
                        <Grid item xs={5}>
                            <h5 className='reqtitle' id='sentReq'> Sent </h5>

                            {sentRequest.slice(0, 2).map((request, index) => (                        
                            <div className='requestprofile' key={index}> 
                                <Grid container>
                                    <Grid item xs={3}> 
                                    {request.profile? (
                                        <img src={`http://localhost:8070/public/images/${request.profile}`} alt='' className='requestimg'
                                        style={{borderRadius:'50%', height:'100px', width:'100px', objectFit: 'fill'}}/>
                                    ) : (
                                        <img src={user} alt='' className='requestimg'/>
                                    )                              
                                    } 
                                    </Grid>
                                    <Grid item xs={5}>
                                        <span className='requsername'> {request.fullName} </span> <br/>
                                        <span className='reqlocation'> {request.district} </span> <br/>
                                        {request.confirmation === 'Pending' ? (
                                            <>
                                            <span className='statusconfirm' style={{marginLeft: '20px', color:'orange'}}> {request.confirmation} </span> <br/> 
                                            </>
                                        ):(
                                            <>
                                            <span className='statusconfirm' style={{marginLeft: '20px', color:'green'}}> {request.confirmation} </span> <br/> 
                                            </>
                                        )}
                                        
                                    </Grid>
                                    <Grid item xs={2}> 
                                        {sentRequest.confirmation === 'Confirmed' ? (
                                            <button className='viewbtn' > View Profile </button>
                                        ) : (
                                            <button className='viewbtn' onClick={() => handleDelete(sentRequest.sent_id)}> Requested </button>
                                        )}
                                        
                                    </Grid>
                                </Grid>
                            </div>
                            ))}                          
                        
                        </Grid>
                        <Grid item xs={2}>
                        <div className='vl2'> </div>                     
                        </Grid>
                        <Grid item xs={5}>
                            <h5 className='reqtitle' id='RecievedReq'> Recieved </h5>

                            {recievedRequest.slice(0, 2).map((request, index) => (
                            <div className='requestprofile' key={index}> 
                                <Grid container>
                                    <Grid item xs={3}> 
                                    {request.profile? (
                                        <img src={`http://localhost:8070/public/images/${request.profile}`} alt='' className='requestimg'
                                        style={{borderRadius:'50%', height:'100px', width:'100px', objectFit: 'fill'}}/>
                                    ) : (
                                        <img src={user} alt='' className='requestimg'/>
                                    )                              
                                    } 
                                    </Grid>
                                    <Grid item xs={5}>
                                        <span className='requsername'> {request.fullName} </span> <br/>
                                        <span className='reqlocation'> {request.district} </span> <br/>
                                        
                                    </Grid>
                                    <Grid item xs={2}> 
                                        {request.confirmation === 'Confirmed' ? (
                                            <button className='viewbtn' > View Profile </button>
                                        ) : (
                                            <button className='viewbtn' id='recbtn' onClick={() => confirm(request.sent_id)}> Confirm </button>
                                        )}
                                        
                                    </Grid>
                                </Grid>

                            </div>
                            ))}
                        
                        </Grid>

                    </Grid>
                    <center>
                        <button onClick={showMore} className='viewbtn'>
                            Show More
                        </button>
                    </center>
                </div>
                <div className='mobileReq'>
                    <div className='tabs'>
                        <button className={activeTab === 'sent' ? 'active-tab' : ''} onClick={() => handleTabChange('sent')}>Sent</button>  |  
                        <button className={activeTab === 'received' ? 'active-tab' : ''} onClick={() => handleTabChange('received')}>Received</button>
                    </div>

                    <div className='requestsMobile'>
                        {activeTab === 'sent' && (
                            <div>
                                <h5 className='reqtitle' id='sentReq'> Sent </h5>
                                {sentRequest.slice(0, 2).map((request, index) => (  
                                    <div className='requestprofile' id='sentprofile' key={index}>
                                        {request.profile? (
                                            <img src={`http://localhost:8070/public/images/${request.profile}`} alt='' className='requestimg'
                                            style={{borderRadius:'50%', height:'100px', width:'100px', objectFit: 'fill'}}/>
                                        ) : (
                                            <img src={user} alt='' className='requestimg'/>
                                        )                              
                                        } 
                                        <span className='requsername'> {request.fullName} </span> <br/>
                                        <span className='reqlocation'> {request.district} </span> <br/>
                                        {request.confirmation === 'Pending' ? (
                                            <>
                                            <span className='statusconfirm' style={{marginLeft: '20px', color:'orange'}}> {request.confirmation} </span> <br/> 
                                            </>
                                        ):(
                                            <>
                                            <span className='statusconfirm' style={{marginLeft: '20px', color:'green'}}> {request.confirmation} </span> <br/> 
                                            </>
                                        )}
                                        {request.confirmation === 'Confirmed' ? (
                                            <button className='viewbtn' > View Profile </button>
                                        ) : (
                                            <button className='viewbtn' onClick={confirm} value={request.sent_id}> Confirm </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}

                        {activeTab === 'received' && (
                            <div>
                                <h5 className='reqtitle' id='RecievedReq'> Received </h5>
                                {recievedRequest.slice(0, 2).map((request, index) => (  
                                    <div className='requestprofile' key={index}>
                                        {request.profile? (
                                            <img src={`http://localhost:8070/public/images/${request.profile}`} alt='' className='requestimg'
                                            style={{borderRadius:'50%', height:'100px', width:'100px', objectFit: 'fill'}}/>
                                        ) : (
                                            <img src={user} alt='' className='requestimg'/>
                                        )                              
                                        } 
                                        <span className='requsername'> {request.fullName} </span> <br/>
                                        <span className='reqlocation'> {request.district} </span> <br/>
                                        {request.confirmation === 'Confirmed' ? (
                                            <button className='viewbtn' id='recbtn'> View Profile </button>
                                        ) : (
                                            <button className='viewbtn' id='recbtn' onClick={() => confirm(request.sent_id)}> Confirm </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            <br/> <br/>
            <Footer/>
        </div>
    )

}

export default Dashboard;