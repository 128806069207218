import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import login from '../../images/login.png';
import '../../styles/customer/Login.css';
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';

function ForgotPassword() {
    const commonStyle = {
        fontFamily: 'Alike, sans-serif'
    }; 

    const [email, setEmail] = useState('');
    const [newpassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(false);

    const navigate = useNavigate();

    //Checks for numbers in string
    function containsNumber(str) {
        return /[0-9]/.test(str);
    }

    //Checks for capital in string
    function containsCapital(str) {
        return /[A-Z]/.test(str);
    }

    const [emailError, setEmailError] = useState('');

    const validateEmail = (inputEmail) => {
        const emailRegex = /^[a-z0-9]+@gmail\.com$/;
        const isValid = emailRegex.test(inputEmail);

        setEmailError(isValid ? '' : 'Invalid Email Format');
        return isValid;
    };

    const [passwordError, setPasswordError] = useState('');

    const validatePassword = (inputPassword) => {
        // Reset password error message
        setPasswordError('');
    
        // If password doesn't contain an uppercase letter
        if (!containsCapital(inputPassword)) {
          setPasswordError('Password must contain at least one uppercase letter');
        }
    
        // If password doesn't contain a number
        else if (!containsNumber(inputPassword)) {
          setPasswordError('Password must contain at least one number');
        }
    
        // If password is less than 8 characters
        else if (inputPassword.length < 8) {
          setPasswordError('Password must be at least 8 characters long');
        }
    };
    
    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
        setPasswordsMatch(e.target.value === confirmPassword);
        validatePassword(e.target.value);
    };
    
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setPasswordsMatch(newpassword !== e.target.value ? 'Passwords do not match' : '');
    };

    const updatePassword = async (e) => {
        try{
            const response = await axios.put('http://localhost:8070/forgotpassword',{
                email:email,
                newpassword:newpassword,
            });

            if(response.data){
                message.success('Password Updated!');
                navigate('/login');
            }else{
                message.error(response.data);
            }
            
            
        }catch(err){
            console.log(err);
            message.error('An error occurred! Please Try Again...');
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (email) {
            axios.get(`http://localhost:8070/getByEmail?email=${email}`)
              .then((res) => {
                console.log(res);
                if (res.data.length > 0) {
                    updatePassword();
                }else{
                    message.error('User not found!');
                }
              })
              .catch((error) => console.log(error));
        }

    };   

    return(
        <div>
            <Grid container>
                <Grid item xs={4}>
                    <div className='forget'>
                        <div className='form'>
                        <form onSubmit={handleSubmit}>
                            <h4>Forgot Password</h4> <br/>
                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(event) => {
                                    setEmail(event.target.value);
                                    validateEmail(event.target.value);
                                    }}
                                    className={`form-control ${emailError ? 'is-invalid' : (email ? 'is-valid' : '')}`}
                                        id='exampleInputEmail1'
                                        aria-describedby='emailHelp'
                                        required
                                    />
                                {emailError && <div className='invalid-feedback'>{emailError}</div>}
                            </div>
                            <br />
                            <div className="mb-3">
                                <label className="form-label">New Password</label>
                                <input
                                    type="password"
                                    value={newpassword}
                                    onChange={handlePasswordChange}
                                    className={`form-control ${passwordError ? 'is-invalid' : (newpassword ? 'is-valid' : '')}`}
                                    id='exampleInputEmail1'
                                    aria-describedby='emailHelp'
                                required/>
                                {passwordError && <div className='invalid-feedback'>{passwordError}</div>}
                            </div>
                            <br/>
                            <div className="mb-3">
                                <label className="form-label">Confirm Password</label>
                                <input
                                    type="password"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                        className={`form-control ${passwordsMatch ? 'is-invalid' : (confirmPassword ? 'is-valid' : '')}`}
                                        id='exampleInputEmail1'
                                        aria-describedby='emailHelp'
                                    required/>
                                    {passwordsMatch && <div className='invalid-feedback'>{passwordsMatch}</div>}
                            </div> 
                            <span className='backtoLogin'> Back to <a href = '/login'> Login </a></span>
                            <br/>  <br/>

                            <button type='submit' className='logbtn' style={commonStyle}> Reset </button>
                            
                        </form>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={7}>
                    <div className='forgetimg'>
                        <img src={login} alt=''/>
                    </div>                   
                </Grid>
            </Grid>
        </div>
    );

}

export default ForgotPassword;