import React,{ useEffect, useState } from 'react';
import '../../styles/customer/CompleteProfile.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function Template(){

    const [horoscope, setHoroscope] = useState([]);
    const {id} = useParams();
   
    useEffect(() => {
        axios.get(`http://localhost:8070/getHoroscope/${id}`)
        .then((data)=>{
            console.log(data)
            setHoroscope({
                L1:data.data[0].L1,
                L2:data.data[0].L2,
                L3:data.data[0].L3,
                L4:data.data[0].L4,
                L5:data.data[0].L5,
                L6:data.data[0].L6,
                L7:data.data[0].L7,
                L8:data.data[0].L8,
                L9:data.data[0].L9,
                L10:data.data[0].L10,
                L11:data.data[0].L11,
                L12:data.data[0].L12,
                L13:data.data[0].L13,
                R1:data.data[0].R1,
                R2:data.data[0].R2,
                R3:data.data[0].R3,
                R4:data.data[0].R4,
                R5:data.data[0].R5,
                R6:data.data[0].R6,
                R7:data.data[0].R7,
                R8:data.data[0].R8,
                R9:data.data[0].R9,
                R10:data.data[0].R10,
                R11:data.data[0].R11,
                R12:data.data[0].R12,
                R13:data.data[0].R13
            });
        });
    }, [id]);

    return(
        <div className="horoscopeView">
            <form>
                {horoscope && (
                <>
                <div className='horoscopetemplate3'>
                    <div className='rowdet1'>
                        <div className='squaredet1'>
                            <input type='text' className='value1' value={horoscope.L1} readOnly/>
                            <input type='text' className='value2' value={horoscope.L2} readOnly/>
                        </div>
                        <div className='squaredet2'>
                            <input type='text' className='value3' value={horoscope.L3} readOnly/>
                        </div>
                        <div className='squaredet3'> 
                            <input type='text' className='value4' value={horoscope.L4} readOnly/>
                            <input type='text' className='value5' value={horoscope.L5} readOnly/>
                        </div>
                        <div className='cross5'>

                        </div>
                        <div className='cross6'>
                            
                        </div>
                    </div>
                    
                    <div className='rowdet2'>
                        <div className='squaredet1'>
                            <input type='text' className='value3' value={horoscope.L6} readOnly/>
                        </div>
                        <div className='squaredet2'>
                            <input type='text' className='value3' value={horoscope.L7} readOnly/>
                        </div>
                        <div className='squaredet3'> 
                            <input type='text' className='value3' value={horoscope.L8} readOnly/>
                        </div>
                    </div>
                    <div className='rowdet3'>
                        <div className='squaredet1'>
                            <input type='text' className='value4' value={horoscope.L9} readOnly/>
                            <input type='text' className='value5' value={horoscope.L10} readOnly/>
                        </div>
                        <div className='squaredet2'>
                            <input type='text' className='value3' value={horoscope.L11} readOnly/>
                        </div>
                        <div className='squaredet3'>
                            <input type='text' className='value1' value={horoscope.L12} readOnly/>
                            <input type='text' className='value2' value={horoscope.L13} readOnly/> 
                        </div>
                        <div className='cross7'>

                        </div>
                        <div className='cross8'>
                            
                        </div>
                    </div>
                    
                </div>
                
                <div className='horoscopetemplate4'>
                    <div className='rowdet1'>
                        <div className='squaredet1'>
                            <input type='text' className='value1' value={horoscope.R1} readOnly/>
                            <input type='text' className='value2' value={horoscope.R2} readOnly/>
                        </div>
                        <div className='squaredet2'>
                            <input type='text' className='value3' value={horoscope.R3} readOnly/>
                        </div>
                        <div className='squaredet3'> 
                            <input type='text' className='value4' value={horoscope.R4} readOnly/>
                            <input type='text' className='value5' value={horoscope.R5} readOnly/>
                        </div>
                        <div className='cross5'>

                        </div>
                        <div className='cross6'>
                            
                        </div>
                    </div>
                    
                    <div className='rowdet2'>
                        <div className='squaredet1'>
                            <input type='text' className='value3' value={horoscope.R6} readOnly/>
                        </div>
                        <div className='squaredet2'>
                            <input type='text' className='value3' value={horoscope.R7} readOnly/>
                        </div>
                        <div className='squaredet3'> 
                            <input type='text' className='value3' value={horoscope.R8} readOnly/>
                        </div>
                    </div>
                    <div className='rowdet3'>
                        <div className='squaredet1'>
                            <input type='text' className='value4' value={horoscope.R9} readOnly/>
                            <input type='text' className='value5' value={horoscope.R10} readOnly/>
                        </div>
                        <div className='squaredet2'>
                            <input type='text' className='value3' value={horoscope.R11} readOnly/>
                        </div>
                        <div className='squaredet3'>
                            <input type='text' className='value1' value={horoscope.R12} readOnly/>
                            <input type='text' className='value2' value={horoscope.R13} readOnly/> 
                        </div>
                        <div className='cross7'>

                        </div>
                        <div className='cross8'>
                            
                        </div>
                    </div>
                    
                </div>
                </>
                )}
            </form>
        </div>
    )
}

export default Template;