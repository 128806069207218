import React, { useEffect, useRef, useState } from 'react';
import '../../styles/customer/CompleteProfile.css';
import NavBar from './NavBar';
import Footer from './Footer';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Checkbox } from '@mui/material';
import qs from 'query-string';

function Print(){
    const commonStyle = {
        fontFamily: 'Alike, sans-serif',
    };

    const {id} = useParams();
    const {post_ID} = useParams();

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getProfile/${post_ID}`)
        .then((data)=>{
            console.log(data)

            const dateString = data.data[0].dob;
            const dateObject = new Date(dateString);
            const dateofbirth = dateObject.toLocaleDateString();

            
            const birthYear = new Date(dateString).getFullYear();
            
            console.log(birthYear);

            const currentYear = new Date().getFullYear();

            console.log(currentYear);

            const age = currentYear - birthYear;

            setUserData({
                userId: data.data[0].cid,
                district: data.data[0].district,
                religion: data.data[0].religion,
                caste: data.data[0].cast,
                country:data.data[0].country,
                address:data.data[0].address,
                status:data.data[0].marital_status,
                education:data.data[0].education,
                occupation:data.data[0].occupation,
                dateofbirth,
                age
            });

            //console.log(formatData);

        });
    }, [post_ID]);

    const [horoscope, setHoroscope] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getHoroscope/${post_ID}`)
        .then((data)=>{
            console.log(data)
            setHoroscope({
                L1:data.data[0].L1,
                L2:data.data[0].L2,
                L3:data.data[0].L3,
                L4:data.data[0].L4,
                L5:data.data[0].L5,
                L6:data.data[0].L6,
                L7:data.data[0].L7,
                L8:data.data[0].L8,
                L9:data.data[0].L9,
                L10:data.data[0].L10,
                L11:data.data[0].L11,
                L12:data.data[0].L12,
                L13:data.data[0].L13,
                R1:data.data[0].R1,
                R2:data.data[0].R2,
                R3:data.data[0].R3,
                R4:data.data[0].R4,
                R5:data.data[0].R5,
                R6:data.data[0].R6,
                R7:data.data[0].R7,
                R8:data.data[0].R8,
                R9:data.data[0].R9,
                R10:data.data[0].R10,
                R11:data.data[0].R11,
                R12:data.data[0].R12,
                R13:data.data[0].R13
            });
        });
    }, [post_ID]);

    const [selectedData, setSelectedData] = useState([]);

    const handleSelect = (data) => {
        setSelectedData(prevSelectedData => {
            if (prevSelectedData.includes(data)) {
                return prevSelectedData.filter(item => item !== data);
            } else {
                return [...prevSelectedData, data];
            }
        });
    };

    const navigate = useNavigate();

    const componentPDF = useRef();
 
    const handlePrint = () => {
        // Stringify the selectedData object and append it as URL parameters
        const queryString = qs.stringify(selectedData);
    
        // Navigate to the target page with the URL parameters
        navigate(`/main/matches/${id}/searchUsers/viewpost/printpost/print/${post_ID}?${queryString}`);
    };   
 
    return(
        <div style={commonStyle}>
            <NavBar/> <br/>
            <div className = 'myprofile' style={{paddingLeft:'50px', paddingRight:'50px'}}> 
                {userData && (
                <>
                <div ref={componentPDF} id = "content" style={commonStyle}>
                <h6 className='myprofilevalues' style={{marginLeft:'30px', marginTop:'20px'}}> User ID : {userData.userId} </h6>
                <div className='data' style={{marginLeft:'30px'}} >
                        <Checkbox name="DateOfBirth"
                            className='checkbox'
                            checked={selectedData.includes("DateOfBirth")}
                            onChange={() => handleSelect("DateOfBirth")}/> <label className='profile-control'> Date of Birth : {userData.dateofbirth}</label> <br/> 
                        <Checkbox name="Address"
                            className='checkbox'
                            checked={selectedData.includes("Address")}
                            onChange={() => handleSelect("Address")}/> <label className='profile-control'> Address : {userData.address}</label> <br/>
                        <Checkbox name="Religion"
                            className='checkbox'
                            checked={selectedData.includes("Religion")}
                            onChange={() => handleSelect("Religion")}/> <label className='profile-control'> Religion : {userData.religion}</label> <br/>
                        <Checkbox name="Caste"
                            className='checkbox'
                            checked={selectedData.includes("Caste")}
                            onChange={() => handleSelect("Caste")}/> <label className='profile-control'> Caste : {userData.caste}</label> <br/>
                        <Checkbox name="Age"
                            className='checkbox'
                            checked={selectedData.includes("Age")}
                            onChange={() => handleSelect("Age")}/> <label className='profile-control'> Age : {userData.age}</label> <br/>
                        <Checkbox name="Education"
                            className='checkbox'
                            checked={selectedData.includes("Education")}
                            onChange={() => handleSelect("Education")}/> <label className='profile-control'> Educational Qualification(s) : {userData.education}</label> <br/>
                        <Checkbox name="Occupation"
                            className='checkbox'
                            checked={selectedData.includes("Occupation")}
                            onChange={() => handleSelect("Occupation")}/> <label className='profile-control'> Occupation : {userData.occupation}</label> <br/>
                        <Checkbox name="District"
                            className='checkbox'
                            checked={selectedData.includes("District")}
                            onChange={() => handleSelect("District")}/> <label className='profile-control'> District : {userData.district}</label> <br/>
                        <Checkbox name="Country"
                            className='checkbox'
                            checked={selectedData.includes("Country")}
                            onChange={() => handleSelect("Country")}/> <label className='profile-control'> Resident Country : {userData.country}</label> <br/>
                        <Checkbox name="MaritalStatus"
                            className='checkbox'
                            checked={selectedData.includes("MaritalStatus")}
                            onChange={() => handleSelect("MaritalStatus")}/> <label className='profile-control'> Single/Divorced : {userData.status}</label> <br/>
                </div>  <br/> 
                <div className = "horoscopediv" id="horoscopeprintpost"> <br/>
                <h5 className='personaldet' style={{textAlign:'center'}} id='horoscope'> Horoscope</h5> 
                <div>
                <form>
                    {horoscope && (
                    <>
                    <div className='horoscopetemplate3' style={{marginLeft:'50px'}}>
                        <div className='rowdet1'>
                            <div className='squaredet1'>
                                <input type='text' className='value1' value={horoscope.L1} readOnly/>
                                <input type='text' className='value2' value={horoscope.L2} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.L3} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value4' value={horoscope.L4} readOnly/>
                                <input type='text' className='value5' value={horoscope.L5} readOnly/>
                            </div>
                            <div className='cross5'>

                            </div>
                            <div className='cross6'>
                                
                            </div>
                        </div>
                        
                        <div className='rowdet2'>
                            <div className='squaredet1'>
                                <input type='text' className='value3' value={horoscope.L6} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.L7} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value3' value={horoscope.L8} readOnly/>
                            </div>
                        </div>
                        <div className='rowdet3'>
                            <div className='squaredet1'>
                                <input type='text' className='value4' value={horoscope.L9} readOnly/>
                                <input type='text' className='value5' value={horoscope.L10} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.L11} readOnly/>
                            </div>
                            <div className='squaredet3'>
                                <input type='text' className='value1' value={horoscope.L12} readOnly/>
                                <input type='text' className='value2' value={horoscope.L13} readOnly/> 
                            </div>
                            <div className='cross7'>

                            </div>
                            <div className='cross8'>
                                
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className='horoscopetemplatepp' style={{marginRight:'50px'}} >
                        <div className='rowdet1'>
                            <div className='squaredet1'>
                                <input type='text' className='value1' value={horoscope.R1} readOnly/>
                                <input type='text' className='value2' value={horoscope.R2} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.R3} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value4' value={horoscope.R4} readOnly/>
                                <input type='text' className='value5' value={horoscope.R5} readOnly/>
                            </div>
                            <div className='cross5'>

                            </div>
                            <div className='cross6'>
                                
                            </div>
                        </div>
                        
                        <div className='rowdet2'>
                            <div className='squaredet1'>
                                <input type='text' className='value3' value={horoscope.R6} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.R7} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value3' value={horoscope.R8} readOnly/>
                            </div>
                        </div>
                        <div className='rowdet3'>
                            <div className='squaredet1'>
                                <input type='text' className='value4' value={horoscope.R9} readOnly/>
                                <input type='text' className='value5' value={horoscope.R10} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.R11} readOnly/>
                            </div>
                            <div className='squaredet3'>
                                <input type='text' className='value1' value={horoscope.R12} readOnly/>
                                <input type='text' className='value2' value={horoscope.R13} readOnly/> 
                            </div>
                            <div className='cross7'>

                            </div>
                            <div className='cross8'>
                                
                            </div>
                        </div>
                        
                    </div>
                    </>
                    )}
                </form>
                </div> <br/>
                </div> <br/>
                </div>
                <center>
                    <button className='printbtn' style={commonStyle} onClick={handlePrint}> Print </button>
                </center> 
                </>
                )} 
            </div> 
            <br/> <br/>
            <Footer/>
        </div>
    )
}

export default Print;