import React, { useEffect, useRef, useState } from 'react';
import '../../styles/customer/CompleteProfile.css';
import NavBar from './NavBar';
import Footer from './Footer';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { message } from 'antd';
import { useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function Print(){
    const commonStyle = {
        fontFamily: 'Alike, sans-serif',
    };

    const { id, post_ID } = useParams();

    const [userData, setUserData] = useState({}); // Change to object

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedKeys = Array.from(queryParams.values());
    const selectedUserData = Object.keys(userData)
    .filter(key => selectedKeys.includes(key))
    .reduce((obj, key) => {
        obj[key] = userData[key];
        return obj;
    }, {});

    useEffect(() => {
        axios.get(`http://localhost:8070/getProfile/${post_ID}`)
            .then((response) => {
                const data = response.data;
                if (data && Array.isArray(data) && data.length > 0) {
                    const userDataItem = data[0];
                    if (userDataItem && userDataItem.dob) {
                        // Convert date string to date object and format it
                        const dateObject = new Date(userDataItem.dob);
                        const DateOfBirth = dateObject.toLocaleDateString();

                        // Calculate age
                        const currentYear = new Date().getFullYear();
                        const birthYear = dateObject.getFullYear();
                        const Age = currentYear - birthYear;

                        // Update userData
                        setUserData({
                            userId: userDataItem.cid,
                            District: userDataItem.district,
                            Religion: userDataItem.religion,
                            Caste: userDataItem.cast,
                            Country: userDataItem.country,
                            Address: userDataItem.address,
                            MaritalStatus: userDataItem.marital_status,
                            Education: userDataItem.education,
                            Occupation: userDataItem.occupation,
                            DateOfBirth,
                            Age
                        });
                    } 
                } 
            })
            .catch(error => {
                console.error("Error fetching user data:", error);
            });
    }, [post_ID]);

    const [horoscope, setHoroscope] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getHoroscope/${post_ID}`)
        .then((data)=>{
            console.log(data)
            setHoroscope({
                L1:data.data[0].L1,
                L2:data.data[0].L2,
                L3:data.data[0].L3,
                L4:data.data[0].L4,
                L5:data.data[0].L5,
                L6:data.data[0].L6,
                L7:data.data[0].L7,
                L8:data.data[0].L8,
                L9:data.data[0].L9,
                L10:data.data[0].L10,
                L11:data.data[0].L11,
                L12:data.data[0].L12,
                L13:data.data[0].L13,
                R1:data.data[0].R1,
                R2:data.data[0].R2,
                R3:data.data[0].R3,
                R4:data.data[0].R4,
                R5:data.data[0].R5,
                R6:data.data[0].R6,
                R7:data.data[0].R7,
                R8:data.data[0].R8,
                R9:data.data[0].R9,
                R10:data.data[0].R10,
                R11:data.data[0].R11,
                R12:data.data[0].R12,
                R13:data.data[0].R13
            });
        });
    }, [post_ID]);  

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(`/main/matches/${id}/searchUsers/viewpost/printpost/${post_ID}`)
    };

    const [loader, setLoader] = useState(false);

    const componentPDF = useRef();

    const generatePDF = () => {
        setLoader(true);
        
        const content = componentPDF.current;
        html2canvas(content).then((canvas) => {
            const printData = canvas.toDataURL('img/png');
            const pdf = new jsPDF('p', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth/imgWidth, pdfHeight/imgHeight);
            const imgX = (pdfWidth-imgWidth * ratio)/2;
            const imgY = 10;
            pdf.addImage(printData, 'PNG', imgX, imgY, imgWidth*ratio, imgHeight*ratio);
            pdf.save(`User ID - ${post_ID}.pdf`);
            axios.put(`http://localhost:8070/printcount/${post_ID}`)
            .then((response) => {
            console.log(response.data);
            message.success('Saved as a PDF');
            })
            .catch((error) => {
            console.error('Error updating count:', error);
            });
            setLoader(false);
        })
    };

    function insertSpaces(string) {
        return string.replace(/([A-Z])/g, ' $1').trim();
    }
    
    return(
        <div style={commonStyle}>
            <NavBar/> <br/>
            <div className = 'myprofile' style={{paddingLeft:'50px', paddingRight:'50px'}}> 
                {Object.keys(userData).length > 0 &&  (
                <>
                <div ref={componentPDF} style={commonStyle}> 
                <h6 className='myprofilevalues' style={{marginLeft:'50px', marginTop:'40px'}}> User ID : {userData.userId} </h6>
                <div className='data' style={{marginLeft:'20px'}} >
                <div>
                    {Object.keys(selectedUserData).map((key) => (
                        <div key={key}>
                            <label className='profile-control'>
                                {`${insertSpaces(key)}: ${selectedUserData[key]}`}
                            </label>
                            <br />
                        </div>
                    ))}
                </div>
                </div>  
                <div id="horoscopeprintpost"> <br/>
                <h5 className='personaldet' style={{textAlign:'center'}} id='horoscope'> Horoscope</h5> 
            <div>
            <form>
                {horoscope && (
                    <>
                    <div className='horoscopetemplate3' style={{marginLeft:'50px'}}>
                        <div className='rowdet1'>
                            <div className='squaredet1'>
                                <input type='text' className='value1' value={horoscope.L1} readOnly/>
                                <input type='text' className='value2' value={horoscope.L2} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.L3} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value4' value={horoscope.L4} readOnly/>
                                <input type='text' className='value5' value={horoscope.L5} readOnly/>
                            </div>
                            <div className='cross5'>

                            </div>
                            <div className='cross6'>
                                
                            </div>
                        </div>
                        
                        <div className='rowdet2'>
                            <div className='squaredet1'>
                                <input type='text' className='value3' value={horoscope.L6} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.L7} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value3' value={horoscope.L8} readOnly/>
                            </div>
                        </div>
                        <div className='rowdet3'>
                            <div className='squaredet1'>
                                <input type='text' className='value4' value={horoscope.L9} readOnly/>
                                <input type='text' className='value5' value={horoscope.L10} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.L11} readOnly/>
                            </div>
                            <div className='squaredet3'>
                                <input type='text' className='value1' value={horoscope.L12} readOnly/>
                                <input type='text' className='value2' value={horoscope.L13} readOnly/> 
                            </div>
                            <div className='cross7'>

                            </div>
                            <div className='cross8'>
                                
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className='horoscopetemplatepp' style={{marginRight:'50px'}} >
                        <div className='rowdet1'>
                            <div className='squaredet1'>
                                <input type='text' className='value1' value={horoscope.R1} readOnly/>
                                <input type='text' className='value2' value={horoscope.R2} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.R3} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value4' value={horoscope.R4} readOnly/>
                                <input type='text' className='value5' value={horoscope.R5} readOnly/>
                            </div>
                            <div className='cross5'>

                            </div>
                            <div className='cross6'>
                                
                            </div>
                        </div>
                        
                        <div className='rowdet2'>
                            <div className='squaredet1'>
                                <input type='text' className='value3' value={horoscope.R6} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.R7} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value3' value={horoscope.R8} readOnly/>
                            </div>
                        </div>
                        <div className='rowdet3'>
                            <div className='squaredet1'>
                                <input type='text' className='value4' value={horoscope.R9} readOnly/>
                                <input type='text' className='value5' value={horoscope.R10} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.R11} readOnly/>
                            </div>
                            <div className='squaredet3'>
                                <input type='text' className='value1' value={horoscope.R12} readOnly/>
                                <input type='text' className='value2' value={horoscope.R13} readOnly/> 
                            </div>
                            <div className='cross7'>

                            </div>
                            <div className='cross8'>
                                
                            </div>
                        </div>
                        
                    </div>
                    </>
                    )}
            </form>
                </div> <br/>
                </div> <br/>
                </div>
                <button className='printbtn' style={{commonStyle, marginLeft:'10px'}} onClick={handleBack}> Back </button>
                <button className='printbtn' style={{commonStyle , float:'right'}} onClick={() => generatePDF()} disabled={!(loader === false)}>  

                {loader? (
                    <span style={{opacity:'0.5'}}> Downloading </span>
                ):(
                    <span > Generate PDF </span>
                )}
                </button>
                </>
                )} 
            </div> 
            <br/> <br/>
            <Footer/>
        </div>
    )
}

export default Print;