import React, { Component } from 'react';
import '../../styles/customer/Home.css';
import titleimage from '../../images/title.png';
import Grid from "@mui/material/Grid";
import right from '../../images/right.png';
import left from '../../images/left.png';
import story1 from '../../images/story1.jpg';
import story2 from '../../images/story2.jpg';
import NavBar from './NavBar';
import Footer from './Footer';
import Service1 from './Service1';
import Service2 from './Service2';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isService: true
        }   
    }

    changeService=()=>{
        if(this.state.isService)
            this.setState({isService: false});
        else    
            this.setState({isService: true});
    }

    render() {
        const containerStyle = {
            fontFamily: 'Aclonica, sans-serif'
        }; 

        return (
            <div>
                
                <NavBar/>
                <br/>
                <Grid container>
                    <Grid item xs={1}>
                        
                    </Grid>
    
                    <Grid item xs={4}>                       
                        <h1 className='title'> Find your <br/> <span style={{color:"#DA3151", containerStyle}}> RIGHT </span>  MATCH <br/> here </h1> 
                    </Grid>

                    <Grid item xs={1}>
                        
                    </Grid>

                    <Grid item xs={6}>
                        <img src = {titleimage} alt = " " className='titleimage'/>
                    </Grid>
                </Grid>
                <hr className='hrtag'/>
                <h3 className='service'> Our Services </h3>
                
                {
                    this.state.isService ? <Service1/> : <Service2/>
                }

                {
                    this.state.isService ?
                    <div>
                        <button className='left' disabled>
                            <img src={left} alt='' className='left'/>
                            <div className='navigatebullet1'></div>
                        </button> 
                        <button className='right' onClick={this.changeService}>
                            <img src={right} alt='' className='right'/>
                            <div className='navigatebullet2'></div>
                        </button> 
                    </div> 
                    :
                    <div> 
                        <button className='left' onClick={this.changeService}>
                            <img src={left} alt='' className='left'/>
                            <div className='navigatebullet4'></div>
                        </button> 
                        <button className='right' disabled>
                            <img src={right} alt='' className='right'/>
                            <div className='navigatebullet5'></div>
                        </button> 
                    </div>
                                                   
                }

                <br/> <br/> <br/> <br/> <br/> <br/>
                <h3 className='service'> Success Stories </h3>
                <a href="/all" className='all'> All Stories </a>
                <Grid container>
                    <Grid item xs={1.4}>
                        
                    </Grid>
    
                    <Grid item xs={4}>
                        <div className = 'story'>
                            <h4 className= 'storyname'> SARAH & ABHISHEK </h4>
                            <img src = {story1} alt = "" className= 'storyimg'/>
                            <div className='para'>
                            <p> With a stroke of destiny and celestial blessings, I guided a pair destined for matrimony. 
                                The stars' intricate dance foretold a story of karmic connection. Their fated union, 
                                scripted in the cosmic scrolls, unfolded seamlessly, creating a timeless love story. </p>
                            </div> 
                            <a href="/see" className='see'> See More </a>
                        </div>
                    </Grid>

                    <Grid item xs={1.5}>
                        
                    </Grid>

                    <Grid item xs={4}>
                        <div className = 'story' id='story2'>
                            <h4 className= 'storyname'> JENNY & JACK </h4>
                            <img src = {story2} alt = "" className= 'storyimg'/> <br/>
                            <div className='para'>
                            <p> In the vast expanse of love, I charted a course for a couple transcending borders and 
                                horizons. Astrological alignments hinted at a love written in the stars, overcoming 
                                cultural differences. Witnessing their cross-cultural matrimony blossom into a harmonious 
                                symphony was an astrological masterpiece. </p>
                            </div>
                            <a href="/see" className='see'> See More </a>
                        </div>                      
                    </Grid>

                    <Grid item xs={1}>
                        
                    </Grid>
                </Grid>
                <br/> <br/> <br/> <br/>
                <Footer/>
                </div>
        
        );
    }
}

export default Home;