import React, { useEffect, useState } from 'react';
import '../../../styles/customer/CompleteProfile.css';
import NavBar from '../NavBar';
import { useParams } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import axios from 'axios';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import user from '../../../images/user.png';
import { message } from 'antd';

function Post(){
    const commonStyle = {
        fontFamily: 'Alike, sans-serif',
    };

    const {user_ID} = useParams();

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getProfile/${user_ID}`)
        .then((data)=>{
            console.log(data);

            const dateString = data.data[0].dob;
            const dateObject = new Date(dateString);
            const formatDate = dateObject.toLocaleDateString();

            
            const birthYear = new Date(dateString).getFullYear();
            
            console.log(birthYear);

            const currentYear = new Date().getFullYear();

            console.log(currentYear);

            const age = currentYear - birthYear;

            setUserData({
                userId: data.data[0].cid,
                district: data.data[0].district,
                religion: data.data[0].religion,
                cast: data.data[0].cast,
                country:data.data[0].country,
                address:data.data[0].address,
                status:data.data[0].marital_status,
                education:data.data[0].education,
                occupation:data.data[0].occupation,
                formatDate,
                age
            });

            //console.log(formatData);

        });
    }, [user_ID]);

    const [file, setFile] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getPostProfile/${user_ID}`)
        .then((data)=>{
            console.log('Images', data);
            setFile({
                profile:data.data[0].profile, 
                image:data.data[0].image
            })
        })
        .catch((err) => console.log(err))
    }, [user_ID]);

    const [horoscope, setHoroscope] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getHoroscope/${user_ID}`)
        .then((data)=>{
            console.log(data)
            setHoroscope({
                L1:data.data[0].L1,
                L2:data.data[0].L2,
                L3:data.data[0].L3,
                L4:data.data[0].L4,
                L5:data.data[0].L5,
                L6:data.data[0].L6,
                L7:data.data[0].L7,
                L8:data.data[0].L8,
                L9:data.data[0].L9,
                L10:data.data[0].L10,
                L11:data.data[0].L11,
                L12:data.data[0].L12,
                L13:data.data[0].L13,
                R1:data.data[0].R1,
                R2:data.data[0].R2,
                R3:data.data[0].R3,
                R4:data.data[0].R4,
                R5:data.data[0].R5,
                R6:data.data[0].R6,
                R7:data.data[0].R7,
                R8:data.data[0].R8,
                R9:data.data[0].R9,
                R10:data.data[0].R10,
                R11:data.data[0].R11,
                R12:data.data[0].R12,
                R13:data.data[0].R13
            });
        });
    }, [user_ID]);

    const [active, setActive] = useState(false);
    const [deactive, setDeactive] = useState(false);
    React.useEffect(() => {
        axios.get(`http://localhost:8070/getPostStatus/${user_ID}`)
          .then((data) => {
            console.log(data);
            const getStatus = data.data[0].post_status;
            console.log(data);
            if(getStatus === 'active'){
                setActive(true);
                setDeactive(false);
            }
        
            if(getStatus === 'deactive'){
                setActive(false);
                setDeactive(true);
            }
        
          })
          .catch(err => console.log(err));
    }, [user_ID]);

    const [post, setPost] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getPost/${user_ID}`)
        .then((data) => {          
            console.log(data);
            setPost(data.data);  
            const dateString = data.data[0].createDate;
                const dateObject = new Date(dateString);
                const formatDate = dateObject.toLocaleDateString(); 
            setPost({
                views : data.data[0].views,
                prints: data.data[0].prints,
                formatDate
            })

            if(post.views >= 1000){
                return (post.view)/1000 + 'K' ;
            }
          })
        .catch(err => console.log(err));
    });

    const handleActivate = () => {
        axios.put(`http://localhost:8070/postActive/${user_ID}`)
            .then((response) => {
                console.log(response);
                setActive(true);
                setDeactive(false);
                message.success('Activated!');
            })
            .catch((error) => {
                console.error('Error updating status:', error);
            });
        
    }

    const handleDeactivate = () => {
        axios.put(`http://localhost:8070/postDeactive/${user_ID}`)
            .then((response) => {
                console.log(response);
                setActive(false);
                setDeactive(true);
                message.success('Deactivated!');
            })
            .catch((error) => {
                console.error('Error updating status:', error);
            });
        
    }

    return(
        <div style={commonStyle}>
            <NavBar/> <br/>
            <div className = 'myprofile' style={{paddingLeft:'50px', paddingRight:'50px'}}> 
                {userData && (
                <> <br/>
                <div style={{display:'flex'}}>
                <h5 className='myprofilevalues' style={{marginLeft:'70px'}}> User ID : {userData.userId} </h5>
                {post && (
                <span style={{marginLeft:'600px'}}> {post.formatDate} </span>                
                )} </div> <br/>
                <center>
                    {file.image===1?(
                        <img src={`http://localhost:8070/public/images/${file.profile}`} alt='' className='groom'
                        style={{borderRadius:'50%', height:'150px', width:'150px', objectFit: 'fill'}} />
                    ):(
                        <img src={user} alt='' className='groom' />
                    )} 
                    <h6> <a href='/' style={{textDecoration:'none', color:'#41436A'}}> Contact User </a> </h6> <br/> <br/>
                </center>    
                <Grid container>
                    <Grid item xs={6}>
                    <div style={{marginLeft:'50px'}} className='createPost'>
                    <span class='activateName' style={{fontSize:'16px', marginLeft:'30px', fontWeight:'bold'}}> No of Post Views :  </span> 
                    <span class='activateName' style={{fontSize:'16px', marginLeft:'30px', fontWeight:'bold'}}> {post.views}  </span> <br/> <br/> <br/> 
                        <label className='profile-control'> Date of Birth </label> <br/>
                        <label className='profile-control'> Address </label> <br/>
                        <label className='profile-control'> Religion </label> <br/>
                        <label className='profile-control'> Caste </label> <br/>
                        <label className='profile-control'> Age </label> <br/>
                        <label className='profile-control'> Educational Qualification(s)</label> <br/>
                        <label className='profile-control'> Occupation</label> <br/>
                        <label className='profile-control'> District </label> <br/>
                        <label className='profile-control'> Resident Country </label> <br/>
                        <label className='profile-control'> Single/Divorced</label> <br/>
                    </div>
                    </Grid>
                    <Grid item xs={1}>
                    <div className='vlcp'> </div>    
                    </Grid>
                    <Grid item xs={5}>
                    <div style={{marginRight:'20px'}} className='createPost'>
                    <center>
                    <span class='activateName' style={{fontSize:'16px', marginLeft:'0', fontWeight:'bold'}}> No of Post Prints :  </span>
                    <span class='activateName' style={{fontSize:'16px', marginLeft:'30px', fontWeight:'bold'}}> {post.prints} </span> <br/> <br/> <br/> <br/>
                    </center>
                        <input type='text' className='form-control' value={userData.formatDate} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.address} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.religion} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.cast} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.age} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.education} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.occupation} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.district} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.country} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.status} readOnly/> <br/>
                    </div>                  
                    </Grid>
                </Grid>
                <br/> <br/>
                <div style={{marginLeft:'10px', boxShadow: '2px 5px 5px 5px #dddcdc', borderRadius:'20px'}}> <br/>
                <h5 className='personaldet' style={{textAlign:'center'}}> Horoscope</h5> 
                <div>
                <form>
                    {horoscope && (
                    <>
                    <div className='horoscopetemplate3' style={{marginLeft:'50px'}}>
                        <div className='rowdet1'>
                            <div className='squaredet1'>
                                <input type='text' className='value1' value={horoscope.L1} readOnly/>
                                <input type='text' className='value2' value={horoscope.L2} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.L3} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value4' value={horoscope.L4} readOnly/>
                                <input type='text' className='value5' value={horoscope.L5} readOnly/>
                            </div>
                            <div className='cross5'>

                            </div>
                            <div className='cross6'>
                                
                            </div>
                        </div>
                        
                        <div className='rowdet2'>
                            <div className='squaredet1'>
                                <input type='text' className='value3' value={horoscope.L6} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.L7} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value3' value={horoscope.L8} readOnly/>
                            </div>
                        </div>
                        <div className='rowdet3'>
                            <div className='squaredet1'>
                                <input type='text' className='value4' value={horoscope.L9} readOnly/>
                                <input type='text' className='value5' value={horoscope.L10} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.L11} readOnly/>
                            </div>
                            <div className='squaredet3'>
                                <input type='text' className='value1' value={horoscope.L12} readOnly/>
                                <input type='text' className='value2' value={horoscope.L13} readOnly/> 
                            </div>
                            <div className='cross7'>

                            </div>
                            <div className='cross8'>
                                
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className='horoscopetemplatepp' style={{marginRight:'50px'}}>
                        <div className='rowdet1'>
                            <div className='squaredet1'>
                                <input type='text' className='value1' value={horoscope.R1} readOnly/>
                                <input type='text' className='value2' value={horoscope.R2} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.R3} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value4' value={horoscope.R4} readOnly/>
                                <input type='text' className='value5' value={horoscope.R5} readOnly/>
                            </div>
                            <div className='cross5'>

                            </div>
                            <div className='cross6'>
                                
                            </div>
                        </div>
                        
                        <div className='rowdet2'>
                            <div className='squaredet1'>
                                <input type='text' className='value3' value={horoscope.R6} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.R7} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value3' value={horoscope.R8} readOnly/>
                            </div>
                        </div>
                        <div className='rowdet3'>
                            <div className='squaredet1'>
                                <input type='text' className='value4' value={horoscope.R9} readOnly/>
                                <input type='text' className='value5' value={horoscope.R10} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.R11} readOnly/>
                            </div>
                            <div className='squaredet3'>
                                <input type='text' className='value1' value={horoscope.R12} readOnly/>
                                <input type='text' className='value2' value={horoscope.R13} readOnly/> 
                            </div>
                            <div className='cross7'>

                            </div>
                            <div className='cross8'>
                                
                            </div>
                        </div>
                        
                    </div>
                    </>
                    )}
                </form>
                </div> <br/> 
                </div> <br/> 
                <br/> <br/> 
                <h5 id='subcribe'> Post Status </h5>
                <br/>
                <div className='toggle'>
                    <label class="switch" id='switch1'>
                        <input type="checkbox" value='active'
                        checked={active} 
                        onClick={handleActivate} /> 
                        <span class="slider round"> <span class='activateName'> Activate </span> </span>  
                    </label>
                    
                    <label class="switch" id='switch2'>
                        <input type="checkbox" value='deactive'
                        checked={deactive} 
                        onClick={handleDeactivate}/>
                        <span class="slider round"> <span class='deactivateName'> Deactivate </span> </span>
                    </label>
                </div>
                <br/> <br/> <br/> <br/>   
                </>
                )} 
            </div>
            <br/> <br/>
        </div>
    )
}

export default Post;