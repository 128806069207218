import React from "react";
import Grid from "@mui/material/Grid";
import '../../styles/customer/Home.css';
import service3 from '../../images/service3.jpg';
import service4 from '../../images/package.png';
import service5 from '../../images/promotion.png';

function Service1(){
    const serviceStyle = {
        fontFamily: 'Jockey One, sans-serif'
    }; 
    
    return(
        <div>
            <Grid container>
                    <Grid item xs={0.85}>
                        
                    </Grid>
    
                    <Grid item xs={3}>
                        <div className='service3'>
                            <div>
                                <img src = {service3} alt = " "/>
                            </div>
                            <span className='servicename3' style={serviceStyle}> Astrologers </span>
                        </div> 
                    </Grid>

                    <Grid item xs={0.75}>
                        
                    </Grid>

                    <Grid item xs={3}>
                        <div className='service4'>
                            <div>
                                <img src = {service4} alt = " "/>
                            </div>
                            <span className='servicename4' style={serviceStyle}> Packages </span>
                        </div>  
                    </Grid>

                    <Grid item xs={0.75}>
                        
                    </Grid>

                    <Grid item xs={3}>
                        <div className='service5'>
                            <div>
                                <img src = {service5} alt = " "/>
                            </div>
                            <span className='servicename5' style={serviceStyle}> Promotions </span>
                        </div> 
                    </Grid>

                    <Grid item xs={1}>
                        
                    </Grid>
                </Grid>
        </div>    
    )
}

export default Service1;