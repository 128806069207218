import * as React from 'react';
import '../../../styles/admin/AdminDashboard.css';
import NavBar from '../NavBar';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useNavigate, useParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
  
  function Registration(props){
    const containerStyle = {
        fontFamily: 'Alike, sans-serif',
    }; 

    const tableStyle = { 
        width: 1150,
        border:'1px solid #b6b6bd',
        boxShadow:'none',
        margin:'25px',
        borderRadius:'10px',
        backgroundColor:'inherit',
        fontFamily: 'Alike, sans-serif',
        color:'#41436A',
    }

    const calculateRowCount = () => {
      const filteredRows = rows.filter((row) => {
        const isActivated = row.status === 'Active';
        const isDeactivated = row.status === 'Deactive';
        const isPending = row.status === 'Pending';
        const isCancel = row.status === 'Cancel';
    
        return (
          (!activeFilter || (activeFilter && isActivated)) &&
          (!deactiveFilter || (deactiveFilter && isDeactivated)) &&
          (!pendingFilter || (pendingFilter && isPending)) &&
          (!cancelFilter || (cancelFilter && isCancel))
        );
      });
    
      return filteredRows.length;
    };
    
    const [pendingFilter, setPendingFilter] = React.useState(false);
    const [activeFilter, setActiveFilter] = React.useState(false);
    const [deactiveFilter, setDeactiveFilter] = React.useState(false);
    const [cancelFilter, setCancelFilter] = React.useState(false);

    const [rows, setData] = React.useState([]);

    React.useEffect(() => {
      axios.get('http://localhost:8070/getDetails')
        .then((data) => {          
          console.log(data);
          setData(data.data);   
        })
        .catch(err => console.log(err));
    }, []);

    const handlePendingFilterChange = () => {
      setPendingFilter(!pendingFilter);
    };
    
    const handleActiveFilterChange = () => {
      setActiveFilter(!activeFilter);
    };
    
    const handleDeactiveFilterChange = () => {
      setDeactiveFilter(!deactiveFilter);
    };

    const handleCancelFilterChange = () => {
      setCancelFilter(!cancelFilter);
    };
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const navigate = useNavigate();

    function astrologer (){
      navigate('/astrologer')
    }

    const {id} = useParams();

    function customer (){
      navigate(`/admindashboard/${id}/registration`)
    }

    return(
        <div>
            <NavBar/>
            <br/> 
            <div style={containerStyle}>
                <div class="manageSub">
                    <div className="manageSubbtn"> 
                        <button className="subbtn" id='active' onClick={customer}> Customer </button>
                    </div>
                    <div className="manageSubbtn" > 
                        <button className="subbtn" id='deactive' onClick={astrologer}> Astrologer </button>
                    </div>
                    <div className="manageSubbtn"> 
                        <button className="subbtn" id='empty' > </button>
                    </div>
                    <div className="manageSubbtn"> 
                        <button className="subbtn" id='empty' > </button>
                    </div>
                    <div className="manageSubbtn"> 
                      <button className="subbtn" id='empty' >  </button>
                    </div>
                    <div className="manageSubbtn"> 
                      <button className="subbtn" id='empty' >  </button>
                    </div>
                </div>
                <br/> <br/> <br/> <br/>  
                <div className='customerProfile'>
                <h6> Customer Profile </h6>
                <hr/>
                {/*
                <div style={searchstyle}>
                  <div class="form-outline" data-mdb-input-init>
                    <input type="search" id="form1" class="form-control" placeholder='Search' style={{backgroundColor:'inherit'}}/>
                  </div>
                  <button type="button" class="btn btn-primary" data-mdb-ripple-init style={{marginLeft:'20px'}}>
                    Enter
                  </button>
                </div> 
                */}
                <br/> 
                <div>
                  <span className='accountStatus'> Status </span> 
                  <Checkbox {...label} checked={pendingFilter} onChange={handlePendingFilterChange} style={{color:'orange', marginLeft:'30px'}}/> <span style={{color:'orange'}}> Pending </span>
                  <Checkbox {...label} checked={activeFilter} onChange={handleActiveFilterChange} style={{color:'green', marginLeft:'30px'}}/> <span style={{color:'green'}}> Active</span>
                  <Checkbox {...label} checked={deactiveFilter} onChange={handleDeactiveFilterChange} style={{color:'red', marginLeft:'30px'}}/> <span style={{color:'red'}}> Deactive </span>
                  <Checkbox {...label} checked={cancelFilter} onChange={handleCancelFilterChange} style={{color:'black', marginLeft:'30px'}}/> <span style={{color:'black'}}> Cancel </span>
                </div>
                <TableContainer component={Paper} sx={tableStyle}>
                <Table sx={{ width: 1148 }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>   
                            <TableCell align='center' style={{ borderRight:'1px solid #b6b6bd', width: 100, color:'#41436A', fontWeight:'bold', fontFamily: 'Alike, sans-serif' }}>ID</TableCell>
                            <TableCell align='center' style={{ borderRight:'1px solid #b6b6bd', width: 300, color:'#41436A', fontWeight:'bold', fontFamily: 'Alike, sans-serif' }}>Name</TableCell>                                    
                            <TableCell align='center' style={{ borderRight:'1px solid #b6b6bd', width: 300, color:'#41436A', fontWeight:'bold', fontFamily: 'Alike, sans-serif' }}>Email</TableCell>
                            <TableCell align='center' style={{ borderRight:'1px solid #b6b6bd', width: 200, color:'#41436A', fontWeight:'bold', fontFamily: 'Alike, sans-serif' }}>Active Status</TableCell>
                            <TableCell align='center' style={{ width: 100, color:'#41436A', fontWeight:'bold', fontFamily: 'Alike, sans-serif' }}>View</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows && 
                    rows.map((row) => {
                      const isActivated = row.status === 'Active';
                      const isDeactivated = row.status === 'Deactive';
                      const isPending = row.status === 'Pending';
                      const isCancel = row.status === 'Cancel';

                      const displayRow =
                        (!activeFilter || (activeFilter && isActivated)) &&
                        (!deactiveFilter || (deactiveFilter && isDeactivated)) &&
                        (!pendingFilter || (pendingFilter && isPending)) &&
                        (!cancelFilter || (cancelFilter && isCancel));

                      return displayRow ? (
                        <TableRow key={row.cid}>
                          <TableCell component="th" scope="row" style={{ color: '#41436A', borderRight: '1px solid #b6b6bd', width: 100, fontFamily: 'Alike, sans-serif' }} align="center">
                            {row.cid}
                          </TableCell>
                          <TableCell style={{ color: '#41436A', borderRight: '1px solid #b6b6bd', width: 350, fontFamily: 'Alike, sans-serif' }} align="center">
                            {row.fullName}
                          </TableCell>
                          <TableCell style={{ color: '#41436A', borderRight: '1px solid #b6b6bd', width: 350, fontFamily: 'Alike, sans-serif' }} align="center">
                            {row.email}
                          </TableCell>
                          <TableCell style={{ color: '#41436A', borderRight: '1px solid #b6b6bd', width: 200, fontFamily: 'Alike, sans-serif' }} align="center">
                            {row.status}
                          </TableCell>
                          <TableCell style={{ width: 100, fontFamily: 'Alike, sans-serif' }} align="center">
                            <a href={`/admindashboard/${id}/registration/view/${row.cid}`} style={{ textDecoration: 'none' }} > View </a>
                          </TableCell>
                        </TableRow>
                      ) : null
                    })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={10} /> 
                        </TableRow>
                    )}                 
                    </TableBody>
                    <TableFooter>
                    <TableRow>
                    {rows && (<>
                    <TablePagination
                      rowsPerPageOptions={[10, { 
                        label: 'All', 
                        value: -1,
                        style:{
                          borderRadius:'30px'
                        } 
                      
                      }]}
                      colSpan={5}
                      count={calculateRowCount()}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                          style:{
                            color: '#41436A',
                            textAlign:'center',
                            fontFamily: 'Alike, sans-serif',
                            border:'1px solid #41436A',
                            background:'inherit',
                            borderRadius:'30px'
                          }
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelRowsPerPage={
                        <span style={{ color:'#41436A', fontFamily: 'Alike, sans-serif', position: 'relative', top: '7px' }}>
                          Rows per page:
                        </span>
                      }
                      labelDisplayedRows={({ from, to, count }) => (
                        <span style={{ color:'#41436A', fontFamily: 'Alike, sans-serif', position: 'relative', top: '7px', float: 'left', marginLeft: '25px' }}>
                          Showing {from}–{to} of {count} entries
                        </span>
                      )}
                    />
                    </>)}
                    </TableRow>
                    </TableFooter>
                </Table>
                </TableContainer>
                </div>
            </div>
        </div>
    )
}


export default Registration;