import React from 'react';
import '../../styles/customer/Footer.css';
import logo from '../../images/homelogo.png';
import Grid from "@mui/material/Grid";
import { useParams } from 'react-router-dom';

function Footer(){
    const commonStyle = {
        fontFamily: 'Alike, sans-serif'
    }; 

    const {id} = useParams();
    return(
        <div className='footer' style={commonStyle}>
            <Grid container>
                <Grid item xs={0.5}>
                </Grid> 
                <Grid item xs={3}>
                    <div className=''>
                        <img src = {logo} alt = " " className='footerLogo'/>
                    </div>
                    <div className='mission'>
                        Our mission is to bring people together, fostering lasting and meaningful relationships.
                    </div>
                </Grid> 
                <Grid item xs={0.3}>
                </Grid> 
                <Grid item xs={2.5}>
                    <div className='footerTitle1'>
                        Quick Links
                    </div>
                    <div className='quickLinks1'>
                        <ul>
                            <li> <a href={`/main/${id}`}> Home </a> </li>
                            <li> Pricing Plan </li>
                            <li> Rating </li>
                            <li> Grooms </li>
                            <li> Brides </li>
                            <li> Astrologers </li>
                            <li> Success Stories </li>
                            <li> <a href='/adminlogin'> Admin Login </a> </li>
                        </ul>
                    </div>
                </Grid> 
                <Grid item xs={0.1}>
                </Grid>
                <Grid item xs={2.5}>
                    <div className='footerTitle2' id='help'>
                        Help & Support
                    </div>
                    <div className='quickLinks2' id='help'>
                        <ul>
                            <li> About Us </li>
                            <li> Contact Us </li>
                            <li> FAQs </li>
                        </ul>
                    </div>

                    <div className='footerTitleSocial'>
                        Follow Us
                    </div>
                    <div className='quickLinksSocial'>
                        <div className='socialmedia'>
                            <i href="#" class="fa fa-instagram"></i>
                            <i href="#" class="fa fa-facebook"></i>
                            <i href="#" class="fa fa-google"></i>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={0.1}>
                </Grid> 
                <Grid item xs={3}>
                    <div className='footerTitle4'>
                        Get in touch!
                    </div>
                    <div className='quickLinks3'>
                        <ul>
                            <li> <i href="#" class="fa fa-phone"></i> 077 913 8835 </li>
                            <li> <i href="#" class="fa fa-envelope"></i> aarudhralanka@gmail.com </li>
                            <li> <i href="#" class="fa fa-map-marker"></i> Bambalapitiya, Sri Lanka </li>
                        </ul>
                    </div>
                    {/*
                    <div>
                        <img src = {logo} alt = " " className='footerLogo'/>
                    </div>
                    */}
                </Grid> 
            </Grid>
            <div className='copyright'>
                <div className='copy'>
                    Copyright © 2023 aarudhra.lk. All rights reserved.
                </div>
            </div>

        </div>
    )
}

export default Footer;