import React from 'react';
import '../../styles/admin/AdminNav.css';
import logo from '../../images/logo.png';
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from 'react-router-dom';

function NavBar(){
    const commonStyle = {
        fontFamily: 'Alike, sans-serif',
    };

    function logout() {
        window.localStorage.removeItem("isLoggedIn");
        window.location.href = '/adminlogin';
    }

    const userId = 1;
    const navigate = useNavigate();

    function handleButton1(){
      navigate(`/admindashboard/${userId}`);
    }

   
    return(
        <div className='nav'>
            
        <Grid container>
                <Grid item xs={2}>
                <div className='navbar'>
                    <img src = {logo} alt = " "/>
                </div>
                
                </Grid>
                <Grid item xs={10}>
                <div className='adminnavlink' style={commonStyle}>  

                    <div className='adminNav'>
                        <div class="adminsubnav">
                            <button className="adminsubnavbtn" onClick={handleButton1}> DASHBOARD </button>
                        </div>

                        <div class="adminsubnav">
                            <button className="adminsubnavbtn"> MANAGE OFFICERS </button>
                        </div>

                        <div class="adminsubnav">
                            <button className="adminsubnavbtn"> MANAGE PROPOSALS </button>
                        </div>

                        <div class="adminsubnav">
                            <button className="adminsubnavbtn"> MANAGE POSTS </button>
                        </div>

                        <div class="adminsubnav">
                            <button className="adminsubnavbtn"> RATING & FEEDBACK </button>
                        </div>
                    </div>

                    <select className='language'>
                        <option className='opt'> English </option>
                        <option className='opt'> Sinhala </option>
                        <option className='opt'> Tamil </option>
                    </select>

                    <div class="dropdown">
                        <button className="dropbtn">
                            <i className='fa fa-user-circle'> </i>
                            <i class="fa fa-caret-down"> </i>
                        </button>
                        <div class="dropdown-content">
                            <Link to ='' className='logout' onClick={logout}>Logout</Link>
                        </div>
                    </div>
                    <button class="openbtn"> &#9776; </button>

                </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default NavBar;