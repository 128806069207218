import React,{useState} from 'react';
import Grid from '@mui/material/Grid';
import loginimg from '../../images/login.png';
import '../../styles/customer/Login.css';
import { Link } from 'react-router-dom';
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';

const Login = ({setLoginUser}) => {
    const commonStyle = {
        fontFamily: 'Alike, sans-serif'
    }; 

    const buttonStyle = {
        fontFamily: 'Angkor, sans-serif'
    }; 

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [emailError, setEmailError] = useState('');

    const validateEmail = (inputEmail) => {
        const emailRegex = /^[a-z0-9]+@gmail\.com$/;
        const isValid = emailRegex.test(inputEmail);

        setEmailError(isValid ? '' : 'Invalid Email Format');
        return isValid;
    };

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const login = {
            email: email,
            password: password,
        };

        try {
          const response = await axios.post('http://localhost:8070/login', login);       
          const userId = response.data.userId;

          if (userId) {
            message.success('Login Successful!', 1);
            navigate(`/main/${userId}`);
          }else {
            message.error("Invalid Credentials! Please Try Again");
          }
        } catch (error) {
          console.log(error.message);
        }
    };

    return(
        <div>
            <Grid container>
                <Grid item xs={4}>
                    <div className='login'>
                    <a href='/' style={{textDecoration:'none', fontSize:'16px', color:'white', marginLeft:'70px'}} className='backLink'> <i className='fa fa-arrow-left'> </i> Back </a>
                        <div className='form'>
                        <form onSubmit={handleSubmit}>
                            <h4>Login</h4> <br/>
                            <div className='mb-3'>
                                <label className='form-label'>Email</label>
                                <input
                                type='email'
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                    validateEmail(event.target.value);
                                }}
                                className={`form-control ${emailError ? 'is-invalid' : (email ? 'is-valid' : '')}`}
                                id='exampleInputEmail1'
                                aria-describedby='emailHelp'
                                required
                                />
                                {emailError && <div className='invalid-feedback'>{emailError}</div>}
                            </div>
                            <br />
                            <div className="mb-3">
                                <label className="form-label">Password</label>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(event) => {
                                    setPassword(event.target.value);
                                    }}
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                required/>
                            </div>

                            <a href='/forgot' className = "forgot" style={{textDecoration:'none', fontSize:'14px', color:'#41436A', fontStyle:'italic'}}> Forgot Password </a>
                            <br/> <br/>

                            <div className='social'>
                            <i href="#" class="fa fa-instagram"></i>
                            <i href="#" class="fa fa-facebook"></i>
                            <i href="#" class="fa fa-google"></i>
                            </div>
                            <br/>
                            <button className='logbtn' style={commonStyle}> Get Start </button> 
                        </form>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={7}>
                    <Link to = "/signup" className="btn1" style={buttonStyle}> Register </Link>
                    <div className='loginimg'>
                        <img src={loginimg} alt=''/>
                    </div>                   
                </Grid>
            </Grid>
        </div>
    );

}

export default Login;