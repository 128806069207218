import React, { useEffect, useState } from 'react';
import '../../styles/customer/Dashboard.css';
import Grid from "@mui/material/Grid";
import NavBar from './NavBar';
import user from '../../images/user.png';
import subnav1 from '../../images/subnav1.png';
import subnav2 from '../../images/subnav2.png';
import subnav3 from '../../images/subnav3.png';
import subnav4 from '../../images/subnav4.png';
import subnav5 from '../../images/subnav5.png';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Footer from './Footer';

function Dashboard (){
    const commonStyle = {
        fontFamily: 'Alike, sans-serif',
    };

    const {id} = useParams();
    const navigate = useNavigate();

    const [sentRequest, setSentRequest] = useState([]);
    const [recievedRequest, setRecievedRequest] = useState([]);

    useEffect (() => {
        axios.get(`http://localhost:8070/getsetrequest/${id}`)
        .then((data)=>{
            console.log('Get Request:', data.data);
            setSentRequest(data.data);   
        })
        .catch((err) => console.log(err))
    }, [id])

    console.log(sentRequest);
    console.log(recievedRequest)

    function confirm(requestId, id) {
        axios.put(`http://localhost:8070/requestConfirm/${requestId}/${id}`)
            .then((data)=>{
                console.log('Request Confirmed:', data.data);   
            })
            .catch((err) => console.log(err))
    }
    

    useEffect (() => {
        axios.get(`http://localhost:8070/getrecievedrequest/${id}`)
        .then((data)=>{
            console.log('Get Request:', data.data);
            setRecievedRequest(data.data);           
        })
        .catch((err) => console.log(err))
    }, [id])

    function notification(){
        navigate(`/main/dashboard/${id}/notifications`);
    }
  
    const {post_ID} = useParams();

    function handleDelete(){
        axios.delete(`http://localhost:8070/${id}/deleterequest/${post_ID}`)
        .then((data)=>{
            console.log('Delete Request:', data.data);
        })
        .catch((err) => console.log(err))
    }

    const [activeTab, setActiveTab] = useState('sent');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return(
        <div style={commonStyle}>
                <NavBar/>
                <br/>
                <div className="subnavbar"> 
                    <button className='servicebtn'> Grooms </button>
                    <button className='servicebtn'> Brides </button>
                    <button className='servicebtn'> Astrologers </button>
                    <button className='servicebtn'> Success Stories </button>

                    <button className='notification' onClick={notification}> <i className='fa fa-bell'> </i> </button>
                </div>

                <div className="subnavbar2"> 
                    <button className='servicebtn'> <img src={subnav1} alt='' className='subnavimg' /> </button>
                    <button className='servicebtn'> <img src={subnav2} alt='' className='subnavimg' /> </button>
                    <button className='servicebtn'> <img src={subnav5} alt='' className='subnavimg' /> </button>
                    <button className='servicebtn'> <img src={subnav3} alt='' className='subnavimg' /> </button>

                    <button className='servicebtn' onClick={notification}> <img src={subnav4} alt='' className='subnavimg' /> </button>
                </div>
                <br/>

                <h5 className='requestname'> Requests </h5>
                <div className='requests'>
                    <Grid container>
                        
                        <Grid item xs={5}>
                            <h5 className='reqtitle' id='sentReq'> Sent </h5>

                            {sentRequest && sentRequest.map((request) => (                            
                            <div className='requestprofile'> 
                                <Grid container>
                                    <Grid item xs={3}> 
                                    {request.profile? (
                                        <img src={`http://localhost:8070/public/images/${request.profile}`} alt='' className='requestimg'
                                        style={{borderRadius:'50%', height:'100px', width:'100px', objectFit: 'fill'}}/>
                                    ) : (
                                        <img src={user} alt='' className='requestimg'/>
                                    )                              
                                    } 
                                    </Grid>
                                    <Grid item xs={5}>
                                        <span className='requsername'> {request.fullName} </span> <br/>
                                        <span className='reqlocation'> {request.district} </span> <br/>
                                        {request.confirmation === 'Pending' ? (
                                            <>
                                            <span className='statusconfirm' style={{marginLeft: '20px', color:'orange'}}> {request.confirmation} </span> <br/> 
                                            </>
                                        ):(
                                            <>
                                            <span className='statusconfirm' style={{marginLeft: '20px', color:'green'}}> {request.confirmation} </span> <br/> 
                                            </>
                                        )}
                                        
                                    </Grid>
                                    <Grid item xs={2}> 
                                        {sentRequest.confirmation === 'Confirmed' ? (
                                            <button className='viewbtn' > View Profile </button>
                                        ) : (
                                            <button className='viewbtn' onClick={handleDelete}> Requested </button>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                            ))}                          
                        
                        </Grid>
                        <Grid item xs={2}>
                        <div className='vl2'> </div>                     
                        </Grid>
                        <Grid item xs={5}>
                            <h5 className='reqtitle' id='RecievedReq'> Recieved </h5>

                            {recievedRequest && recievedRequest.map((request) => ( 
                            <div className='requestprofile'> 
                                <Grid container>
                                    <Grid item xs={3}> 
                                    {request.profile? (
                                        <img src={`http://localhost:8070/public/images/${request.profile}`} alt='' className='requestimg'
                                        style={{borderRadius:'50%', height:'100px', width:'100px', objectFit: 'fill'}}/>
                                    ) : (
                                        <img src={user} alt='' className='requestimg'/>
                                    )                              
                                    } 
                                    </Grid>
                                    <Grid item xs={5}>
                                        <span className='requsername'> {request.fullName} </span> <br/>
                                        <span className='reqlocation'> {request.district} </span> <br/>
                                        
                                    </Grid>
                                    <Grid item xs={2}> 
                                        {request.confirmation === 'Confirmed' ? (
                                            <button className='viewbtn' > View Profile </button>
                                        ) : (
                                            <button className='viewbtn' id='recbtn' onClick={() => confirm(request.sent_id)}> Confirm </button>
                                        )}
                                    </Grid>
                                </Grid>

                            </div>
                            ))}
                        
                        </Grid>

                    </Grid>
                </div>
                <div className='mobileReq'>
                    <div className='tabs'>
                        <button className={activeTab === 'sent' ? 'active-tab' : ''} onClick={() => handleTabChange('sent')}>Sent</button>  |  
                        <button className={activeTab === 'received' ? 'active-tab' : ''} onClick={() => handleTabChange('received')}>Received</button>
                    </div>

                    <div className='requestsMobile'>
                        {activeTab === 'sent' && (
                            <div>
                                <h5 className='reqtitle' id='sentReq'> Sent </h5>
                                {sentRequest && sentRequest.map((request) => (
                                    <div className='requestprofile' id='sentprofile' key={request.sent_id}>
                                        {request.profile? (
                                            <img src={`http://localhost:8070/public/images/${request.profile}`} alt='' className='requestimg'
                                            style={{borderRadius:'50%', height:'100px', width:'100px', objectFit: 'fill'}}/>
                                        ) : (
                                            <img src={user} alt='' className='requestimg'/>
                                        )                              
                                        } 
                                        <span className='requsername'> {request.fullName} </span> <br/>
                                        <span className='reqlocation'> {request.district} </span> <br/>
                                        {request.confirmation === 'Pending' ? (
                                            <>
                                            <span className='statusconfirm' style={{marginLeft: '20px', color:'orange'}}> {request.confirmation} </span> <br/> 
                                            </>
                                        ):(
                                            <>
                                            <span className='statusconfirm' style={{marginLeft: '20px', color:'green'}}> {request.confirmation} </span> <br/> 
                                            </>
                                        )}
                                        {request.confirmation === 'Confirmed' ? (
                                            <button className='viewbtn' > View Profile </button>
                                        ) : (
                                            <button className='viewbtn' onClick={confirm} value={request.sent_id}> Confirm </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}

                        {activeTab === 'received' && (
                            <div>
                                <h5 className='reqtitle' id='RecievedReq'> Received </h5>
                                {recievedRequest && recievedRequest.map((request) => (
                                    <div className='requestprofile' key={request.sent_id}>
                                        {request.profile? (
                                            <img src={`http://localhost:8070/public/images/${request.profile}`} alt='' className='requestimg'
                                            style={{borderRadius:'50%', height:'100px', width:'100px', objectFit: 'fill'}}/>
                                        ) : (
                                            <img src={user} alt='' className='requestimg'/>
                                        )                              
                                        } 
                                        <span className='requsername'> {request.fullName} </span> <br/>
                                        <span className='reqlocation'> {request.district} </span> <br/>
                                        {request.confirmation === 'Confirmed' ? (
                                            <button className='viewbtn' id='recbtn'> View Profile </button>
                                        ) : (
                                            <button className='viewbtn' id='recbtn' onClick={() => confirm(request.sent_id)}> Confirm </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            <br/> <br/>
            <Footer/>
        </div>
    )

}

export default Dashboard;