import React, { Component } from 'react';
import '../../styles/customer/Home.css';
import logo from '../../images/homelogo.png';
import titleimage from '../../images/title.png';
import Grid from "@mui/material/Grid";
import right from '../../images/right.png';
import left from '../../images/left.png';
import subscription from '../../images/subscription.png';
import story1 from '../../images/story1.jpg';
import story2 from '../../images/story2.jpg';
import marry from '../../images/marry.jpg';
import find from '../../images/find.jpg';
import meet from '../../images/meet.jpg';
import info from '../../images/info.png';
import register from '../../images/register.png';
import { Link } from 'react-router-dom';
import Service1 from './Service1';
import Service2 from './Service2';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isService: true
        }
    }

    changeService=()=>{
        if(this.state.isService)
            this.setState({isService: false});
        else    
            this.setState({isService: true});
    }

    render() {
        const containerStyle = {
            fontFamily: 'Aclonica, sans-serif'
        }; 

        const buttonStyle = {
            fontFamily: 'Angkor, sans-serif'
        }; 

        const commonStyle = {
            fontFamily: 'Alike, sans-serif'
        }; 

        return (
            <div>
                <div className='navbarhome'>
                    <img src = {logo} alt = " "/>
                </div>
                <Grid container>
                    <Grid item xs={1}>
                        
                    </Grid>
    
                    <Grid item xs={4}>
                        <h1 className='title'> Find your <br/> <span style={{color:"#DA3151", containerStyle}}> RIGHT </span>  MATCH <br/> here </h1> 
                        <Link to = "/login" className="getbtn" style={buttonStyle}> 
                        Get Started </Link>
                    </Grid>

                    <Grid item xs={1}>
                        
                    </Grid>

                    <Grid item xs={6}>
                        <img src = {titleimage} alt = " " className='titleimage'/>
                    </Grid>
                </Grid>
                <hr className='hrtag'/>
                <h3 className='service'> Our Services </h3>
                {
                    this.state.isService ? <Service1/> : <Service2/>
                }

                {
                    this.state.isService ?
                    <div>
                        <button className='left' disabled>
                            <img src={left} alt='' className='left'/>
                            <div className='navigatebullet1'></div>
                        </button> 
                        <button className='right' onClick={this.changeService}>
                            <img src={right} alt='' className='right'/>
                            <div className='navigatebullet2'></div>
                        </button> 
                    </div> 
                    :
                    <div> 
                        <button className='left' onClick={this.changeService}>
                            <img src={left} alt='' className='left'/>
                            <div className='navigatebullet4'></div>
                        </button> 
                        <button className='right' disabled>
                            <img src={right} alt='' className='right'/>
                            <div className='navigatebullet5'></div>
                        </button> 
                    </div>
                                                   
                }
                <br/> <br/> <br/> <br/> <br/> <br/>
                <h3 className='service'> How it works </h3>
                
                <Grid container>
                    <Grid item xs={1.75}>
                        
                    </Grid>
    
                    <Grid item xs={4}>
                        <h5 className='workleft1'> Subcription </h5>
                        <div className='imgleft1'>
                            <img src = {subscription} alt = "" className='imgleft1'/>
                        </div>

                        <h5 className='workleft2'> Get Profile Information </h5>
                        <div className='imgleft1'>
                            <img src = {info} alt = "" className='imgleft2'/>
                        </div>

                        <h5 className='workleft3'> Getting Marriage </h5>
                        <div className='imgleft1'>
                            <img src = {marry} alt = "" className='imgleft3'/>
                        </div>
                    </Grid>

                    <Grid item xs={1}>
                        <div className='numbercircle'>
                            <h4 className='number'> 1 </h4>
                        </div>

                        <div className='vl'> </div>

                        <div className='numbercircle'>
                            <h4 className='number'> 2 </h4>
                        </div>

                        <div className='vl'> </div>

                        <div className='numbercircle'>
                            <h4 className='number'> 3 </h4>
                        </div>

                        <div className='vl'> </div>

                        <div className='numbercircle'>
                            <h4 className='number'> 4 </h4>
                        </div>

                        <div className='vl'> </div>

                        <div className='numbercircle'>
                            <h4 className='number'> 5 </h4>
                        </div>

                        <div className='vl'> </div>

                        <div className='numbercircle'>
                            <h4 className='number'> 6 </h4>
                        </div>

                        <div className='vl'> </div>
                    </Grid>

                    <Grid item xs={4}>
                        <h5 className='workright1'> Register </h5>
                        <div className='imgright1'>
                            <img src = {register} alt = "" className='imgright1'/>
                        </div>

                        <h5 className='workright2'> Find Your Match </h5>
                        <div className='imgright2'>
                            <img src = {find} alt = "" className='imgright2'/>
                        </div>

                        <h5 className='workright3'> Start Meetups </h5>
                        <div className='imgright2'>
                            <img src = {meet} alt = "" className='imgright2'/>
                        </div>
                        
                    </Grid>

                    <Grid item xs={1}>
                        
                    </Grid>
                </Grid>
                <br/> <br/> <br/> <br/> 
                <h3 className='service'> Success Stories </h3>
                <a href="/all" className='all'> All Stories </a>
                <Grid container>
                    <Grid item xs={1.4}>
                        
                    </Grid>
    
                    <Grid item xs={4}>
                        <div className = 'story'>
                            <h4 className= 'storyname'> SARAH & ABHISHEK </h4>
                            <img src = {story1} alt = "" className= 'storyimg'/>
                            <div className='para'>
                            <p> With a stroke of destiny and celestial blessings, I guided a pair destined for matrimony. 
                                The stars' intricate dance foretold a story of karmic connection. Their fated union, 
                                scripted in the cosmic scrolls, unfolded seamlessly, creating a timeless love story. </p>
                            </div> 
                            <a href="/see" className='see'> See More </a>
                        </div>
                    </Grid>

                    <Grid item xs={1.5}>
                        
                    </Grid>

                    <Grid item xs={4}>
                        <div className = 'story' id='story2'>
                            <h4 className= 'storyname'> JENNY & JACK </h4>
                            <img src = {story2} alt = "" className= 'storyimg'/> <br/>
                            <div className='para'>
                            <p> In the vast expanse of love, I charted a course for a couple transcending borders and 
                                horizons. Astrological alignments hinted at a love written in the stars, overcoming 
                                cultural differences. Witnessing their cross-cultural matrimony blossom into a harmonious 
                                symphony was an astrological masterpiece. </p>
                            </div>
                            <a href="/see" className='see'> See More </a>
                        </div>                      
                    </Grid>

                    <Grid item xs={1}>
                        
                    </Grid>
                </Grid>

                <br/> <br/> <br/> <br/>

                <div className='registerbtn'>
                    <h2 className='topic'> Find your perfect Match now </h2>
                    <h4 className='sub'> Connecting couples who are searching for their perfect match. </h4>
                    <br/>  
                    <a href='/signup'>           
                    <button className='regbtn' style={commonStyle}> Register Now </button>
                    </a> 
                </div>

                <br/> <br/> 
                <footer>
                <div className='footer' style={commonStyle}>
                    <Grid container>
                        <Grid item xs={0.5}>
                        </Grid> 
                        <Grid item xs={3}>
                            <div className=''>
                                <img src = {logo} alt = " " className='footerLogo'/>
                            </div>
                            <div className='mission'>
                                Our mission is to bring people together, fostering lasting and meaningful relationships.
                            </div>
                        </Grid> 
                        <Grid item xs={0.3}>
                        </Grid> 
                        <Grid item xs={2.5}>
                            <div className='footerTitle1'>
                                Quick Links
                            </div>
                            <div className='quickLinks1'>
                                <ul>
                                    <li> <a href={`/login`}> Home </a> </li>
                                    <li> Pricing Plan </li>
                                    <li> Rating </li>
                                    <li> Success Stories </li>
                                    <li> <a href='/adminlogin'> Admin Login </a> </li>
                                </ul>
                            </div>
                        </Grid> 
                        <Grid item xs={0.1}>
                        </Grid>
                        <Grid item xs={2.5}>
                            <div className='footerTitle2'>
                                Help & Support
                            </div>
                            <div className='quickLinks2'>
                                <ul>
                                    <li> About Us </li>
                                    <li> Contact Us </li>
                                    <li> FAQs </li>
                                </ul>
                            </div>

                            <div className='footerTitleSocial'>
                                Follow Us
                            </div>
                            <div className='quickLinksSocial'>
                                <div className='socialmedia'>
                                    <i href="#" class="fa fa-instagram"></i>
                                    <i href="#" class="fa fa-facebook"></i>
                                    <i href="#" class="fa fa-google"></i>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={0.1}>
                        </Grid> 
                        <Grid item xs={3}>
                            <div className='footerTitle3'>
                                Get in touch!
                            </div>
                            <div className='quickLinks3'>
                                <ul>
                                    <li> <i href="#" class="fa fa-phone"></i> 077 913 8835 </li>
                                    <li> <i href="#" class="fa fa-envelope"></i> aarudhralanka@gmail.com </li>
                                    <li> <i href="#" class="fa fa-map-marker"></i> Bambalapitiya, Sri Lanka </li>
                                </ul>
                            </div>
                            {/*
                            <div>
                                <img src = {logo} alt = " " className='footerLogo'/>
                            </div>
                            */}
                        </Grid> 
                    </Grid>
                    <div className='copyright'>
                        <div className='copy'>
                            Copyright © 2023 aarudhra.lk. All rights reserved.
                        </div>
                    </div>

                </div>
                </footer>
                </div>
        
        );
    }
}

export default Home;