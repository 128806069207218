import React, { useState, useEffect } from 'react';
import NavBar from '../NavBar';
import '../../../styles/admin/AdminDashboard.css';
import user from '../../../images/user.png';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

function CancelProfile(){
    const containerStyle = {
        fontFamily: 'Alike, sans-serif',
    };

    const {id, user_ID} = useParams();

    const navigate = useNavigate();
    function backbtn(){
        navigate(`/admindashboard/${id}/registration/view/${user_ID}`);
    }

    const [rows, setData] = React.useState([]);

    const [reason, setReason] = useState('');

    React.useEffect(() => {
      axios.get(`http://localhost:8070/get/${user_ID}`)
        .then((data) => {
          console.log(data);
          const dateString = data.data[0].deactivatedDate;
          const dateObject = new Date(dateString);
          const date = dateObject.toLocaleDateString();
          setData({
            ID:data.data[0].cid,
            name:data.data[0].fullName,
            status:data.data[0].marital_status,
            gender:data.data[0].gender,
            date
          });
        })
        .catch(err => console.log(err));
    }, [user_ID]); 

    const showModal = (e) => {
        e.preventDefault(); 

        Modal.confirm({
            title: 'Warning',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to cancel the profile?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                axios.put(`http://localhost:8070/statusCancel/${user_ID}`)
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.error('Error updating status:', error);
                });

                axios.post(`http://localhost:8070/cancel/${user_ID}`, { reason })
                .then((response) => {
                    console.log(response);
                    message.success('Profile Cancelled!');
                })
                .catch((err) => {
                    console.log(err);
                    message.error('An error occurred! Please Try Again...');
                });
                
            },
            onCancel: () => {
                message.error('Profile Cancellation Cancelled!');
            },
        });
    };

    const [file, setFile] = useState();

    useEffect(() => {
        axios.get(`http://localhost:8070/getImage/${user_ID}`)
        .then((data)=>{
            console.log('Images', data);
            setFile(data.data[0].profile);
        })
        .catch((err) => console.log(err))
    }, [user_ID]);

    return(
        <div style={containerStyle}>
            <NavBar/>
            <div className='profileView'> 
                <form>
                <center> 
                    <h5> {rows.name} </h5> <br/>
                    {file? (
                                <img src={`http://localhost:8070/public/images/${file}`} alt='' 
                                style={{borderRadius:'50%', height:'150px', width:'150px', objectFit: 'cover'}}/>
                        ) : (
                                <img src={user} alt='' className='groom' />
                            )                              
                    } 
                    <br/> <br/>
                    <div className='labels'>
                        <label > Customer ID :  <input type='text' style={{border:'none', textAlign:'center', width:'20px', color:'#41436A'}} value={rows.ID} readOnly/> </label> <br/>
                        <label > Status : <input type='text' style={{border:'none', textAlign:'center', width:'50px', color:'#41436A'}} value={rows.status} readOnly/> </label> <br/>

                        <button className='deactivebtn' disabled> Deactivated Account </button>
                    </div>
                </center>
        
                <hr className='hrclass'/>
                <div className='subscribe'>
                    <h6> Subscription Date :  <input type='text' style={{border:'none'}} readOnly/> </h6> 
                    <h6> Expire Date :  <input type='text' style={{border:'none'}} readOnly/> </h6>
                    <h6> Deactivated Date :  <input type='text' value={rows.date} style={{color:'#41436A', border:'none'}} readOnly/> </h6>
                </div>
                <hr className='hrclass'/>
                <h5 id='subcribe'> Cancellation Reason </h5>
                <div className='reason'>
                    <input type='text' className='form-control' 
                    value={reason}
                    onChange={(event) => {
                        setReason(event.target.value);
                    }}
                    />
                </div>
                <hr className='hrclass'/>
                <button className='cancelProfile' id='back' onClick={backbtn}> Back </button>
                <button type='submit' className='cancelProfile' id='cancelProfile' onClick={showModal}> Cancel Profile </button>
                <br/> <br/> <br/>
            </form>
            </div>
        </div>
    )
}

export default CancelProfile;