import * as React from 'react';
import '../../../styles/admin/AdminDashboard.css';
import NavBar from '../NavBar';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router-dom';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
  
  function Post(props){
    const containerStyle = {
        fontFamily: 'Alike, sans-serif',
    }; 

    const tableStyle = { 
        width: 1150,
        border:'1px solid #b6b6bd',
        boxShadow:'none',
        margin:'25px',
        borderRadius:'10px',
        backgroundColor:'inherit',
        fontFamily: 'Alike, sans-serif',
        color:'#41436A',
    }

    const {id} = useParams();

    const [rows, setData] = React.useState([]);

    React.useEffect(() => {
      axios.get('http://localhost:8070/getPostDetails')
        .then((data) => {          
          console.log(data);
          setData(data.data);    
        })
        .catch(err => console.log(err));
    }, []);
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return(
        <div>
            <NavBar/>
            <br/> 
            <div style={containerStyle}> 
                <div className='customerProfile'>
                <h6> Customer Posts </h6>
                <hr/>
                <TableContainer component={Paper} sx={tableStyle}>
                <Table sx={{ width: 1148 }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>   
                            <TableCell align='center' style={{ borderRight:'1px solid #b6b6bd', width: 150, color:'#41436A', fontWeight:'bold', fontFamily: 'Alike, sans-serif' }}>User ID</TableCell>
                            <TableCell align='center' style={{ borderRight:'1px solid #b6b6bd', width: 300, color:'#41436A', fontWeight:'bold', fontFamily: 'Alike, sans-serif' }}>Name</TableCell> 
                            <TableCell align='center' style={{ borderRight:'1px solid #b6b6bd', width: 250, color:'#41436A', fontWeight:'bold', fontFamily: 'Alike, sans-serif' }}>Create Date</TableCell>                                    
                            <TableCell align='center' style={{ borderRight:'1px solid #b6b6bd', width: 150, color:'#41436A', fontWeight:'bold', fontFamily: 'Alike, sans-serif' }}>Views</TableCell>
                            <TableCell align='center' style={{ borderRight:'1px solid #b6b6bd', width: 150, color:'#41436A', fontWeight:'bold', fontFamily: 'Alike, sans-serif' }}>Prints</TableCell>
                            <TableCell align='center' style={{ width: 100, color:'#41436A', fontWeight:'bold', fontFamily: 'Alike, sans-serif' }}>View</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows && 
                        rows.map((row) => {
                        return (
                        <TableRow key={row.cid}>
                          <TableCell component="th" scope="row" style={{ color: '#41436A', borderRight: '1px solid #b6b6bd', width: 100, fontFamily: 'Alike, sans-serif' }} align="center">
                            {row.userId}
                          </TableCell>
                          <TableCell style={{ color: '#41436A', borderRight: '1px solid #b6b6bd', width: 300, fontFamily: 'Alike, sans-serif' }} align="center">
                            {row.fullName}
                          </TableCell>
                          <TableCell style={{ color: '#41436A', borderRight: '1px solid #b6b6bd', width: 250, fontFamily: 'Alike, sans-serif' }} align="center">
                            {moment(row.createDate).format('YYYY-MM-DD')}
                          </TableCell>
                          <TableCell style={{ color: '#41436A', borderRight: '1px solid #b6b6bd', width: 150, fontFamily: 'Alike, sans-serif' }} align="center">
                            {row.views}
                          </TableCell>
                          <TableCell style={{ color: '#41436A', borderRight: '1px solid #b6b6bd', width: 150, fontFamily: 'Alike, sans-serif' }} align="center">
                            {row.prints}
                          </TableCell>
                          <TableCell style={{ width: 100, fontFamily: 'Alike, sans-serif' }} align="center">
                            <a href={`/admindashboard/${id}/managepost/viewpost/${row.cid}`} style={{ textDecoration: 'none' }} > View </a>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={10} /> 
                        </TableRow>
                    )}                 
                    </TableBody>
                    <TableFooter>
                    <TableRow>
                    {rows && (<>
                    <TablePagination
                      rowsPerPageOptions={[10, { 
                        label: 'All', 
                        value: -1,
                        style:{
                          borderRadius:'30px'
                        } 
                      
                      }]}
                      colSpan={5}
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                          style:{
                            color: '#41436A',
                            textAlign:'center',
                            fontFamily: 'Alike, sans-serif',
                            border:'1px solid #41436A',
                            background:'inherit',
                            borderRadius:'30px'
                          }
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelRowsPerPage={
                        <span style={{ color:'#41436A', fontFamily: 'Alike, sans-serif', position: 'relative', top: '7px' }}>
                          Rows per page:
                        </span>
                      }
                      labelDisplayedRows={({ from, to, count }) => (
                        <span style={{ color:'#41436A', fontFamily: 'Alike, sans-serif', position: 'relative', top: '7px', float: 'left', marginLeft: '25px' }}>
                          Showing {from}–{to} of {count} entries
                        </span>
                      )}
                    />
                    </>)}
                    </TableRow>
                    </TableFooter>
                </Table>
                </TableContainer>
                </div>
            </div>
        </div>
    )
}


export default Post;