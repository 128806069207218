import React, { useEffect, useState } from 'react';
import '../../../styles/admin/AdminDashboard.css';
import NavBar from '../NavBar';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import user from '../../../images/user.png';

function Profile(){
    const commonStyle = {
        fontFamily: 'Alike, sans-serif',
    };

    const navigate = useNavigate();
    const {id, userID} = useParams();

    function handleButton() {
        navigate(`/admindashboard/${id}`);
    }

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getProfile/${userID}`)
        .then((data)=>{
            console.log(data)

            const dateString = data.data[0].dob;
            const dateObject = new Date(dateString);
            const formatDate = dateObject.toLocaleDateString();

            setUserData({
                fullName: data.data[0].fullName,
                district: data.data[0].district,
                religion: data.data[0].religion,
                nationality: data.data[0].religion,
                height: data.data[0].height,
                //dob: data.data[0].dob,
                birthtime: data.data[0].birthtime,
                cast: data.data[0].cast,
                country:data.data[0].country,
                address:data.data[0].address,
                vegnonveg:data.data[0].vegnonveg,
                weight:data.data[0].weight,
                status:data.data[0].marital_status,
                raasi:data.data[0].raasi,
                natchaththiram:data.data[0].natchaththiram,
                kiragaPawam:data.data[0].kiragaPawam,
                thosam:data.data[0].thosam,
                education:data.data[0].education,
                occupation:data.data[0].occupation,
                employee:data.data[0].employeeStatus,
                citizen:data.data[0].citizen,
                mothername:data.data[0].fullNameMother,
                motherjob:data.data[0].occupationMother,
                motheryob:data.data[0].yearOfBirthMother,
                motherpob:data.data[0].placeOfBirthMother,
                motherreligion:data.data[0].religionMother,
                mothercast:data.data[0].castMother,
                fathername:data.data[0].fullNameFather,
                fatherjob:data.data[0].occupationFather,
                fatheryob:data.data[0].yearOfBirthFather,
                fatherpob:data.data[0].placeOfBirthFather,
                fatherreligion:data.data[0].religionFather,
                fathercast:data.data[0].castFather,
                sibilings:data.data[0].sibilingDetails,
                expectation:data.data[0].expectation,
                formatDate
            });

            //console.log(formatData);

        });
    }, [userID]);

    const [file, setFile] = useState();

    useEffect(() => {
        axios.get(`http://localhost:8070/getImage/${userID}`)
        .then((data)=>{
            console.log('Images', data);
            setFile(data.data[0].profile);
        })
        .catch((err) => console.log(err))
    }, [userID]);

    const [files, setFiles] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getImages/${userID}`)
        .then((data)=>{
            console.log('Images', data);
            const filesArray = data.data[0].photos.split(',').map(file => file.trim());
            setFiles(filesArray);
        })
        .catch((err) => console.log(err))
    }, [userID]);

    const [horoscope, setHoroscope] = useState([]);
   
    useEffect(() => {
        axios.get(`http://localhost:8070/getHoroscope/${userID}`)
        .then((data)=>{
            console.log(data)
            setHoroscope({
                L1:data.data[0].L1,
                L2:data.data[0].L2,
                L3:data.data[0].L3,
                L4:data.data[0].L4,
                L5:data.data[0].L5,
                L6:data.data[0].L6,
                L7:data.data[0].L7,
                L8:data.data[0].L8,
                L9:data.data[0].L9,
                L10:data.data[0].L10,
                L11:data.data[0].L11,
                L12:data.data[0].L12,
                L13:data.data[0].L13,
                R1:data.data[0].R1,
                R2:data.data[0].R2,
                R3:data.data[0].R3,
                R4:data.data[0].R4,
                R5:data.data[0].R5,
                R6:data.data[0].R6,
                R7:data.data[0].R7,
                R8:data.data[0].R8,
                R9:data.data[0].R9,
                R10:data.data[0].R10,
                R11:data.data[0].R11,
                R12:data.data[0].R12,
                R13:data.data[0].R13
            });
        });
    }, [userID]);

    return(
        <div style={commonStyle}>
            <NavBar/>
            <h5 className='myprofiletitle'> User Profile </h5>
            <div className = 'myprofile'>
                {userData && (
                <>
                <center>
                    {file? (
                                <img src={`http://localhost:8070/public/images/${file}`} alt='' className='profileImage'
                                style={{borderRadius:'100px', height:'150px', width:'150px'}}/>
                            ) : (
                                <img src={user} alt='' className='profileImage' />
                            )                              
                    }
                </center>    
                <br/> 
                <h5 className='personaldet'> Personal Details of Bride/Groom</h5> 
                <Grid container>
                    <Grid item xs={6}>
                    <label className='profile-control'> Full Name</label> <br/>
                    <input type='text' className='profile-value' value={userData.fullName} readOnly/>
                    <br/> 
                    <label className='profile-control'> Birth Place</label> <br/>
                    <input type='text'  className='profile-value' value={userData.district} readOnly/>
                    <br/> 
                    <label className='profile-control'> Residence Country</label> <br/>
                    <input type='text' className='profile-value' value={userData.country} readOnly/>
                    <br/>
                    <label className='profile-control'> Single/Divorced</label> <br/>
                    <input type='text' className='profile-value' value={userData.status} readOnly/>
                    <br/>
                    <label className='profile-control'> Religion</label> <br/>
                    <input type='text' className='profile-value' value={userData.religion}  readOnly/>
                    <br/>
                    <label className='profile-control'> Height (cm)</label> <br/>
                    <input type='text' className='profile-value' value={userData.height}  readOnly/>
                    </Grid>
                    <Grid item xs={1}>
                        
                    </Grid>
                    <Grid item xs={5}>
                    <label className='profile-control'> Date of Birth</label> <br/>
                    <input type='text' className='profile-value' value={userData.formatDate} readOnly/>
                    <br/> 
                    <label className='profile-control'> District</label> <br/>
                    <input type='text'  className='profile-value' value={userData.district} readOnly/>
                    <br/> 
                    <label className='profile-control'> Address</label> <br/>
                    <input type='text' className='profile-value' value={userData.address} readOnly/>
                    <br/>
                    <label className='profile-control'> Vegitarian/Non-Vegitarian</label> <br/>
                    <input type='text' className='profile-value' value={userData.vegnonveg} readOnly/>
                    <br/>
                    <label className='profile-control'> Cast</label> <br/>
                    <input type='text' className='profile-value' value={userData.cast} readOnly/>
                    <br/>
                    <label className='profile-control'> Weight (kg)</label> <br/>
                    <input type='text' className='profile-value' value={userData.weight} readOnly/>
                    </Grid>
                </Grid>
                <br/>
                <h5 className='personaldet'> Details of Jathagam</h5> 
                <Grid container>
                    <Grid item xs={6}>
                    <label className='profile-control'> Birth Time</label> <br/>
                    <input type='text' className='profile-value' value={userData.birthtime} readOnly/>
                    <br/> 
                    <label className='profile-control'> Natchaththram</label> <br/>
                    <input type='text'  className='profile-value' value={userData.natchaththiram} readOnly/>
                    <br/> 
                    <label className='profile-control'> Thosam (optional)</label> <br/>
                    <input type='text' className='profile-value' value={userData.thosam} readOnly/>
                    </Grid>
                    <Grid item xs={1}>
                        
                    </Grid>
                    <Grid item xs={5}>
                    <label className='profile-control'> Raasi</label> <br/>
                    <input type='text' className='profile-value' value={userData.raasi} readOnly/>
                    <br/> 
                    <label className='profile-control'> Kiraga Pawam</label> <br/>
                    <input type='text'  className='profile-value' value={userData.kiragaPawam} readOnly/>

                    </Grid>
                </Grid> <br/>
                <h5 className='personaldet'> Qualifications and Occupations</h5> 
                <Grid container>
                    <Grid item xs={6}>
                    <label className='profile-control'> Educational Qualification(s)</label> <br/>
                    <input type='text' className='profile-value' value={userData.education} readOnly/>
                    <br/> 
                    <label className='profile-control'> Government/Private/Self-Employee/Not-Employee</label> <br/>
                    <input type='text'  className='profile-value' value={userData.employee} readOnly/>
                    </Grid>
                    <Grid item xs={1}>
                        
                    </Grid>
                    <Grid item xs={5}>
                    <label className='profile-control'> Occupation</label> <br/>
                    <input type='text' className='profile-value' value={userData.occupation} readOnly/>
                    <br/> 
                    <label className='profile-control'> Citizenship/PR/Work Permit</label> <br/>
                    <input type='text'  className='profile-value' value={userData.citizen} readOnly/>

                    </Grid>
                </Grid> <br/>
                <Grid container>
                    <Grid item xs={5}>
                    <h5 className='personaldet'> Mother's Details </h5>
                    <input type='text' className='profile-value' value={userData.mothername} readOnly/>
                    <input type='text'  className='profile-value' value={userData.motherjob} readOnly/>
                    <input type='text' className='profile-value' value={userData.motheryob} readOnly/>
                    <input type='text'  className='profile-value' value={userData.motherpob} readOnly/>
                    <input type='text' className='profile-value' value={userData.motherreligion} readOnly/>
                    <input type='text'  className='profile-value' value={userData.mothercast} readOnly/>
                    </Grid>
                    <Grid item xs={2}>
                    <br/> <br/>
                    <center>
                    <label className='profile-control' id='parent'> Full Name</label> <br/>
                    <label className='profile-control' id='parent'> Occupation</label> <br/>
                    <label className='profile-control' id='parent'> Year of Birth</label> <br/>
                    <label className='profile-control' id='parent'> Place of Birth</label> <br/>
                    <label className='profile-control' id='parent'> Religion</label> <br/>
                    <label className='profile-control' id='parent'> Cast</label> <br/>
                    </center>
                    </Grid>
                    <Grid item xs={3}>
                    <h5 className='personaldet'> Father's Details </h5>
                    <input type='text' className='profile-value' value={userData.fathername} readOnly/>
                    <input type='text'  className='profile-value' value={userData.fatherjob} readOnly/>
                    <input type='text' className='profile-value' value={userData.fatheryob} readOnly/>
                    <input type='text'  className='profile-value' value={userData.fatherpob} readOnly/>
                    <input type='text' className='profile-value' value={userData.fatherreligion} readOnly/>
                    <input type='text'  className='profile-value' value={userData.fathercast} readOnly/>
                    
                    </Grid>
                </Grid> <br/>
                <h5 className='personaldet'> Sibiling's Details</h5> 
                <textarea className="form-control" id='profile-value' value={userData.sibilings} readOnly/>

                <h5 className='personaldet'> Expectations</h5> 
                <textarea className="form-control" id='profile-value' value={userData.expectation} readOnly/>
                
                <h5 className='personaldet'> My Photos </h5> 
                <center>
                    <Carousel showThumbs={false}  className="custom-carousel">
                        {files.map((file, index) => (
                            <img src={`http://localhost:8070/public/images/${file}`} alt='' 
                            style = {{width:'400px'}} />
                        ))}
                    </Carousel> 
                </center> 
                <h5 className='personaldet'> Horoscope</h5> 
                <div>
                <form>
                    {horoscope && (
                    <>
                    <div className='horoscopetemplate3'>
                        <div className='rowdet1'>
                            <div className='squaredet1'>
                                <input type='text' className='value1' value={horoscope.L1} readOnly/>
                                <input type='text' className='value2' value={horoscope.L2} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.L3} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value4' value={horoscope.L4} readOnly/>
                                <input type='text' className='value5' value={horoscope.L5} readOnly/>
                            </div>
                            <div className='cross5'>

                            </div>
                            <div className='cross6'>
                                
                            </div>
                        </div>
                        
                        <div className='rowdet2'>
                            <div className='squaredet1'>
                                <input type='text' className='value3' value={horoscope.L6} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.L7} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value3' value={horoscope.L8} readOnly/>
                            </div>
                        </div>
                        <div className='rowdet3'>
                            <div className='squaredet1'>
                                <input type='text' className='value4' value={horoscope.L9} readOnly/>
                                <input type='text' className='value5' value={horoscope.L10} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.L11} readOnly/>
                            </div>
                            <div className='squaredet3'>
                                <input type='text' className='value1' value={horoscope.L12} readOnly/>
                                <input type='text' className='value2' value={horoscope.L13} readOnly/> 
                            </div>
                            <div className='cross7'>

                            </div>
                            <div className='cross8'>
                                
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className='horoscopetemplate4'>
                        <div className='rowdet1'>
                            <div className='squaredet1'>
                                <input type='text' className='value1' value={horoscope.R1} readOnly/>
                                <input type='text' className='value2' value={horoscope.R2} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.R3} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value4' value={horoscope.R4} readOnly/>
                                <input type='text' className='value5' value={horoscope.R5} readOnly/>
                            </div>
                            <div className='cross5'>

                            </div>
                            <div className='cross6'>
                                
                            </div>
                        </div>
                        
                        <div className='rowdet2'>
                            <div className='squaredet1'>
                                <input type='text' className='value3' value={horoscope.R6} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.R7} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value3' value={horoscope.R8} readOnly/>
                            </div>
                        </div>
                        <div className='rowdet3'>
                            <div className='squaredet1'>
                                <input type='text' className='value4' value={horoscope.R9} readOnly/>
                                <input type='text' className='value5' value={horoscope.R10} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.R11} readOnly/>
                            </div>
                            <div className='squaredet3'>
                                <input type='text' className='value1' value={horoscope.R12} readOnly/>
                                <input type='text' className='value2' value={horoscope.R13} readOnly/> 
                            </div>
                            <div className='cross7'>

                            </div>
                            <div className='cross8'>
                                
                            </div>
                        </div>
                        
                    </div>
                    </>
                    )}
                </form>
            </div>
                <center>
                    <button className='donebtn' style={commonStyle} onClick={handleButton}> Done </button>
                </center> 
                </>
                )} 
            </div>
        </div>
    )
}

export default Profile;