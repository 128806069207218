import React, { useState, useEffect } from 'react';
import NavBar from '../NavBar';
import '../../../styles/admin/AdminDashboard.css';
import user from '../../../images/user.png';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';

function View () {
    const containerStyle = {
        fontFamily: 'Alike, sans-serif',
    }; 

    const navigate = useNavigate();
    function cancelProfile(){
        navigate(`/admindashboard/${id}/registration/view/cancelProfile/${user_ID}`);
    }

    const [rows, setData] = React.useState([]);
    const {id, user_ID} = useParams();

    const [active, setActive] = useState(false);
    const [deactive, setDeactive] = useState(false);

    const handleActivate = () => {
        axios.put(`http://localhost:8070/statusActive/${user_ID}`)
            .then((response) => {
                console.log(response);
                setActive(true);
                setDeactive(false);
                message.success('Activated!');
            })
            .catch((error) => {
                console.error('Error updating status:', error);
            });
        
    }

    const handleDeactivate = () => {
        axios.put(`http://localhost:8070/statusDeactive/${user_ID}`)
            .then((response) => {
                console.log(response);
                setActive(false);
                setDeactive(true);
                message.success('Deactivated!');
            })
            .catch((error) => {
                console.error('Error updating status:', error);
            });
        
    }

    React.useEffect(() => {
        axios.get(`http://localhost:8070/getStatus/${user_ID}`)
          .then((data) => {
            console.log(data);
            const getStatus = data.data[0].status;
            console.log(data);
            if(getStatus === 'Active'){
                setActive(true);
                setDeactive(false);
            }
        
            if(getStatus === 'Deactive'){
                setActive(false);
                setDeactive(true);
            }
        
          })
          .catch(err => console.log(err));
    }, [user_ID]);  

    React.useEffect(() => {
      axios.get(`http://localhost:8070/get/${user_ID}`)
        .then((data) => {
          console.log(data);
          
          const dateString = data.data[0].dob;
          const birthYear = new Date(dateString).getFullYear();
          
          console.log(birthYear);

          const currentYear = new Date().getFullYear();

          console.log(currentYear);

          const age = currentYear - birthYear;

          setData({
            ID:data.data[0].cid,
            name:data.data[0].fullName,
            status:data.data[0].marital_status,
            age,
            gender:data.data[0].gender
          });
        })
        .catch(err => console.log(err));
    }, [user_ID]);

    const [file, setFile] = useState();

    useEffect(() => {
        axios.get(`http://localhost:8070/getImage/${user_ID}`)
        .then((data)=>{
            console.log('Images', data);
            setFile(data.data[0].profile);
        })
        .catch((err) => console.log(err))
    }, [user_ID]);

    return(
        <div style={containerStyle}>
            <NavBar/>
            <div className='profileView'>
                <a href={`/admindashboard/${id}/registration/view/viewDetails/${user_ID}`} style={{ textDecoration: 'none', float:'right', marginRight:'30px'}} > View Profile </a> 
                <br/> <br/>
                <center> 
                    <h5> {rows.name} </h5> <br/>
                    {file? (
                                <img src={`http://localhost:8070/public/images/${file}`} alt='' className='profileImage'
                                style={{borderRadius:'100px', height:'150px', width:'150px'}}/>
                            ) : (
                                <img src={user} alt='' className='profileImage' />
                            )                              
                    }
                </center>
                <br/>
                <div className='labels'>
                    <label style={{float:'left'}}> Age : {rows.age} </label>
                    <label className='label2' > Customer ID : {rows.ID} </label>
                    <label style={{float:'right'}}> Status : {rows.status} </label>
                </div>
                <hr className='hrclass'/>
                <h5 id='subcribe'> Subscription Details </h5>
                <div className='subscribe'>
                    <h6> Subscription Type :  </h6>
                    <h6> Subscription Date :  </h6>
                    <h6> Subscription Month :  </h6>
                    <h6> Expire Date :  </h6>
                    <h6> Valid Days :  </h6>
                </div>
                <hr className='hrclass'/>
                <h5 id='subcribe'> Active Status </h5>
                <br/>
                <div className='toggle'>
                    <label class="switch" id='switch1'>
                        <input type="checkbox" 
                        checked={active} 
                        onClick={handleActivate} /> 
                        <span class="slider round"> <span class='activateName'> Activate </span> </span>  
                    </label>
                    
                    <label class="switch" id='switch2'>
                        <input type="checkbox" 
                        checked={deactive} 
                        onClick={handleDeactivate}/>
                        <span class="slider round"> <span class='deactivateName'> Deactivate </span> </span>
                    </label>
                </div>
                <br/> <br/> 
                <hr className='hrclass'/>
                <center> 
                    <button className='cancelProfile' onClick={cancelProfile}> Cancel Profile </button>
                </center>
            </div>
        </div>
    )
}

export default View;