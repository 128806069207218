import React from "react";
import Grid from "@mui/material/Grid";
import '../../styles/customer/Home.css';
import service1 from '../../images/service1.jpg';
import service2 from '../../images/bride.png';
import service3 from '../../images/service3.jpg';
import { useParams } from "react-router-dom";

function Service1(){
    const serviceStyle = {
        fontFamily: 'Jockey One, sans-serif'
    }; 
    
    const {id} = useParams();
    return(
        <div>
            <Grid container>
                    <Grid item xs={0.85}>
                        
                    </Grid>
    
                    <Grid item xs={3}>
                        <a href={`/${id}/searchGrooms`}> 
                        <div className='service1'>
                            <div>
                                <img src = {service1} alt = " "/>
                            </div>
                            <span className='servicename1' style={serviceStyle}> Grooms </span>
                        </div>
                        </a> 
                    </Grid>

                    <Grid item xs={0.75}>
                        
                    </Grid>

                    <Grid item xs={3}>
                    <a href={`/${id}/searchBrides`}> 
                        <div className='service2'>
                            <div>
                                <img src = {service2} alt = " "/>
                            </div>
                            <span className='servicename2' style={serviceStyle}> Brides </span>
                        </div>
                    </a>  
                    </Grid>

                    <Grid item xs={0.75}>
                        
                    </Grid>

                    <Grid item xs={3}>
                        <div className='service3'>
                            <div>
                                <img src = {service3} alt = " "/>
                            </div>
                            <span className='servicename3' style={serviceStyle}> Astrologers </span>
                        </div> 
                    </Grid>

                    <Grid item xs={1}>
                        
                    </Grid>
                </Grid>
        </div>    
    )
}

export default Service1;