import React, { useEffect, useState } from 'react';
import '../../styles/customer/CompleteProfile.css';
import NavBar from './NavBar';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import Horoscope from './HoroscopeDetails';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function Profile(){
    const commonStyle = {
        fontFamily: 'Alike, sans-serif',
    };

    const navigate = useNavigate();
    const {id} = useParams();

    function handleButton() {
        navigate(`/dashboard/${id}`);
    }

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getProfile/${id}`)
        .then((data)=>{
            console.log(data)

            const dateString = data.data[0].dob;
            const dateObject = new Date(dateString);
            const formatDate = dateObject.toLocaleDateString();

            setUserData({
                fullName: data.data[0].fullName,
                district: data.data[0].district,
                religion: data.data[0].religion,
                nationality: data.data[0].religion,
                height: data.data[0].height,
                //dob: data.data[0].dob,
                birthtime: data.data[0].birthtime,
                cast: data.data[0].cast,
                country:data.data[0].country,
                address:data.data[0].address,
                vegnonveg:data.data[0].vegnonveg,
                weight:data.data[0].weight,
                status:data.data[0].marital_status,
                raasi:data.data[0].raasi,
                natchaththiram:data.data[0].natchaththiram,
                kiragaPawam:data.data[0].kiragaPawam,
                thosam:data.data[0].thosam,
                education:data.data[0].education,
                occupation:data.data[0].occupation,
                employee:data.data[0].employeeStatus,
                citizen:data.data[0].citizen,
                mothername:data.data[0].fullNameMother,
                motherjob:data.data[0].occupationMother,
                motheryob:data.data[0].yearOfBirthMother,
                motherpob:data.data[0].placeOfBirthMother,
                motherreligion:data.data[0].religionMother,
                mothercast:data.data[0].castMother,
                fathername:data.data[0].fullNameFather,
                fatherjob:data.data[0].occupationFather,
                fatheryob:data.data[0].yearOfBirthFather,
                fatherpob:data.data[0].placeOfBirthFather,
                fatherreligion:data.data[0].religionFather,
                fathercast:data.data[0].castFather,
                sibilings:data.data[0].sibilingDetails,
                expectation:data.data[0].expectation,
                formatDate
            });

            //console.log(formatData);

        });
    }, [id]);

    const [file, setFile] = useState();

    useEffect(() => {
        axios.get(`http://localhost:8070/getImage/${id}`)
        .then((data)=>{
            console.log('Images', data);
            setFile(data.data[0].profile);
        })
        .catch((err) => console.log(err))
    }, [id]);

    const [files, setFiles] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getImages/${id}`)
        .then((data)=>{
            console.log('Images', data);
            const filesArray = data.data[0].photos.split(',').map(file => file.trim());
            setFiles(filesArray);
        })
        .catch((err) => console.log(err))
    }, [id]);

    return(
        <div style={commonStyle}>
            <NavBar/>
            <div className = 'myprofile'>
                <p className='myprofiletitle'> Profile </p>
                <a href={`/main/dashboard/editprofile/${id}`} className='editprofile'> Edit </a> <br/> <br/>
                {userData && (
                <>
                <center>
                    {file && 
                        <img src={`http://localhost:8070/public/images/${file}`} alt='' className='groom'
                        style={{borderRadius:'50%', height:'150px', width:'150px', objectFit: 'fill'}} />
                    }
                    <h5 className='myprofilevalues'> {userData.fullName} </h5>
                </center>    
                <br/> 
                <h5 className='personaldet'> Personal Details of Bride/Groom</h5> 
                <Grid container>
                    <Grid item xs={6}>
                    <label className='profile-control'> Full Name</label> <br/>
                    <input type='text' className='profile-value' value={userData.fullName} readOnly/>
                    <br/> 
                    <label className='profile-control'> Birth Place</label> <br/>
                    <input type='text'  className='profile-value' value={userData.district} readOnly/>
                    <br/> 
                    <label className='profile-control'> Residence Country</label> <br/>
                    <input type='text' className='profile-value' value={userData.country} readOnly/>
                    <br/>
                    <label className='profile-control'> Single/Divorced</label> <br/>
                    <input type='text' className='profile-value' value={userData.status} readOnly/>
                    <br/>
                    <label className='profile-control'> Religion</label> <br/>
                    <input type='text' className='profile-value' value={userData.religion}  readOnly/>
                    <br/>
                    <label className='profile-control'> Height (cm)</label> <br/>
                    <input type='text' className='profile-value' value={userData.height}  readOnly/>
                    </Grid>
                    <Grid item xs={1}>
                        
                    </Grid>
                    <Grid item xs={5}>
                    <label className='profile-control'> Date of Birth</label> <br/>
                    <input type='text' className='profile-value' value={userData.formatDate} readOnly/>
                    <br/> 
                    <label className='profile-control'> District</label> <br/>
                    <input type='text'  className='profile-value' value={userData.district} readOnly/>
                    <br/> 
                    <label className='profile-control'> Address</label> <br/>
                    <input type='text' className='profile-value' value={userData.address} readOnly/>
                    <br/>
                    <label className='profile-control'> Vegitarian/Non-Vegitarian</label> <br/>
                    <input type='text' className='profile-value' value={userData.vegnonveg} readOnly/>
                    <br/>
                    <label className='profile-control'> Cast</label> <br/>
                    <input type='text' className='profile-value' value={userData.cast} readOnly/>
                    <br/>
                    <label className='profile-control'> Weight (kg)</label> <br/>
                    <input type='text' className='profile-value' value={userData.weight} readOnly/>
                    </Grid>
                </Grid>
                <br/>
                <h5 className='personaldet'> Details of Jathagam</h5> 
                <Grid container>
                    <Grid item xs={6}>
                    <label className='profile-control'> Birth Time</label> <br/>
                    <input type='text' className='profile-value' value={userData.birthtime} readOnly/>
                    <br/> 
                    <label className='profile-control'> Natchaththram</label> <br/>
                    <input type='text'  className='profile-value' value={userData.natchaththiram} readOnly/>
                    <br/> 
                    <label className='profile-control'> Thosam (optional)</label> <br/>
                    <input type='text' className='profile-value' value={userData.thosam} readOnly/>
                    </Grid>
                    <Grid item xs={1}>
                        
                    </Grid>
                    <Grid item xs={5}>
                    <label className='profile-control'> Raasi</label> <br/>
                    <input type='text' className='profile-value' value={userData.raasi} readOnly/>
                    <br/> 
                    <label className='profile-control'> Kiraga Pawam</label> <br/>
                    <input type='text'  className='profile-value' value={userData.kiragaPawam} readOnly/>

                    </Grid>
                </Grid> <br/>
                <h5 className='personaldet'> Qualifications and Occupations</h5> 
                <Grid container>
                    <Grid item xs={6}>
                    <label className='profile-control'> Educational Qualification(s)</label> <br/>
                    <input type='text' className='profile-value' value={userData.education} readOnly/>
                    <br/> 
                    <label className='profile-control'> Government/Private/Self-Employee/Not-Employee</label> <br/>
                    <input type='text'  className='profile-value' value={userData.employee} readOnly/>
                    </Grid>
                    <Grid item xs={1}>
                        
                    </Grid>
                    <Grid item xs={5}>
                    <label className='profile-control'> Occupation</label> <br/>
                    <input type='text' className='profile-value' value={userData.occupation} readOnly/>
                    <br/> 
                    <label className='profile-control'> Citizenship/PR/Work Permit</label> <br/>
                    <input type='text'  className='profile-value' value={userData.citizen} readOnly/>

                    </Grid>
                </Grid> <br/>
                <Grid container>
                    <Grid item xs={5}>
                    <h5 className='personaldet'> Mother's Details </h5>
                    <input type='text' className='profile-value' id='parentdet' value={userData.mothername} readOnly/>
                    <input type='text'  className='profile-value' id='parentdet' value={userData.motherjob} readOnly/>
                    <input type='text' className='profile-value' id='parentdet' value={userData.motheryob} readOnly/>
                    <input type='text'  className='profile-value' id='parentdet' value={userData.motherpob} readOnly/>
                    <input type='text' className='profile-value' id='parentdet' value={userData.motherreligion} readOnly/>
                    <input type='text'  className='profile-value' id='parentdet' value={userData.mothercast} readOnly/>
                    </Grid>
                    <Grid item xs={2}>
                    <br/> <br/>
                    <center className='parent'>
                    <label className='profile-control' id='parent'> Full Name</label> <br/>
                    <label className='profile-control' id='parent-det'> Occupation</label> <br/>
                    <label className='profile-control' id='parent-det'> Year of Birth</label> <br/>
                    <label className='profile-control' id='parent-det'> Place of Birth</label> <br/>
                    <label className='profile-control' id='parent-det'> Religion</label> <br/>
                    <label className='profile-control' id='parent-det'> Cast</label> <br/>
                    </center>
                    </Grid>
                    <Grid item xs={3}>
                    <h5 className='personaldet'> Father's Details </h5>
                    <input type='text' className='profile-value' id='parentdet' value={userData.fathername} readOnly/>
                    <input type='text'  className='profile-value' id='parentdet' value={userData.fatherjob} readOnly/>
                    <input type='text' className='profile-value' id='parentdet' value={userData.fatheryob} readOnly/>
                    <input type='text'  className='profile-value' id='parentdet' value={userData.fatherpob} readOnly/>
                    <input type='text' className='profile-value' id='parentdet' value={userData.fatherreligion} readOnly/>
                    <input type='text'  className='profile-value' id='parentdet' value={userData.fathercast} readOnly/>
                    
                    </Grid>
                </Grid> <br/>
                <h5 className='personaldet'> Sibiling's Details</h5> 
                <textarea className="form-control" id='profile-value' value={userData.sibilings} readOnly/>

                <h5 className='personaldet'> Expectations</h5> 
                <textarea className="form-control" id='profile-value' value={userData.expectation} readOnly/>
                
                <h5 className='personaldet'> My Photos </h5> <br/>
                <center className='web-carousel'>
                    <Carousel showThumbs={false} className="custom-carousel">
                        {files.map((file, index) => (
                            <img src={`http://localhost:8070/public/images/${file}`} alt='' className='groom'
                            style = {{width:'400px'}} />
                        ))}
                    </Carousel>  
                </center> <br/>
                <center className='mobile-carousel'>
                    <Carousel showThumbs={false} className="custom-carousel">
                        {files.map((file, index) => (
                            <img src={`http://localhost:8070/public/images/${file}`} alt='' className='groom'
                            style = {{width:'300px'}} />
                        ))}
                    </Carousel>  
                </center> <br/>
                <h5 className='personaldet'> Horoscope</h5> 
                <Horoscope/>
                <center>
                    <button className='donebtn' style={commonStyle} onClick={handleButton}> Done </button>
                </center> 
                </>
                )} 
            </div>
        </div>
    )
}

export default Profile;