import React, { useEffect, useState } from 'react';
import '../../styles/customer/Dashboard.css';
import NavBar from './NavBar';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Footer from './Footer';

function Notification (){
    const commonStyle = {
        fontFamily: 'Alike, sans-serif',
    };

    
    const {id} = useParams();
    console.log(id);

    const [status, setStatus] = useState('');
    
    useEffect(() => {
        axios.get(`http://localhost:8070/getStatus/${id}`)
        .then((response)=>{
            console.log(response);
            if (response.data.length > 0) {
                setStatus(response.data[0].status);
            }
        })
    }, [id])

    const [post, setPost] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getPost/${id}`)
        .then((data) => {          
            console.log(data);
            setPost(data.data);   
            setPost({
                status: data.data[0].post_status
            })

          })
        .catch(err => console.log(err));
    });

    return(
        <div style={commonStyle}>
            <NavBar/>
            {status === 'Active'? (
                <div className='account-active'> 
                    <h6 style={{fontWeight:'bold'}}> Your Account is Activated !  </h6>
                    <label> Now you can create a post to public for all users. </label>
                </div>
            ) : status === 'Deactive'? (
                <div className='account-deactive'>
                    <h6 style={{fontWeight:'bold'}}> Your Account is Deactivated !  </h6>
                    <label> Please make your subscription otherwise your account will be cancelled. </label>
                </div>
            ):(
                <div className='account-cancel'>
                    <h6 style={{fontWeight:'bold'}}> Your Account is Cancelled !  </h6>
                    <label> </label>
                </div>
            )}

            {post.status === 'deactive'? (
                <div className='account-deactive'> 
                    <h6 style={{fontWeight:'bold'}}> Your Post is Deactivated !  </h6>
                    {status === 'Deactive'? (
                        <label> Your account is deactivated. Please make your subscription otherwise your post is not visible for users. </label>
                    ):status === 'Cancel'? (
                        <label> Your account is cancelled. So, the post permently invisible for users. </label>
                    ):(
                        <label> There may be any invalid inputs in the input fields. Make sure correct those otherwise your post is not visible for users. </label>
                    )}
                    
                </div>
            ) : null}
            <br/> <br/>
            <Footer/>
        </div>
    )

}

export default Notification;