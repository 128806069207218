import * as React from 'react';
import '../../styles/admin/AdminDashboard.css';
import NavBar from './NavBar';
import astro1 from '../../images/astro1.png';
import users from '../../images/users.png';
import proposal from '../../images/proposal.png';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { PieChart } from '@mui/x-charts/PieChart';
import { useNavigate, useParams } from 'react-router-dom';

function Dashboard () {
        const containerStyle = {
            fontFamily: 'Alike, sans-serif',
        }; 

        const tableStyle = {
            border:'none',
            boxShadow:'none'
        }

        function createData(earnings, bank, credit, total) {
            return { earnings, bank, credit, total };
          }
          
        const rows = [
            createData('Customer Packages', 19750, 11000, 30750),
            createData('View Profile', 19750, 11000, 30750),
            createData('Promotions', 19750, 11000, 30750),
            createData('Astrologers Package Charges', 19750, 11000, 30750),
            createData('Astrologers Promotions Charges', 19750, 11000, 30750),
            createData('Others', 19750, 11000, 30750),
        ];

        const navigate = useNavigate();

        const {id} = useParams();

        function handleRegistration(){
            navigate(`/admindashboard/${id}/registration`);
        }

        function handleManage(){
            navigate(`/admindashboard/${id}/managepost`);
        }
        
        return (
            <div>
                
                <NavBar/>
                <br/>
                <div className='dashboard' style={containerStyle}>
                    <div class="dashboardTop">
                        <div className="dashboardTopBlock"> 
                        <img src={users} alt=''/>
                        <span className='name'> Users </span> 
                        <span className='value'> 10 </span>
                        </div>
                    </div>

                    
                    <div class="dashboardTop">
                        <div className="dashboardTopBlock"> 
                        <img src={proposal} alt=''/>
                        <span className='name'> Proposals </span> 
                        <span className='value'> 50 </span>
                        </div>
                    </div>

                    <div class="dashboardTop">
                        <div className="dashboardTopBlock">
                        <img src={astro1} alt=''/> 
                        <span className='name'> Astrologers </span> 
                        <span className='value'> 10 </span>
                        </div>
                    </div>

                    <div class="dashboardTop">
                        <div className="dashboardTopBlock"> 
                        <i class="fa fa-star checked"></i>
                        <span className='name'> Feedback & Ratings </span>
                        <span className='value'> 4.3 </span>
                        </div>
                    </div>
                    <br/> <br/> <br/> <br/>
                    <div class="dashboardSub">
                        <div className="dashboardSubbtn"> 
                            <button className="subbtn" id='btn1' onClick={handleManage}> Manage Post </button>
                        </div>
                        <div className="dashboardSubbtn"> 
                            <button className="subbtn" onClick={handleRegistration}> Manage Registration </button>
                        </div>
                        <div className="dashboardSubbtn"> 
                            <button className="subbtn"> Payments </button>
                        </div>
                        <div className="dashboardSubbtn"> 
                            <button className="subbtn"> Ratings & Feedback </button>
                        </div>
                        <div className="dashboardSubbtn"> 
                            <button className="subbtn" id='btn5'> Manage Proposals </button>
                        </div>
                    </div>

                    <br/> <br/> <br/> <br/>
                    <div className='earnings'>
                        <h5> Earnings </h5>
                        <div style={containerStyle}>
                        <TableContainer component={Paper} style={tableStyle}>
                            <Table style={{ width: 1200 }} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell> </TableCell>
                                    <TableCell align='right' style={{ width: 200, color:'#41436A', fontWeight:'bold', fontFamily: 'Alike, sans-serif' }}>Bank Deposit</TableCell>
                                    <TableCell align='right' style={{ width: 200, color:'#41436A', fontWeight:'bold', fontFamily: 'Alike, sans-serif' }}>Credit/Deposit</TableCell>
                                    <TableCell align='right' style={{ width: 200, color:'#41436A', fontWeight:'bold', fontFamily: 'Alike, sans-serif' }}>Total</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                    key={row.earnings}
                                    >
                                    <TableCell component="th" scope="row" style={{ color:'#41436A', fontFamily: 'Alike, sans-serif' }}>
                                        {row.earnings}
                                    </TableCell>
                                    <TableCell align='right' style={{ width: 200, color:'#41436A', fontFamily: 'Alike, sans-serif' }}>{row.bank}</TableCell>
                                    <TableCell align='right' style={{ width: 200, color:'#41436A', fontFamily: 'Alike, sans-serif' }}>{row.credit}</TableCell>
                                    <TableCell align='right' style={{ width: 200, color:'#41436A', fontFamily: 'Alike, sans-serif' }}>{row.total}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </div>
                        <br/>
                    </div>
                    <br/> <br/>
                    <div className='report'>
                        <h5> Revenue Summery Report for Promotions </h5>
                        <Grid container>
                            <Grid item xs={1}>

                            </Grid>
                            <Grid item xs={5}>                             
                                <PieChart
                                colors={['#fa2b5f', '#00ff73', '#00ffff', '#2e355c', '#f8f678']} 
                                series={[
                                    {
                                    data: [
                                        { id: 0, value: 20 },
                                        { id: 1, value: 5 },
                                        { id: 2, value: 9 },
                                        { id: 3, value: 11 },
                                        { id: 4, value: 28 },
                                    ],
                                    },
                                ]}
                                width={400}
                                height={400}
                                />
                            </Grid>
                            <Grid item xs={1}>

                            </Grid>
                            <Grid item xs={4}>
                                <br/>
                                <div>
                                    <ul>
                                        <li> <i className='fa fa-minus' id='promo1'></i> Discounted Rate </li>
                                        <li> <i className='fa fa-minus' id='promo2'></i> Extra Services</li>
                                        <li> <i className='fa fa-minus' id='promo3'></i> Limited-time Offers</li>
                                        <li> <i className='fa fa-minus' id='promo4'></i> Promo Codes</li>
                                        <li> <i className='fa fa-minus' id='promo5'></i> Seasonal Promotions</li>
                                    </ul>
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                
                            </Grid>
                        </Grid>
                        <br/> 
                    </div>
                    <br/> <br/>
                </div>

            </div>
        
        );
    
}

export default Dashboard;