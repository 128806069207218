import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import login from '../../images/login.png';
import '../../styles/customer/Login.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { message } from 'antd';

function SignUp() {
  const commonStyle = {
    fontFamily: 'Alike, sans-serif',
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const [emailError, setEmailError] = useState('');

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[a-z0-9]+@gmail\.com$/;
    const isValid = emailRegex.test(inputEmail);

    setEmailError(isValid ? '' : 'Invalid Email Format');
    return isValid;
  };

  const [passwordError, setPasswordError] = useState('');

  //Checks for numbers in string
  function containsNumber(str) {
    return /[0-9]/.test(str);
  }

  //Checks for capital in string
  function containsCapital(str) {
      return /[A-Z]/.test(str);
  }


  const validatePassword = (inputPassword) => {
    // Reset password error message
    setPasswordError('');

    // If password doesn't contain an uppercase letter
    if (!containsCapital(inputPassword)) {
      setPasswordError('Password must contain at least one uppercase letter');
    }

    // If password doesn't contain a number
    else if (!containsNumber(inputPassword)) {
      setPasswordError('Password must contain at least one number');
    }

    // If password is less than 8 characters
    else if (inputPassword.length < 8) {
      setPasswordError('Password must be at least 8 characters long');
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordsMatch(e.target.value === confirmPassword);
    validatePassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordsMatch(password !== e.target.value ? 'Passwords do not match' : '');
  };

  const buttonStyle = {
    fontFamily: 'Angkor, sans-serif'
  }; 

  const navigate = useNavigate();

  const createAccount = async (e) =>{
    try {
      await axios.post(`http://localhost:8070/register`, {
      email: email,
      password: password,
    });

    message.success('Account Created!');
    navigate('/login');

    } catch (error) {
      console.error(error.message);
    }
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email) {
      axios.get(`http://localhost:8070/getByEmail?email=${email}`)
        .then((res) => {
          console.log(res);
          if (res.data.length > 0) {
            message.error('Email already exists!');
          }else{
            createAccount();
          }
        })
        .catch((error) => console.log(error));
    }
    
  };

  return (
    <div>
      <Grid container>

        <Grid item xs={7}>
          <Link to = "/login" className="btn2" style={buttonStyle}> Login </Link>
          <div className='signupimg'>
            <img src={login} alt='' />
          </div>
        </Grid>

        <Grid item xs={4}>
          <div className='signup'>
            <div className='form'>
              <form onSubmit={handleSubmit}>
                <h4>Sign Up</h4> <br />
                <div className='mb-3'>
                  <label className='form-label'>Email</label>
                    <input
                        type='email'
                        value={email}
                        onChange={(event) => {
                        setEmail(event.target.value);
                        validateEmail(event.target.value);
                        }}
                        className={`form-control ${emailError ? 'is-invalid' : (email ? 'is-valid' : '')}`}
                        id='exampleInputEmail1'
                        aria-describedby='emailHelp'
                        required
                    />
                    {emailError && 
                    <div className='invalid-feedback'>{emailError}</div>
                    }
                    
                </div> <br/>
                <div className='mb-3'>
                  <label className='form-label'>Password</label>
                  <input
                    type='password'
                    value={password}
                    onChange={handlePasswordChange}
                    className={`form-control ${passwordError ? 'is-invalid' : (password ? 'is-valid' : '')}`}
                    id='exampleInputEmail1'
                    aria-describedby='emailHelp'
                  required/>
                  {passwordError && <div className='invalid-feedback'>{passwordError}</div>}
                </div>  <br/>
                <div className='mb-3'>
                  <label className='form-label'>Confirm Password</label>
                  <input
                    type='password'
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    className={`form-control ${passwordsMatch ? 'is-invalid' : (confirmPassword ? 'is-valid' : '')}`}
                    id='exampleInputEmail1'
                    aria-describedby='emailHelp'
                  required/>
                  {passwordsMatch && <div className='invalid-feedback'>{passwordsMatch}</div>}
                </div>
                <button className='signupbtn' style={commonStyle}>
                  Get Started
                </button>
              </form>
            </div>
          </div>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </div>
  );
}

export default SignUp;
