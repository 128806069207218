import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import login from '../../images/login.png';
import '../../styles/admin/AdminLogin.css';
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';

function ForgotPassword() {
    const commonStyle = {
        fontFamily: 'Alike, sans-serif'
    }; 

    const [email, setEmail] = useState('');
    const [newpassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
        setPasswordsMatch(e.target.value === confirmPassword);
    };
    
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setPasswordsMatch(newpassword === e.target.value);
    };

    const navigate = useNavigate();

    //Checks for numbers in string
    function containsNumber(str) {
        return /[0-9]/.test(str);
    }

    //Checks for capital in string
    function containsCapital(str) {
        return /[A-Z]/.test(str);
    }

    const updatePassword = async (e) => {
        try{
            const response = await axios.put('http://localhost:8070/adminforgotpassword',{
                email:email,
                newpassword:newpassword,
            });

            if(response.data.includes("Password Updated")){
                message.success('Password Updated!');
                navigate('/login');
            }else{
                message.error(response.data);
            }
            
            
        }catch(err){
            console.log(err);
            message.error('An error occurred! Please Try Again...');
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        //If password doesn't contain an uppercase letter
        if (!containsCapital(newpassword)) {
            message.error('Password must contain at least one uppercase letter');
            return;
        }
    
        //If password doesn't contain a number
        if (!containsNumber(newpassword)) {
            message.error('Password must contain at least one number');
            return;
        }
    
        //If password is less than 8 characters
        if (newpassword.length < 8) {
            message.error('Password must be at least 8 characters long');
            return;
        }
  
        // Check if passwords match before submitting the form
        if (!passwordsMatch) {
            message.error('Passwords do not match!');
            return;
        } 

        updatePassword();

    };   

    return(
        <div>
            <Grid container>
                <Grid item xs={4}>
                    <div className='forget'>
                        <div className='form'>
                        <form onSubmit={handleSubmit}>
                            <h4>Forgot Password</h4> <br/>
                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(event) => {
                                    setEmail(event.target.value);
                                    }}
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                required/>
                            </div>
                            <br />
                            <div className="mb-3">
                                <label className="form-label">New Password</label>
                                <input
                                    type="password"
                                    value={newpassword}
                                    onChange={handlePasswordChange}
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                required/>
                            </div>
                            <br/>
                            <div className="mb-3">
                                <label className="form-label">Confirm Password</label>
                                <input
                                    type="password"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                required/>
                            </div>
                            
                            <span className='backtoLogin'> Back to <a href = '/login'> Login </a></span>
                            <br/>  <br/>

                            <button type='submit' className='logbtn' style={commonStyle}> Reset </button>
                        </form>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={7}>
                    <div className='forgetimg'>
                        <img src={login} alt=''/>
                    </div>                   
                </Grid>
            </Grid>
        </div>
    );

}

export default ForgotPassword;