import React, {useState, useEffect} from 'react';
import '../../styles/customer/CompleteProfile.css';
import NavBar from './NavBar';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Modal,message } from 'antd';
import Footer from './Footer';
import { ExclamationCircleOutlined } from '@ant-design/icons';

function CompleteProfile(){
    const commonStyle = {
        fontFamily: 'Alike, sans-serif',
    };

    const {id} = useParams();

    const navigate = useNavigate();

    useEffect(() => {
            axios.get(`http://localhost:8070/getProfile/${id}`)
            .then((data)=>{
                console.log(data)
                const dateString = data.data[0].dob;
                const dateObject = new Date(dateString);
                const formatDate = dateObject.toISOString().split('T')[0];
    
                setFullname(data.data[0].fullName);
                setDistrict(data.data[0].district);
                setReligion(data.data[0].religion);
                setHeight(data.data[0].height);
                setGender(data.data[0].gender);
                setBirthtime(data.data[0].birthtime);
                setCast(data.data[0].cast);
                setCountry(data.data[0].country);
                setAddress(data.data[0].address);
                setVegnonveg(data.data[0].vegnonveg);
                setWeight(data.data[0].weight);
                setMaritalStatus(data.data[0].marital_status)
                setRaasi(data.data[0].raasi);
                setNatchaththiram(data.data[0].natchaththiram);
                setKiragaPawam(data.data[0].kiragaPawam);
                setThosam(data.data[0].thosam);
                setEducation(data.data[0].education);
                setOccupation(data.data[0].occupation);
                setEmployeeStatus(data.data[0].employeeStatus);
                setCitizen(data.data[0].citizen);
                setFullNameMother(data.data[0].fullNameMother);
                setOccupationMother(data.data[0].occupationMother);
                setYearOfBirthMother(data.data[0].yearOfBirthMother);
                setPlaceOfBirthMother(data.data[0].placeOfBirthMother);
                setReligionMother(data.data[0].religionMother);
                setCastMother(data.data[0].castMother);
                setFullNameFather(data.data[0].fullNameFather);
                setOccupationFather(data.data[0].occupationFather);
                setYearOfBirthFather(data.data[0].yearOfBirthFather);
                setPlaceOfBirthFather(data.data[0].placeOfBirthFather);
                setReligionFather(data.data[0].religionFather);
                setCastFather(data.data[0].castFather);
                setSibilingDetails(data.data[0].sibilingDetails);
                setExpectation(data.data[0].expectation);
                setDob(formatDate);
                console.log(data);
            });
    },[id]);

    const [fullName, setFullname] = useState("");
    const [dob, setDob] = useState("");
    const [gender, setGender] = useState("");
    const [district, setDistrict] = useState("");
    const [address, setAddress] = useState("");
    const [country, setCountry] = useState("");
    const [marital_status, setMaritalStatus] = useState("");
    const [vegnonveg, setVegnonveg] = useState("");
    const [religion, setReligion] = useState("");
    const [cast, setCast] = useState("");
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");

    const [birthtime, setBirthtime] = useState("");
    const [raasi, setRaasi] = useState("");
    const [natchaththiram, setNatchaththiram] = useState("");
    const [kiragaPawam, setKiragaPawam] = useState("");
    const [thosam, setThosam] = useState("");
    const [education, setEducation] = useState("");
    const [occupation, setOccupation] = useState("");
    const [employeeStatus, setEmployeeStatus] = useState("");
    const [citizen, setCitizen] = useState("");
    const [fullNameMother, setFullNameMother] = useState("");
    const [occupationMother, setOccupationMother] = useState("");
    const [yearOfBirthMother, setYearOfBirthMother] = useState("");
    const [placeOfBirthMother, setPlaceOfBirthMother] = useState("");
    const [religionMother, setReligionMother] = useState("");
    const [castMother, setCastMother] = useState("");
    const [fullNameFather, setFullNameFather] = useState("");
    const [occupationFather, setOccupationFather] = useState("");
    const [yearOfBirthFather, setYearOfBirthFather] = useState("");
    const [placeOfBirthFather, setPlaceOfBirthFather] = useState("");
    const [religionFather, setReligionFather] = useState("");
    const [castFather, setCastFather] = useState("");

    const [sibilingDetails, setSibilingDetails] = useState("");
    const [expectation, setExpectation] = useState("");

    const handleButton = async(e) => {
        e.preventDefault();

        const editProfile = {
            fullName:fullName,
            dob:dob,
            gender:gender,
            district:district,
            address:address,
            country:country,
            marital_status:marital_status,
            vegnonveg:vegnonveg,
            religion:religion,
            cast:cast,
            height:height,
            weight:weight,
            birthtime:birthtime,
            raasi:raasi,
            natchaththiram:natchaththiram,
            kiragaPawam:kiragaPawam,
            thosam:thosam,
            education:education,
            occupation:occupation,
            employeeStatus:employeeStatus,
            citizen:citizen,
            expectation:expectation,
            fullNameMother:fullNameMother,
            occupationMother:occupationMother,
            yearOfBirthMother:yearOfBirthMother,
            placeOfBirthMother:placeOfBirthMother,
            religionMother:religionMother,
            castMother:castMother,
            fullNameFather:fullNameFather,
            occupationFather:occupationFather,
            yearOfBirthFather:yearOfBirthFather,
            placeOfBirthFather:placeOfBirthFather,
            religionFather:religionFather,
            castFather:castFather,
            sibilingDetails:sibilingDetails,
        };

        console.log(editProfile);

        try {
            await axios.put(`http://localhost:8070/editProfile/${id}`, editProfile);
            message.success('Updated!');
            
        } catch (error) {
            message.error('Failed!');
            console.log(error.message);
        }

    }

    function handleContinue(){
        navigate(`/myprofile/${id}`);
    }

    function handleCancel(){
        navigate(`/dashboard/${id}`);
    }

    function handleReset(){
        Modal.confirm({
            title: 'Warning',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to cancel editing the form?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                axios.get(`http://localhost:8070/getProfile/${id}`)
                .then((data)=>{
                    console.log(data)
                    const dateString = data.data[0].dob;
                    const dateObject = new Date(dateString);
                    const formatDate = dateObject.toISOString().split('T')[0];
        
                    setFullname(data.data[0].fullName);
                    setDistrict(data.data[0].district);
                    setReligion(data.data[0].religion);
                    setHeight(data.data[0].height);
                    setGender(data.data[0].gender);
                    setBirthtime(data.data[0].birthtime);
                    setCast(data.data[0].cast);
                    setCountry(data.data[0].country);
                    setAddress(data.data[0].address);
                    setVegnonveg(data.data[0].vegnonveg);
                    setWeight(data.data[0].weight);
                    setMaritalStatus(data.data[0].marital_status)
                    setRaasi(data.data[0].raasi);
                    setNatchaththiram(data.data[0].natchaththiram);
                    setKiragaPawam(data.data[0].kiragaPawam);
                    setThosam(data.data[0].thosam);
                    setEducation(data.data[0].education);
                    setOccupation(data.data[0].occupation);
                    setEmployeeStatus(data.data[0].employeeStatus);
                    setCitizen(data.data[0].citizen);
                    setFullNameMother(data.data[0].fullNameMother);
                    setOccupationMother(data.data[0].occupationMother);
                    setYearOfBirthMother(data.data[0].yearOfBirthMother);
                    setPlaceOfBirthMother(data.data[0].placeOfBirthMother);
                    setReligionMother(data.data[0].religionMother);
                    setCastMother(data.data[0].castMother);
                    setFullNameFather(data.data[0].fullNameFather);
                    setOccupationFather(data.data[0].occupationFather);
                    setYearOfBirthFather(data.data[0].yearOfBirthFather);
                    setPlaceOfBirthFather(data.data[0].placeOfBirthFather);
                    setReligionFather(data.data[0].religionFather);
                    setCastFather(data.data[0].castFather);
                    setSibilingDetails(data.data[0].sibilingDetails);
                    setExpectation(data.data[0].expectation);
                    setDob(formatDate);
                    
                });
            }
        })
    }

    const [L1, setL1] = useState("");
    const [L2, setL2] = useState("");
    const [L3, setL3] = useState("");
    const [L4, setL4] = useState("");
    const [L5, setL5] = useState("");
    const [L6, setL6] = useState("");
    const [L7, setL7] = useState("");
    const [L8, setL8] = useState("");
    const [L9, setL9] = useState("");
    const [L10, setL10] = useState("");
    const [L11, setL11] = useState("");
    const [L12, setL12] = useState("");
    const [L13, setL13] = useState("");
    const [R1, setR1] = useState("");
    const [R2, setR2] = useState("");
    const [R3, setR3] = useState("");
    const [R4, setR4] = useState("");
    const [R5, setR5] = useState("");
    const [R6, setR6] = useState("");
    const [R7, setR7] = useState("");
    const [R8, setR8] = useState("");
    const [R9, setR9] = useState("");
    const [R10, setR10] = useState("");
    const [R11, setR11] = useState("");
    const [R12, setR12] = useState("");
    const [R13, setR13] = useState("");
   
    useEffect(() => {
        axios.get(`http://localhost:8070/getHoroscope/${id}`)
        .then((data)=>{
            console.log(data);
            setL1(data.data[0].L1);
            setL2(data.data[0].L2);
            setL3(data.data[0].L3);
            setL4(data.data[0].L4);
            setL5(data.data[0].L5);
            setL6(data.data[0].L6);
            setL7(data.data[0].L7);
            setL8(data.data[0].L8);
            setL9(data.data[0].L9);
            setL10(data.data[0].L10);
            setL11(data.data[0].L11);
            setL12(data.data[0].L12);
            setL13(data.data[0].L13);
            setR1(data.data[0].R1);
            setR2(data.data[0].R2);
            setR3(data.data[0].R3);
            setR4(data.data[0].R4);
            setR5(data.data[0].R5);
            setR6(data.data[0].R6)
            setR7(data.data[0].R7);
            setR8(data.data[0].R8);
            setR9(data.data[0].R9);
            setR10(data.data[0].R10);
            setR11(data.data[0].R11);
            setR12(data.data[0].R12);
            setR13(data.data[0].R13);
        });
    }, [id]);

    const handleUpdate = async (e) =>{
        const editHoroscope = {
            L1:L1,
            L2:L2,
            L3:L3,
            L4:L4,
            L5:L5,
            L6:L6,
            L7:L7,
            L8:L8,
            L9:L9,
            L10:L10,
            L11:L11,
            L12:L12,
            L13:L13,
            R1:R1,
            R2:R2,
            R3:R3,
            R4:R4,
            R5:R5,
            R6:R6,
            R7:R7,
            R8:R8,
            R9:R9,
            R10:R10,
            R11:R11,
            R12:R12,
            R13:R13
        }
        try {
            await axios.put(`http://localhost:8070/editHoroscope/${id}`, editHoroscope);
            message.success('Updated!');
            console.log(editHoroscope);
        } catch (error) {
            message.error('Failed!');
            console.log(error.message);
        }
    }

    useEffect(() => {
        // Calculate the current date
        const currentDate = new Date();

        // Set the max attribute of the date input to the current date
        const dateInput = document.getElementById('dob');
        if (dateInput) {
            dateInput.max = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

        }
    }, []);

    const [file, setFile] = useState();

    const profileUpload = async(e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file)

        axios.put(`http://localhost:8070/updateImage/${id}`, formData)
            .then((response) => {
                console.log(response);
                message.success('Uploaded!');
            })
            .catch((err) => {
                console.error(err);
                message.error('Failed!');
            });
    }

    const [files, setFiles] = useState([]); 

    const handleFile = (e) =>{
        setFiles(e.target.files)
    }

    const handleUpload = async (e) => {
        e.preventDefault();
        if (!files || files.length === 0) {
            message.error('No files selected');
            return;
        }

        if (files.length > 5) {
            message.error('You can upload up to 5 images only');
            return;
        }
        const formData = new FormData();
    
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
        }
    
        axios.put(`http://localhost:8070/upload/${id}`, formData)
            .then((response) => {
                console.log(response);
                message.success('Uploaded!');
            })
            .catch((err) => {
                console.error(err);
                message.error('Failed!');
            });
    }

    useEffect(() => {
        axios.get(`http://localhost:8070/getImage/${id}`)
        .then((data)=>{
            console.log('Images', data);
            setFile(data.data[0].profile);
        })
        .catch((err) => console.log(err))
    }, [id]);

    

    return(
        <div style={commonStyle}>
            <NavBar/>
            <h5 className='completeprofile'> Edit Profile </h5>
            <form onSubmit={(Event) => Event.preventDefault()} className='profileform'>
            <div className='profileform-left'>
                <h5> Personal Details of Bride/Groom </h5>
                
                <div className="mb-3">
                        <label className="form-label">Full Name</label>
                        <input
                            type="text"
                            className="form-control"
                            value={fullName}
                            onChange={(event) => {
                            setFullname(event.target.value);
                            }}
                        />
                </div>

                <div className="mb-3">
                        <label className="form-label">Date Of Birth</label>
                        <input
                            type="date"
                            className="form-control"
                            value={dob}
                            onChange={(event) => {
                            setDob(event.target.value);
                            }}
                        />
                </div>

                <div className="mb-3">
                        <label className="form-label">Gender</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='F/M'
                            value={gender}
                            onChange={(event) => {
                            setGender(event.target.value);
                            }}
                        />
                </div>

                <div className="mb-3">
                        <label className="form-label">District</label>
                        <input
                            type="text"
                            className="form-control"
                            value={district}
                            onChange={(event) => {
                            setDistrict(event.target.value);
                            }}
                        />
                </div>

                <div className="mb-3">
                        <label className="form-label">Address</label>
                        <textarea
                            className="form-control"
                            value={address}
                            onChange={(event) => {
                            setAddress(event.target.value);
                            }}
                        />
                </div>

                <div className="mb-3">
                        <label className="form-label">Residence Country</label>
                        <input
                            type="text"
                            className="form-control"
                            value={country}
                            onChange={(event) => {
                            setCountry(event.target.value);
                            }}
                        />
                </div> 
                <br/>

            </div>

            <div className='profileform-right'>  
                        <div className="mb-3">
                                <label className="form-label">Single/Divorced</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={marital_status}
                                    onChange={(event) => {
                                    setMaritalStatus(event.target.value);
                                    }}
                                />
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Vegitarian/Non-Vegitarian</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder='Veg/NonVeg'
                                    value={vegnonveg}
                                    onChange={(event) => {
                                    setVegnonveg(event.target.value);
                                    }}
                                />
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Religion</label>
                                <div className='select-body' style={commonStyle}>
                                <select style={{width:'480px'}} className="form-control" defaultValue=''
                                value={religion}
                                onChange={(event) => {
                                setReligion(event.target.value);
                                }} required>
                                    <option value='' disabled>  </option>
                                    <option value='Buddhist' > Buddhist </option>
                                    <option value='Hindu' > Hindu </option>
                                    <option value='Islam' > Islam </option>
                                    <option value='Catholic' > Catholic </option>
                                </select>
                                </div>
                        </div>
                        
                        <div className="mb-3">
                                <label className="form-label">Caste</label>
                                <select style={{width:'480px'}} className="form-control" defaultValue=''
                                value={cast}
                                onChange={(event) => {
                                setCast(event.target.value);
                                }} required>
                                <option value='' disabled>  </option>
                                <option value='Bathgama'> Bathgama </option>
                                <option value='Berava'> Berava </option>
                                <option value='Bodhi Vamsa/Bodhahara'> Bodhi Vamsa/Bodhahara </option>
                                <option value='Demala-Gattara'> Demala-Gattara </option>
                                <option value='Dewa people'> Dewa people </option>
                                <option value='Durava'> Durava </option>
                                <option value='Govigama'> Govigama </option>
                                <option value='Hena'> Hena </option>
                                <option value='Karava'> Karava </option>
                                <option value='Karava heraldry'> Karava heraldry </option>
                                <option value='Kinnaraya'> Kinnaraya </option>
                                <option value='Konda Karavas'> Konda Karavas </option>
                                <option value='Marakkalage'> Marakkalage </option>
                                <option value='Pamunu'> Pamunu </option>
                                <option value='Patti'> Patti </option>
                                <option value='Porowakara'> Porowakara </option>
                                <option value='Radala'> Radala </option>
                                <option value='Rodiya'> Rodiya </option>
                                <option value='Salagama'> Salagama </option>
                                <option value='Kurukulam'> Kurukulam </option>
                                </select>
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Height(cm)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={height}
                                    onChange={(event) => {
                                    setHeight(event.target.value);
                                    }}
                                />
                        </div> 

                        <div className="mb-3">
                                <label className="form-label">Weight(kg)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={weight}
                                    onChange={(event) => {
                                    setWeight(event.target.value);
                                    }}
                                />
                        </div> 
                        <br/> <br/> 
                    </div>
                <br/> <br/> 
                <div className='profileform-middle'>
                    <div className='profileform-left'>
                        <h5> Details of Jathagam </h5>

                        <div className="mb-3">
                                <label className="form-label">Birth Time</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder='12:13 PM'
                                    value={birthtime}
                                    onChange={(event) => {
                                    setBirthtime(event.target.value);
                                    }}
                                />
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Raasi</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={raasi}
                                    onChange={(event) => {
                                    setRaasi(event.target.value);
                                    }}
                                />
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Natchaththiram</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={natchaththiram}
                                    onChange={(event) => {
                                    setNatchaththiram(event.target.value);
                                    }}
                                />
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Kiraga Pawam</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={kiragaPawam}
                                    onChange={(event) => {
                                    setKiragaPawam(event.target.value);
                                    }}
                                />
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Thosam (optional)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={thosam}
                                    onChange={(event) => {
                                    setThosam(event.target.value);
                                    }}
                                />
                        </div>
                        <br/>
                    </div>
                
                    <div className='profileform-right'>
                        <h5> Qualification and Occupation </h5>
                        <div className="mb-3">
                                <label className="form-label">Education Qualification(s)</label>
                                <textarea
                                    className="form-control"
                                    value={education}
                                    onChange={(event) => {
                                    setEducation(event.target.value);
                                    }}
                                />
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Occupation</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={occupation}
                                    onChange={(event) => {
                                    setOccupation(event.target.value);
                                    }}
                                />
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Government/Private/Self-Employee/Not-Employee</label>
                                <select style={{width:'480px'}} className="form-control" defaultValue=''
                                value={employeeStatus}
                                onChange={(event) => {
                                setEmployeeStatus(event.target.value);
                                }}>
                                    <option value='' disabled>  </option>
                                    <option value='Government' > Government </option>
                                    <option value='Private' > Private </option>
                                    <option value='Self-Employee' > Self-Employee </option>
                                    <option value='Not-Employee' > Not-Employee </option>
                                </select>
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Citizenship/PR/Work Permit</label>
                                <select style={{width:'480px'}} className="form-control" defaultValue=''
                                value={citizen}
                                onChange={(event) => {
                                setCitizen(event.target.value);
                                }}>
                                    <option value='' disabled>  </option>
                                    <option value='Citizenship' > Citizenship </option>
                                    <option value='PR' > PR </option>
                                    <option value='Work Permit' > Work Permit </option>
                                </select>
                        </div>
                        <br/>
                    </div>
                </div>
                <div className='profileform-parent'>
                    <div className='profileform-left'>
                        <h5> Mother's Details </h5>
                        <div className="mb-3">
                                <label className="form-label">Full Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={fullNameMother}
                                    onChange={(event) => {
                                    setFullNameMother(event.target.value);
                                    }}
                                />
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Occupation</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={occupationMother}
                                    onChange={(event) => {
                                    setOccupationMother(event.target.value);
                                    }}
                                />
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Year of Birth</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={yearOfBirthMother}
                                    onChange={(event) => {
                                    setYearOfBirthMother(event.target.value);
                                    }}
                                />
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Place of Birth</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={placeOfBirthMother}
                                    onChange={(event) => {
                                    setPlaceOfBirthMother(event.target.value);
                                    }}
                                />
                        </div>
                        <div className="mb-3">
                                <label className="form-label">Religion</label>
                                <select style={{width:'480px'}} className="form-control" defaultValue=''
                                value={religionMother}
                                onChange={(event) => {
                                setReligionMother(event.target.value);
                                }}>
                                    <option value='' disabled>  </option>
                                    <option value='Buddhist' > Buddhist </option>
                                    <option value='Hindu' > Hindu </option>
                                    <option value='Islam' > Islam </option>
                                    <option value='Catholic' > Catholic </option>
                                </select>
                        </div>
                        <div className="mb-3">
                                <label className="form-label">Cast</label>
                                <select style={{width:'480px'}} className="form-control" defaultValue=''
                                value={castMother}
                                onChange={(event) => {
                                setCastMother(event.target.value);
                                }} required>
                                <option value='' disabled>  </option>
                                <option value='Bathgama'> Bathgama </option>
                                <option value='Berava'> Berava </option>
                                <option value='Bodhi Vamsa/Bodhahara'> Bodhi Vamsa/Bodhahara </option>
                                <option value='Demala-Gattara'> Demala-Gattara </option>
                                <option value='Dewa people'> Dewa people </option>
                                <option value='Durava'> Durava </option>
                                <option value='Govigama'> Govigama </option>
                                <option value='Hena'> Hena </option>
                                <option value='Karava'> Karava </option>
                                <option value='Karava heraldry'> Karava heraldry </option>
                                <option value='Kinnaraya'> Kinnaraya </option>
                                <option value='Konda Karavas'> Konda Karavas </option>
                                <option value='Marakkalage'> Marakkalage </option>
                                <option value='Pamunu'> Pamunu </option>
                                <option value='Patti'> Patti </option>
                                <option value='Porowakara'> Porowakara </option>
                                <option value='Radala'> Radala </option>
                                <option value='Rodiya'> Rodiya </option>
                                <option value='Salagama'> Salagama </option>
                                <option value='Kurukulam'> Kurukulam </option>
                                </select>
                        </div>
                    </div>
                    <div className='profileform-right'>
                    <h5> Father's Details </h5>
                        <div className="mb-3">
                                <label className="form-label">Full Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={fullNameFather}
                                    onChange={(event) => {
                                    setFullNameFather(event.target.value);
                                    }}
                                />
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Occupation</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={occupationFather}
                                    onChange={(event) => {
                                    setOccupationFather(event.target.value);
                                    }}
                                />
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Year of Birth</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={yearOfBirthFather}
                                    onChange={(event) => {
                                    setYearOfBirthFather(event.target.value);
                                    }}
                                />
                        </div>

                        <div className="mb-3">
                                <label className="form-label">Place of Birth</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={placeOfBirthFather}
                                    onChange={(event) => {
                                    setPlaceOfBirthFather(event.target.value);
                                    }}
                                />
                        </div>
                        <div className="mb-3">
                                <label className="form-label">Religion</label>
                                <select style={{width:'480px'}} className="form-control" defaultValue=''
                                value={religionFather}
                                onChange={(event) => {
                                setReligionFather(event.target.value);
                                }}>
                                    <option value='' disabled>  </option>
                                    <option value='Buddhist' > Buddhist </option>
                                    <option value='Hindu' > Hindu </option>
                                    <option value='Islam' > Islam </option>
                                    <option value='Catholic' > Catholic </option>
                                </select>
                        </div>
                        <div className="mb-3">
                                <label className="form-label">Cast</label>
                                <select style={{width:'480px'}} className="form-control" defaultValue=''
                                value={castFather}
                                onChange={(event) => {
                                setCastFather(event.target.value);
                                }} required>
                                <option value='' disabled>  </option>
                                <option value='Bathgama'> Bathgama </option>
                                <option value='Berava'> Berava </option>
                                <option value='Bodhi Vamsa/Bodhahara'> Bodhi Vamsa/Bodhahara </option>
                                <option value='Demala-Gattara'> Demala-Gattara </option>
                                <option value='Dewa people'> Dewa people </option>
                                <option value='Durava'> Durava </option>
                                <option value='Govigama'> Govigama </option>
                                <option value='Hena'> Hena </option>
                                <option value='Karava'> Karava </option>
                                <option value='Karava heraldry'> Karava heraldry </option>
                                <option value='Kinnaraya'> Kinnaraya </option>
                                <option value='Konda Karavas'> Konda Karavas </option>
                                <option value='Marakkalage'> Marakkalage </option>
                                <option value='Pamunu'> Pamunu </option>
                                <option value='Patti'> Patti </option>
                                <option value='Porowakara'> Porowakara </option>
                                <option value='Radala'> Radala </option>
                                <option value='Rodiya'> Rodiya </option>
                                <option value='Salagama'> Salagama </option>
                                <option value='Kurukulam'> Kurukulam </option>
                                </select>
                        </div>
                    </div>
                </div> 
                <br/> 
                <div className='profileform-sibiling' id='editsibilings'>
                    <div className='profileform-left'>
                        <h5> Sibiling's Details </h5>
                        <div className="mb-3">
                            <textarea
                                className="form-control" id='det'
                                value={sibilingDetails}
                                onChange={(event) => {
                                setSibilingDetails(event.target.value);
                                }}
                            />
                        </div>
                        <br/>
                    </div>
                    
                    <div className='profileform-right'>
                        <h5> Expectations </h5>
                        <div className="mb-3">
                            <textarea
                                className="form-control" id='det'
                                value={expectation}
                                onChange={(event) => {
                                setExpectation(event.target.value);
                                }}
                            />
                        </div>
                        <br/>
                    </div>
                </div>   

                <div className='profileform-btn' id = 'editbutton'>
                    <button className='backbtn1' style={commonStyle} onClick={handleReset}> Reset </button> 
                    <button type='submit' className='continuebtn2' style={commonStyle} onClick={handleButton}> Save </button>                                       
                </div>
                </form>
                <div className='profileform-images' id = 'editimage'>
                    <h5> Attachments </h5>
                    <form>
                    <div className="mb-3">
                        <label className="form-label">Photos of Bride/Groom (minimum 1 and maximum 5)</label>
                        <br/> <br/> 
                        <div className='image-left'>
                            <div className='imageupload'>
                            <input
                                type="file"
                                name="file"
                                className="form-control"
                                onChange={(e) => setFile(e.target.files[0])}
                                accept=".jpg, .jpeg, .png"
                            />
                            <span style={{ float:'right', marginRight:'-120px', fontStyle: 'italic', color:'red', marginTop: '-30px', display: 'inline-block'}} className='spanEditHoroscope'> * Profile Picture </span>
                            <button type='submit' className='upload' onClick={profileUpload}> Upload </button>
                            
                            </div>
                            <br/>  
                            <div>              
                            <input
                                type="file"
                                name="files"
                                className="form-control"
                                onChange={handleFile}
                                accept=".jpg, .jpeg, .png"
                                multiple
                            />   
                            <button type='submit' className='upload' onClick={handleUpload}> Upload </button> 
                            </div>                      
                        </div> 
                        <br/> <br/> <br/> <br/> <br/>
                    </div>
                    <p style={{ float:'left', fontStyle: 'italic', color:'orange', marginLeft:'30px'}}> Note: Uploading new images will remove the previous images.</p> <br/> <br/>
                    </form>      
                </div> 
                    
                    <div className='profileform-horoscope' id='editHoroscope'>
                        <h5> Horoscope </h5>
                        <form onSubmit={(Event) => Event.preventDefault()} className='profileform'>
                        <button type='submit' className='save' onClick={handleUpdate}> Update </button>
                        <div className='horoscopetemplate1'>
                            <div className='row1'>
                                <div className='square1'>
                                    <input type='text' className='L1' placeholder='L1' 
                                    value={L1}
                                    onChange={(event) => {
                                    setL1(event.target.value);
                                    }}
                                    />
                                    <input type='text' className='L2' placeholder='L2'
                                    value={L2}
                                    onChange={(event) => {
                                    setL2(event.target.value);
                                    }}
                                    />
                                </div>
                                <div className='square2'>
                                    <input type='text' className='L3' placeholder='L3'
                                    value={L3}
                                    onChange={(event) => {
                                    setL3(event.target.value);
                                    }}/>
                                </div>
                                <div className='square3'> 
                                    <input type='text' className='L4' placeholder='L4'
                                    value={L4}
                                    onChange={(event) => {
                                    setL4(event.target.value);
                                    }}/>
                                    <input type='text' className='L5' placeholder='L5'
                                    value={L5}
                                    onChange={(event) => {
                                    setL5(event.target.value);
                                    }}/>
                                </div>
                                <div className='cross1'>

                                </div>
                                <div className='cross2'>
                                    
                                </div>
                            </div>
                            
                            <div className='row2'>
                                <div className='square1'>
                                    <input type='text' className='L3' placeholder='L6'
                                    value={L6}
                                    onChange={(event) => {
                                    setL6(event.target.value);
                                    }}/>
                                </div>
                                <div className='square2'>
                                    <input type='text' className='L3' placeholder='L7'
                                    value={L7}
                                    onChange={(event) => {
                                    setL7(event.target.value);
                                    }}/>
                                </div>
                                <div className='square3'> 
                                    <input type='text' className='L3' placeholder='L8'
                                    value={L8}
                                    onChange={(event) => {
                                    setL8(event.target.value);
                                    }}/>
                                </div>
                            </div>
                            <div className='row3'>
                                <div className='square1'>
                                    <input type='text' className='L4' placeholder='L9'
                                    value={L9}
                                    onChange={(event) => {
                                    setL9(event.target.value);
                                    }}/>
                                    <input type='text' className='L5' placeholder='L10'
                                    value={L10}
                                    onChange={(event) => {
                                    setL10(event.target.value);
                                    }}/>
                                </div>
                                <div className='square2'>
                                    <input type='text' className='L3' placeholder='L11'
                                    value={L11}
                                    onChange={(event) => {
                                    setL11(event.target.value);
                                    }}/>
                                </div>
                                <div className='square3'> 
                                    <input type='text' className='L1' placeholder='L12'
                                    value={L12}
                                    onChange={(event) => {
                                    setL12(event.target.value);
                                    }}/>
                                    <input type='text' className='L2' placeholder='L13'
                                    value={L13}
                                    onChange={(event) => {
                                    setL13(event.target.value);
                                    }}/>
                                </div>
                                <div className='cross3'>

                                </div>
                                <div className='cross4'>

                                </div>
                            </div>
                            
                        </div>
                        
                        <div className='horoscopetemplate2'>
                        <div className='row1'>
                                <div className='square1'>
                                    <input type='text' className='L1' placeholder='R1'
                                    value={R1}
                                    onChange={(event) => {
                                    setR1(event.target.value);
                                    }}/>
                                    <input type='text' className='L2' placeholder='R2'
                                    value={R2}
                                    onChange={(event) => {
                                    setR2(event.target.value);
                                    }}/>
                                </div>
                                <div className='square2'>
                                    <input type='text' className='L3' placeholder='R3'
                                    value={R3}
                                    onChange={(event) => {
                                    setR3(event.target.value);
                                    }}/>
                                </div>
                                <div className='square3'> 
                                    <input type='text' className='L4' placeholder='R4'
                                    value={R4}
                                    onChange={(event) => {
                                    setR4(event.target.value);
                                    }}/>
                                    <input type='text' className='L5' placeholder='R5'
                                    value={R5}
                                    onChange={(event) => {
                                    setR5(event.target.value);
                                    }}/>
                                </div>
                                <div className='cross1'>

                                </div>
                                <div className='cross2'>
                                    
                                </div>
                            </div>
                            
                            <div className='row2'>
                                <div className='square1'>
                                    <input type='text' className='L3' placeholder='R6'
                                    value={R6}
                                    onChange={(event) => {
                                    setR6(event.target.value);
                                    }}/>
                                </div>
                                <div className='square2'>
                                    <input type='text' className='L3' placeholder='R7'
                                    value={R7}
                                    onChange={(event) => {
                                    setR7(event.target.value);
                                    }}/>
                                </div>
                                <div className='square3'> 
                                    <input type='text' className='L3' placeholder='R8'
                                    value={R8}
                                    onChange={(event) => {
                                    setR8(event.target.value);
                                    }}/>
                                </div>
                            </div>
                            <div className='row3'>
                                <div className='square1'>
                                    <input type='text' className='L4' placeholder='R9'
                                    value={R9}
                                    onChange={(event) => {
                                    setR9(event.target.value);
                                    }}/>
                                    <input type='text' className='L5' placeholder='R10'
                                    value={R10}
                                    onChange={(event) => {
                                    setR10(event.target.value);
                                    }}/>
                                </div>
                                <div className='square2'>
                                    <input type='text' className='L3' placeholder='R11'
                                    value={R11}
                                    onChange={(event) => {
                                    setR11(event.target.value);
                                    }}/>
                                </div>
                                <div className='square3'> 
                                    <input type='text' className='L1' placeholder='R12'
                                    value={R12}
                                    onChange={(event) => {
                                    setR12(event.target.value);
                                    }}/>
                                    <input type='text' className='L2' placeholder='R13'
                                    value={R13}
                                    onChange={(event) => {
                                    setR13(event.target.value);
                                    }}/>
                                </div>
                                <div className='cross3'>

                                </div>
                                <div className='cross4'>

                                </div>
                            </div>   
                            
                        </div>

                        <div className='mobileHoroscopeEdit'>
                        <div className='horoscopetemplate3'>
                        <div className='rowdet1'>
                            <div className='squaredet1'>
                                <input type='text' className='value1' placeholder='L1' 
                                value={L1}
                                onChange={(event) => {
                                setL1(event.target.value);
                                }} required/>
                                <input type='text' className='value2' placeholder='L2'
                                value={L2}
                                onChange={(event) => {
                                setL2(event.target.value);
                                }} required/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' placeholder='L3'
                                value={L3}
                                onChange={(event) => {
                                setL3(event.target.value);
                                }} required/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value4' placeholder='L4'
                                value={L4}
                                onChange={(event) => {
                                setL4(event.target.value);
                                }} required/>
                                <input type='text' className='value5' placeholder='L5'
                                value={L5}
                                onChange={(event) => {
                                setL5(event.target.value);
                                }} required/>
                            </div>
                            <div className='cross5'>

                            </div>
                            <div className='cross6'>
                                
                            </div>
                        </div>
                        
                        <div className='rowdet2'>
                            <div className='squaredet1'>
                                <input type='text' className='value3' placeholder='L6'
                                value={L6}
                                onChange={(event) => {
                                setL6(event.target.value);
                                }} required/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' placeholder='L7'
                                value={L7}
                                onChange={(event) => {
                                setL7(event.target.value);
                                }}/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value3' placeholder='L8'
                                value={L8}
                                onChange={(event) => {
                                setL8(event.target.value);
                                }}/>
                            </div>
                        </div>
                        <div className='rowdet3'>
                            <div className='squaredet1'>
                                <input type='text' className='value4' placeholder='L9'
                                value={L9}
                                onChange={(event) => {
                                setL9(event.target.value);
                                }} required/>
                                <input type='text' className='value5' placeholder='L10'
                                value={L10}
                                onChange={(event) => {
                                setL10(event.target.value);
                                }} required/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' placeholder='L11'
                                value={L11}
                                onChange={(event) => {
                                setL11(event.target.value);
                                }} required/>
                            </div>
                            <div className='squaredet3'>
                                <input type='text' className='value1' placeholder='L12'
                                value={L12}
                                onChange={(event) => {
                                setL12(event.target.value);
                                }} required/>
                                <input type='text' className='value2' placeholder='L13'
                                value={L13}
                                onChange={(event) => {
                                setL13(event.target.value);
                                }} required/>
                            </div>
                            <div className='cross7'>

                            </div>
                            <div className='cross8'>
                                
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className='horoscopetemplate4'>
                        <div className='rowdet1'>
                            <div className='squaredet1'>
                                <input type='text' className='value1' placeholder='R1'
                                value={R1}
                                onChange={(event) => {
                                setR1(event.target.value);
                                }} required/>
                                <input type='text' className='value2' placeholder='R2'
                                value={R2}
                                onChange={(event) => {
                                setR2(event.target.value);
                                }} required/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' placeholder='R3'
                                value={R3}
                                onChange={(event) => {
                                setR3(event.target.value);
                                }} required/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value4' placeholder='R4'
                                value={R4}
                                onChange={(event) => {
                                setR4(event.target.value);
                                }} required/>
                                <input type='text' className='value5' placeholder='R5'
                                value={R5}
                                onChange={(event) => {
                                setR5(event.target.value);
                                }} required/>
                            </div>
                            <div className='cross5'>

                            </div>
                            <div className='cross6'>
                                
                            </div>
                        </div>
                        
                        <div className='rowdet2'>
                            <div className='squaredet1'>
                                <input type='text' className='value3' placeholder='R6'
                                value={R6}
                                onChange={(event) => {
                                setR6(event.target.value);
                                }} required/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' placeholder='R7'
                                value={R7}
                                onChange={(event) => {
                                setR7(event.target.value);
                                }} required/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value3' placeholder='R8'
                                value={R8}
                                onChange={(event) => {
                                setR8(event.target.value);
                                }} required/>
                            </div>
                        </div>
                        <div className='rowdet3'>
                            <div className='squaredet1'>
                                <input type='text' className='value4' placeholder='R9'
                                value={R9}
                                onChange={(event) => {
                                setR9(event.target.value);
                                }} required/>
                                <input type='text' className='value5' placeholder='R10'
                                value={R10}
                                onChange={(event) => {
                                setR10(event.target.value);
                                }} required/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' placeholder='R11'
                                value={R11}
                                onChange={(event) => {
                                setR11(event.target.value);
                                }} required/>
                            </div>
                            <div className='squaredet3'>
                                <input type='text' className='value1' placeholder='R12'
                                value={R12}
                                onChange={(event) => {
                                setR12(event.target.value);
                                }} required/>
                                <input type='text' className='value2' placeholder='R13'
                                value={R13}
                                onChange={(event) => {
                                setR13(event.target.value);
                                }} required/>
                            </div>
                            <div className='cross7'>

                            </div>
                            <div className='cross8'>
                                
                            </div>
                            
                        </div>
                        
                    </div>                   
                    </div>
                    </form>
                </div>          
                <br/> <br/> 
                <button type='submit' className='resetbtn' style={commonStyle} onClick={handleCancel}> Back </button>                                       
                <button type='submit' className='continuebtn3' style={commonStyle} onClick={handleContinue}> Continue </button>                                       
                <br/> <br/> <br/> <br/> 
            <Footer/>
        </div>
    )
}

export default CompleteProfile;