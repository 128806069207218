import React, { useEffect, useState } from 'react';
import '../../styles/customer/CompleteProfile.css';
import NavBar from './NavBar';
import Footer from './Footer';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import axios from 'axios';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import user from '../../images/user.png';
import { Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

function Post(){
    const commonStyle = {
        fontFamily: 'Alike, sans-serif',
    };

    const navigate = useNavigate();
    const {id} = useParams();

    function handleEdit() {
        navigate(`/main/dashboard/editpost/${id}`);
    }

    const handleDelete = (e) => {
        e.preventDefault(); 
    
        Modal.confirm({
            title: 'Warning',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to delete the post?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                axios.delete(`http://localhost:8070/deletePost/${id}`)
                .then((response) => {
                    console.log(response);
                    message.success('Post Deleted!');
                    navigate(`/main/dashboard/createpost/${id}`);
                })
                .catch((error) => {
                    console.error('Error deleting post:', error);
                });
            },
            onCancel: () => {
                message.error('Post Deletion Cancelled!');
            },
        });
    };
    
    const [userData, setUserData] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getProfile/${id}`)
        .then((data)=>{
            console.log(data)

            const dateString = data.data[0].dob;
            const dateObject = new Date(dateString);
            const formatDate = dateObject.toLocaleDateString();

            
            const birthYear = new Date(dateString).getFullYear();
            
            console.log(birthYear);

            const currentYear = new Date().getFullYear();

            console.log(currentYear);

            const age = currentYear - birthYear;

            setUserData({
                userId: data.data[0].cid,
                district: data.data[0].district,
                religion: data.data[0].religion,
                cast: data.data[0].cast,
                country:data.data[0].country,
                address:data.data[0].address,
                status:data.data[0].marital_status,
                education:data.data[0].education,
                occupation:data.data[0].occupation,
                formatDate,
                age
            });

            //console.log(formatData);

        });
    }, [id]);

    const [file, setFile] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getPostProfile/${id}`)
        .then((data)=>{
            console.log(data);
            setFile({
                profile:data.data[0].profile, 
                image:data.data[0].image,
                status:data.data[0].post_status
            });

        })
        .catch((err) => console.log(err))
    }, [id]);

    const [horoscope, setHoroscope] = useState([]);

    useEffect(() => {
        axios.get(`http://localhost:8070/getHoroscope/${id}`)
        .then((data)=>{
            console.log(data)
            setHoroscope({
                L1:data.data[0].L1,
                L2:data.data[0].L2,
                L3:data.data[0].L3,
                L4:data.data[0].L4,
                L5:data.data[0].L5,
                L6:data.data[0].L6,
                L7:data.data[0].L7,
                L8:data.data[0].L8,
                L9:data.data[0].L9,
                L10:data.data[0].L10,
                L11:data.data[0].L11,
                L12:data.data[0].L12,
                L13:data.data[0].L13,
                R1:data.data[0].R1,
                R2:data.data[0].R2,
                R3:data.data[0].R3,
                R4:data.data[0].R4,
                R5:data.data[0].R5,
                R6:data.data[0].R6,
                R7:data.data[0].R7,
                R8:data.data[0].R8,
                R9:data.data[0].R9,
                R10:data.data[0].R10,
                R11:data.data[0].R11,
                R12:data.data[0].R12,
                R13:data.data[0].R13
            });
        });
    }, [id]);
    return(
        <div style={commonStyle}>
            <NavBar/> <br/>
            <div className = 'myprofile' style={{paddingLeft:'50px', paddingRight:'50px'}}> 
                {userData && (
                <>
                <h5 className='myprofilevalues'> User ID : {userData.userId} </h5>
                <center>
                    {file.image===1?(
                        <img src={`http://localhost:8070/public/images/${file.profile}`} alt='' className='groom'
                        style={{borderRadius:'50%', height:'150px', width:'150px', objectFit: 'fill'}} />
                    ):(
                        <img src={user} alt='' className='groom' />
                    )} <br/>
                    {file.status==='deactive' ? (
                        <h6 className='myprofilevalues' > Post Status : <span style={{color:'red'}}> Deactive </span> </h6>
                    ) : (
                        <h6 className='myprofilevalues' > Post Status : <span style={{color:'green'}}> Active </span> </h6>
                    )
                    }
                    
                </center>    
                <Grid container>
                    <Grid item xs={6}>
                    <div style={{marginLeft:'50px'}} className='createPost' id="viewpost">
                        <label className='profile-control'> Date of Birth </label> <br/>
                        <label className='profile-control'> Address </label> <br/>
                        <label className='profile-control'> Religion </label> <br/>
                        <label className='profile-control'> Caste </label> <br/>
                        <label className='profile-control'> Age </label> <br/>
                        <label className='profile-control'> Educational Qualification(s)</label> <br/>
                        <label className='profile-control'> Occupation</label> <br/>
                        <label className='profile-control'> District </label> <br/>
                        <label className='profile-control'> Resident Country </label> <br/>
                        <label className='profile-control'> Single/Divorced</label> <br/>
                    </div>
                    </Grid>
                    <Grid item xs={1}>
                    <div className='vlcpcustomer'> </div>    
                    </Grid>
                    <Grid item xs={5}>
                    <div style={{marginRight:'20px', marginTop:'10px'}} className='createPost' id="viewpostinput">
                        <input type='text' className='form-control' value={userData.formatDate} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.address} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.religion} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.cast} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.age} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.education} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.occupation} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.district} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.country} readOnly/> <br/>
                        <input type='text' className='form-control' value={userData.status} readOnly/> <br/>
                    </div>                  
                    </Grid>
                </Grid>
                <br/> <br/>
                <div style={{marginLeft:'10px', boxShadow: '2px 5px 5px 5px #dddcdc', borderRadius:'20px'}} id="horoscopecreatepost"> <br/>
                <h5 className='personaldet' style={{textAlign:'center'}}> Horoscope</h5> 
                <div>
                <form>
                    {horoscope && (
                    <>
                    <div className='horoscopetemplate3' style={{marginLeft:'50px'}}>
                        <div className='rowdet1'>
                            <div className='squaredet1'>
                                <input type='text' className='value1' value={horoscope.L1} readOnly/>
                                <input type='text' className='value2' value={horoscope.L2} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.L3} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value4' value={horoscope.L4} readOnly/>
                                <input type='text' className='value5' value={horoscope.L5} readOnly/>
                            </div>
                            <div className='cross5'>

                            </div>
                            <div className='cross6'>
                                
                            </div>
                        </div>
                        
                        <div className='rowdet2'>
                            <div className='squaredet1'>
                                <input type='text' className='value3' value={horoscope.L6} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.L7} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value3' value={horoscope.L8} readOnly/>
                            </div>
                        </div>
                        <div className='rowdet3'>
                            <div className='squaredet1'>
                                <input type='text' className='value4' value={horoscope.L9} readOnly/>
                                <input type='text' className='value5' value={horoscope.L10} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.L11} readOnly/>
                            </div>
                            <div className='squaredet3'>
                                <input type='text' className='value1' value={horoscope.L12} readOnly/>
                                <input type='text' className='value2' value={horoscope.L13} readOnly/> 
                            </div>
                            <div className='cross7'>

                            </div>
                            <div className='cross8'>
                                
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className='horoscopetemplatepp' style={{marginRight:'50px'}}>
                        <div className='rowdet1'>
                            <div className='squaredet1'>
                                <input type='text' className='value1' value={horoscope.R1} readOnly/>
                                <input type='text' className='value2' value={horoscope.R2} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.R3} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value4' value={horoscope.R4} readOnly/>
                                <input type='text' className='value5' value={horoscope.R5} readOnly/>
                            </div>
                            <div className='cross5'>

                            </div>
                            <div className='cross6'>
                                
                            </div>
                        </div>
                        
                        <div className='rowdet2'>
                            <div className='squaredet1'>
                                <input type='text' className='value3' value={horoscope.R6} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.R7} readOnly/>
                            </div>
                            <div className='squaredet3'> 
                                <input type='text' className='value3' value={horoscope.R8} readOnly/>
                            </div>
                        </div>
                        <div className='rowdet3'>
                            <div className='squaredet1'>
                                <input type='text' className='value4' value={horoscope.R9} readOnly/>
                                <input type='text' className='value5' value={horoscope.R10} readOnly/>
                            </div>
                            <div className='squaredet2'>
                                <input type='text' className='value3' value={horoscope.R11} readOnly/>
                            </div>
                            <div className='squaredet3'>
                                <input type='text' className='value1' value={horoscope.R12} readOnly/>
                                <input type='text' className='value2' value={horoscope.R13} readOnly/> 
                            </div>
                            <div className='cross7'>

                            </div>
                            <div className='cross8'>
                                
                            </div>
                        </div>
                        
                    </div>
                    </>
                    )}
                </form>
                </div> <br/> 
                </div> <br/>
                <button className='donebtn' id="btn1" style={{commonStyle, float:'left'}} onClick={handleEdit}> Edit </button>
                <button className='donebtn' id="btn2" style={{commonStyle, float:'right'}} onClick={handleDelete}> Delete </button>
                <br/> <br/> <br/> 
                </>
                )} 
            </div>
            <br/> <br/>
            <Footer/>
        </div>
    )
}

export default Post;